import React from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout as AntdLayout, Menu, Breadcrumb, MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import useLogoutIfLoggedIn from "../../services/logout-if-logged-in";
import useToLoginPage from "../../services/to-login-page";
import Contents from "../contents";
import "../../index.css";

const { Header, Content, Footer, Sider } = AntdLayout;

interface LayoutProps {
  children?: any;
  title?: string | undefined;
}

interface RouteItem {
  key: string;
  route: string;
  label: string;
}

const logoutKey = "logout";

const routeItems: RouteItem[] = [
  {
    key: "users",
    route: "/users",
    label: "유저 관리",
  },
  {
    key: "countries",
    route: "/countries",
    label: "국가 관리",
  },
  {
    key: "regions",
    route: "/regions",
    label: "지역 관리",
  },
  {
    key: "vote-categories",
    route: "/vote-categories",
    label: "투표 카테고리 관리",
  },
  {
    key: "idols",
    route: "/idols",
    label: "아이돌 관리",
  },
  {
    key: "associations",
    route: "/associations",
    label: "소속 관리",
  },
  {
    key: "votes",
    route: "/votes",
    label: "투표 관리",
  },
  {
    key: "announcements",
    route: "/announcements",
    label: "공지 관리",
  },
  // {
  //   key: 'update-app-active-idol-votes',
  //   route: '/update-app-active-idol-votes',
  //   label: '진행중인 아이돌 투표 관리',
  // },
  {
    key: "update-app-main-banners",
    route: "/update-app-main-banners",
    label: "메인 배너 관리",
  },
  {
    key: "update-app-main-page-contents",
    route: "/update-app-main-page-contents",
    label: "메인 페이지 컨텐츠 관리",
  },
  {
    key: "update-app-max-idol-vote-count-per-day",
    route: "/update-app-max-idol-vote-count-per-day",
    label: "하루 아이돌 투표 제한 관리",
  },
  {
    key: "update-app-rewarding-ticket-count",
    route: "/update-app-rewarding-ticket-count",
    label: "보상 투표권수 관리",
  },
  {
    key: "tour-categories",
    route: "/tour-categories",
    label: "펜업투어 카테고리 관리",
  },
  {
    key: "result-vote",
    route: "/result-vote",
    label: "펜업투어 관리",
  },
  {
    key: "attraction",
    route: "/attraction",
    label: "관광지 관리",
  },
  {
    key: "qna",
    route: "/qna",
    label: "1대1 문의 관리",
  },
  {
    key: "push-alarm",
    route: "/push-alarm",
    label: "푸쉬 알람 관리",
  },
];

const Layout: React.FC<LayoutProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const logout = useLogoutIfLoggedIn();
  const toLogin = useToLoginPage();

  const onSelect: MenuProps["onSelect"] = async (selected) => {
    if (selected.key === logoutKey) {
      await logout();
      toLogin();
      return;
    }

    for (let i = 0; i < routeItems.length; ++i) {
      const routeDatum = routeItems[i];

      if (routeDatum.key === selected.key) {
        navigate(routeDatum.route);
        return;
      }
    }
  };

  const getCurrentKey = (): string | undefined => {
    for (let i = 0; i < routeItems.length; ++i) {
      const routeDatum = routeItems[i];

      if (location.pathname.startsWith(routeDatum.route)) {
        return routeDatum.key;
      }
    }
  };

  const isNotNull = (a: any): boolean => a != null;

  const getSelectedKeys = (): string[] => {
    return [getCurrentKey()].filter(isNotNull) as string[];
  };

  return (
    <AntdLayout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} width={`240`} collapsible>
        <div className="logo">
          <img src={`/logo-new.png`} />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          onSelect={onSelect}
          selectedKeys={getSelectedKeys()}
        >
          {routeItems.map((routeDatum) => {
            return (
              <Menu.Item key={routeDatum.key} icon={<MenuFoldOutlined />}>
                {routeDatum.label}
              </Menu.Item>
            );
          })}
          <Menu.Item key={logoutKey} danger>
            로그아웃
          </Menu.Item>
        </Menu>
      </Sider>
      <AntdLayout>
        <Header
          className="site-layout-background"
          style={{ padding: 0, color: "#fff" }}
        >
          <MenuUnfoldOutlined /> &nbsp; Fanup Admin &gt; {props.title}
        </Header>
        <Content>
          <Contents title={props.title}>{props.children}</Contents>
        </Content>
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout;
