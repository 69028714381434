import {strict as assert} from "assert";

const fromRole = (role: 'admin' | 'user'): string => {
  if (role === 'user') {
    return '일반';
  }

  if (role === 'admin') {
    return '관리자';
  }

  assert.fail();
};

export default fromRole;
