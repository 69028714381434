import buildCreatePage from "../../utils/build-create-page";
import regionCollectionMetadata from "../../data-metadata/region";
import Region from '../../data-types/region';

const CreateRegionPage = buildCreatePage<Region>({
  title: '지역 생성',
  collectionMetadata: regionCollectionMetadata,
});

export default CreateRegionPage;
