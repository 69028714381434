import React from 'react';
import Layout from "../../components/layout";
import requireLogin from "../../hocs/require-login";
import {Typography} from "antd";

const MainPage: React.FC = requireLogin(() => {
  return (
    <Layout title={'대문'}>
      <Typography.Text>좌측의 메뉴에서 원하는 작업을 선택하세요.</Typography.Text>
    </Layout>
  );
});

export default MainPage;
