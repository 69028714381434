import { Input, Typography } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ImageInput from "../../../ant-form-inputs/image-input";
import { CountryType } from "../../../data-types/country";
import InternalFile from "../../../data-types/internal-file";

import { ResultVoteImageType } from "../../../data-types/result-vote";

interface IProps {
  title: string;
  value?: Partial<ResultVoteImageType>;
  urlLink?: string;
  onImageChange: (value: Partial<ResultVoteImageType>, title: string) => void;
  onUrlLinkChange?: (value: string, title: string) => void;
}
const Container = styled.div`
  border: 1px solid;
  border-radius: 25px;
  margin-bottom: 1rem;
  h2 {
    text-align: center;
  }
  input[type="text"] {
    width: 30%;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const InputConatiner = styled.div`
  text-align: center;
  margin: 1rem;
`;
const ResultVoteImageForm: React.FC<IProps> = ({
  title,
  value,
  onImageChange,
  onUrlLinkChange,
  urlLink,
}) => {
  const [en, setEn] = useState<InternalFile>();
  const [kr, setkr] = useState<InternalFile>();
  const [zh, setZh] = useState<InternalFile>();
  const handleImageChange = (
    e: InternalFile | undefined,
    country: CountryType
  ) => {
    if (e) {
      switch (country) {
        case "en":
          setEn(e);
          break;
        case "kr":
          setkr(e);
          break;
        case "zh":
          setZh(e);
          break;
        default:
          throw new Error("잘못된 국가 정보 입니다.");
      }
    }
    console.log(e);
  };

  useEffect(() => {
    onImageChange({ en, kr, zh }, title);
  }, [en, kr, zh]);

  return (
    <Container>
      <h2>{title}</h2>
      <ImageContainer>
        <div>
          <Typography.Paragraph strong>미국</Typography.Paragraph>
          <ImageInput
            value={value?.en}
            onChange={(e) => handleImageChange(e, "en")}
            requestUploadInfoPath="upload-app-main-banner-image"
          />
        </div>
        <div>
          <Typography.Paragraph strong>한국</Typography.Paragraph>
          <ImageInput
            value={value?.kr}
            onChange={(e) => handleImageChange(e, "kr")}
            requestUploadInfoPath="upload-app-main-banner-image"
          />
        </div>
        <div>
          <Typography.Paragraph strong>중국</Typography.Paragraph>
          <ImageInput
            value={value?.zh}
            onChange={(e) => handleImageChange(e, "zh")}
            requestUploadInfoPath="upload-app-main-banner-image"
          />
        </div>
      </ImageContainer>
      {onUrlLinkChange && (
        <InputConatiner>
          <Typography.Text strong>URL : </Typography.Text>
          <Input
            value={urlLink}
            onChange={(e) => {
              onUrlLinkChange(e.target.value, title);
            }}
          />
        </InputConatiner>
      )}
    </Container>
  );
};

export default ResultVoteImageForm;
