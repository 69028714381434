import VoteChoiceContent from "../data-types/vote-choice-content";
import runIfNotNull from "../utils/run-if-not-null";
import internalFileFromUrl from "../utils/internal-file-from-url";

const serialize = (r: Record<string, any>): VoteChoiceContent => {
  return {
    title: r.title,
    description: r.description,
    link: r.link,
    image: runIfNotNull(internalFileFromUrl, r.image) ?? undefined,
  };
};

export default serialize;
