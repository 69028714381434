import MultilingualString from "./multilingual-string";
import InternalFile from "./internal-file";

export interface Attraction {
  id: string;
  name: MultilingualString;
  address: MultilingualString;
  location?: number[];
  latitude: number;
  longitude: number;
  tel?: string;
  time?: string;
  time2?: string;
  memo?: MultilingualString;
  class1?: string;
  class2?: string;
  class3?: MultilingualString;
  class3Image?: InternalFile;
  images?: InternalFile[];
  statusCd?: string;
  statusNm?: string;
  approvedAt?: Date;
  approvedId?: string;
  modifiedAt?: Date;
  modifiedId?: string;
  requestAt?: Date;
  requestId?: string;
}
export interface AttractionPayload {
  id?: string;
  name?: MultilingualString;
  address?: MultilingualString;
  location?: number[];
  latitude?: number;
  longitude?: number;
  tel?: string;
  time?: string;
  memo?: MultilingualString;
  class1?: string;
  class2?: string;
  class3?: MultilingualString;
  class3Image?: string | null;
  images?: string[];
  statusCd?: string;
  statusNm?: string;
  approvedAt?: Date;
  approvedId?: string;
  modifiedAt?: Date;
  modifiedId?: string;
  requestAt?: Date;
  requestId?: string;
}
export const AttractionStatus = {
  REQUEST: "01",
  APPROVED: "02",
  REJECT: "99",
};
