import React from 'react';
import FieldMetadata from "../data-types/field-metadata";
import CollectionMetadata from "../data-types/collection-metadata";
import CollectionDocumentView from "../components/collection-document-view";
import getCollectionMetadataById from "./get-collection-metadata-by-id";

const buildRefFieldRender = <OwnerT, RefT>(
  that: FieldMetadata<OwnerT>,
  collectionMetadataId: string,
): FieldMetadata<OwnerT>['render'] => {
  return (params) => {
    const collectionMetadata: CollectionMetadata<RefT> = getCollectionMetadataById(collectionMetadataId);
    const value = params.value as unknown as string;

    return <CollectionDocumentView
      brief
      key={that.name}
      collectionMetadata={collectionMetadata}
      value={value}
    />;
  }
};

export default buildRefFieldRender;
