import FieldMetadata from "../../data-types/field-metadata";
import InternalFile from "../../data-types/internal-file";
import SmallImage from "./small-image";

const buildImageFieldRender = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['render'] => {
  return (params) => {
    const value = params.value as unknown as InternalFile;

    return <SmallImage key={that.name} src={value.url}/>;
  };
};

export default buildImageFieldRender;
