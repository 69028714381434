import React from 'react';
import FieldMetadata from "../../data-types/field-metadata";
import {Form} from "antd";
import MediumImageInput from "./medium-image-input";

const buildImageFieldRenderEditableFormItem = <T,>(
  that: FieldMetadata<T>,
  requestUploadInfoPath: string = 'upload-idol-thumbnail'
): FieldMetadata<T>['renderEditableFormItem'] => () => {
  return (
    <Form.Item
      key={that.name}
      label={that.label}
      name={that.name}
    >
      <MediumImageInput
        requestUploadInfoPath={requestUploadInfoPath}
      />
    </Form.Item>
  );
};

export default buildImageFieldRenderEditableFormItem;
