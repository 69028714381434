import React, {useState} from 'react';
import CollectionRefSelect from "../components/collection-ref-select";
import idolCollectionMetadata from "../data-metadata/idol";
import Idol from "../data-types/idol";
import {Button} from "antd";
import IdolVoteCandidate from "../data-types/idol-vote-candidate";

interface IdolVoteCandidatesInputProps {
  className?: string | undefined;
  value?: {idol: string}[] | undefined;
  onChange?: ((value: {idol: string}[]) => void) | undefined;
}

const koreanName = (idol: Idol): string => {
  return idol.name.kr ?? idol.name.en;
};

const IdolVoteCandidatesInput: React.FC<IdolVoteCandidatesInputProps> = (props) => {
  const [idols, setIdols] = useState<Record<string, Idol>>({});

  const idolExists = (idolId: string): boolean => {
    const value = props.value ?? [];
    return value.some((cur) => cur.idol === idolId);
    // return idolId in idols;
  };

  const appendIdol = (idol: Idol) => {
    if (idolExists(idol.id)) { return; }

    const value = props.value ?? [];

    props.onChange != null && props.onChange([...value, {idol: idol.id}]);

    setIdols({...idols, [idol.id]: idol});
  };

  const deleteIdolById = (idolId: string) => {
    const isNotIdolId = (cur: {idol: string}) => cur.idol !== idolId;
    props.onChange != null && props.onChange(
      (props.value ?? []).filter(isNotIdolId)
    );
  };

  const onIdolPress = appendIdol;
  const onIdolDelete = deleteIdolById;

  const getIdolIdAndKoreanName = (id: string): string => {
    const idol = idols[id];

    if (idol == null) {
      return id;
    }

    return `${id}, ${koreanName(idol)}`;
  };

  return (
    <div className={props.className}>
      {(props.value ?? []).map(
        (candidate) => {
          return (
            <div key={candidate.idol}>
              <span>{getIdolIdAndKoreanName(candidate.idol)}</span>
              <Button danger onClick={() => onIdolDelete(candidate.idol)}>
                삭제
              </Button>
            </div>
          );
        }
      )}
      <CollectionRefSelect
        collectionMetadata={idolCollectionMetadata}
        onPress={onIdolPress}
      />
    </div>
  );
};

export default IdolVoteCandidatesInput;
