import React from 'react';
import FieldMetadata from "../data-types/field-metadata";
import {Form, Input, DatePicker, DatePickerProps} from "antd";
import moment from 'moment';

const TheDatePicker: React.FC<any> = (props) => {
  const value = props.value != null ? moment(props.value) : props.value;

  return <DatePicker {...props} value={value}/>;
};

const buildDateFieldRenderReadOnlyFormItem = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['renderReadOnlyFormItem'] => {
  return () => {
    return (
      <Form.Item
        key={that.name}
        label={that.label}
        name={that.name}
      >
        <TheDatePicker disabled={true}/>
      </Form.Item>
    );
  };
};

export default buildDateFieldRenderReadOnlyFormItem;
