import FieldMetadata from "../data-types/field-metadata";
import {Form, Input} from "antd";
import MultilingualStringInput from "../components/multilingual-string-input";
import React from "react";

const buildMultilingualStringFieldRenderReadOnlyFormItem = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['renderReadOnlyFormItem'] => {
  return () => {
    return (
      <Form.Item
        key={that.name}
        label={that.label}
        name={that.name}
      >
        <MultilingualStringInput disabled={true}/>
      </Form.Item>
    )
  };
};

export default buildMultilingualStringFieldRenderReadOnlyFormItem;
