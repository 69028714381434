import MultilingualString from "../../data-types/multilingual-string";
import React from "react";
import {Input, Typography} from "antd";
import styled from 'styled-components';

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

interface MultilingualStringProps {
  className?: string | undefined;
  value?: MultilingualString | undefined;
  onChange?: ((value: MultilingualString) => void) | undefined;
  disabled?: boolean | undefined;
}

const MultilingualStringInput: React.FC<MultilingualStringProps> = (props) => {
  const buildOnChange = (fieldName: string) => (e: any) => {
    props.onChange && props.onChange({
      en: props?.value?.en ?? '',
      kr: props?.value?.kr ?? '',
      zh: props?.value?.zh ?? '',
      [fieldName]: e.target.value,
    });
  };

  const onEnChange = buildOnChange('en');
  const onKrChange = buildOnChange('kr');
  const onZhChange = buildOnChange('zh');
  return <Cont className={props.className}>
    <Typography.Title level={5}>
      영어
    </Typography.Title>
    <Input value={props?.value?.en ?? ''} onChange={onEnChange} disabled={props.disabled} readOnly={props.disabled}/>
    <Typography.Title level={5}>
      한국어
    </Typography.Title>
    <Input value={props?.value?.kr ?? ''} onChange={onKrChange} disabled={props.disabled} readOnly={props.disabled}/>
    <Typography.Title level={5}>
      중국어
    </Typography.Title>
    <Input value={props?.value?.zh ?? ''} onChange={onZhChange} disabled={props.disabled} readOnly={props.disabled}/>
  </Cont>;
};

export default MultilingualStringInput;
export type {MultilingualStringProps};
