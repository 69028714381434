import { Badge, Descriptions } from "antd";

import { QnaDetail } from "../../../data-types/qna";

const QnaDetailForm: React.FC<{ QnaData: QnaDetail }> = ({ QnaData }) => {
	return (
		<>
			<Descriptions style={{ backgroundColor: "snow" }} column={2} bordered>
				<Descriptions.Item label="UID">{QnaData.id}</Descriptions.Item>
				<Descriptions.Item label="제목">{QnaData.title}</Descriptions.Item>
				<Descriptions.Item label="답변 여부">
					<Badge status={QnaData.isAnswered ? "success" : "warning"} />
				</Descriptions.Item>
				<Descriptions.Item label="카테고리">{QnaData.category}</Descriptions.Item>
				<Descriptions.Item label="작성자">{QnaData.email}</Descriptions.Item>
				{/* <Descriptions.Item label="작성일">{QnaData.createAt.toISOString().substring(0, 10)}</Descriptions.Item> */}
				<Descriptions.Item label="내용" span={2}>
					{QnaData.text}
				</Descriptions.Item>
			</Descriptions>
		</>
	);
};

export default QnaDetailForm;
