import useAuth from "./auth";
import axios from "axios";
import {apiAddr} from "../config";

const useLogout = () => {
  const auth = useAuth();

  return async () => {
    try {
      await axios({
        method: 'post',
        url: `${apiAddr}/v1/auth/logout`,
        data: {refreshToken: auth.refreshToken},
      });
    } catch(error) {
      console.error('Error occurred in the process of logout. But it\'s ignored, since we did the best.');
    }

    auth.logout();
  };
};

export default useLogout;
