import Banner from "../data-types/banner";
import runIfNotNull from "../utils/run-if-not-null";
import internalFileFromUrl from "../utils/internal-file-from-url";

const serialize = (r: Record<string, any>): Banner => {
  return {
    id: r.id,
    title: r.title,
    image: runIfNotNull(internalFileFromUrl, r.imageUrl),
    actionButtonText: r.actionButtonText,
    link: r.link,
  };
};

export default serialize;
