import {Typography, Form, Input} from 'antd';
import User from "../data-types/user";
import useReadUser from "../services/read-user";
import useReadUsers from "../services/read-users";
import useToUpdateUserPage from "../services/to-update-user-page";
import FieldMetadata from "../data-types/field-metadata";
import CollectionMetadata from "../data-types/collection-metadata";
import useToUsersPage from "../services/to-users-page";
import useUpdateUser from "../services/update-user";
import buildTextFieldRenderReadOnlyFormItem from "../utils/build-text-field-render-read-only-form-item";
import buildTextFieldRenderEditableFormItem from "../utils/build-text-field-render-editable-form-item";
import buildTextFieldRender from "../utils/build-text-field-render";
import buildBooleanFieldRenderReadOnlyFormItem from "../utils/build-boolean-field-render-read-only-form-item";
import buildBooleanFieldRenderEditableFormItem from "../utils/build-boolean-field-render-editable-form-item";
import buildDateFieldRender from "../utils/build-date-field-render";
import buildDateFieldRenderReadOnlyFormItem from "../utils/build-date-field-render-read-only-form-item";
import buildRoleFieldRender from "../utils/build-role-field-render";
import buildRoleFieldRenderReadOnlyFormItem from "../utils/build-role-field-render-read-only-form-item";

const userCollectionMetadata: CollectionMetadata<User> = {
  useRead: useReadUser,
  useReadByPagination: useReadUsers,
  useDefaultDetailsAction: useToUpdateUserPage,
  useToOverallPage: useToUsersPage,
  useUpdate: useUpdateUser,
  fields: [
    new class implements FieldMetadata<User> {
      readonly name = 'id';
      readonly label = 'UID';
      readonly customizable = false;

      // @ts-ignore
      readonly render = buildTextFieldRender(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
    },
    new class implements FieldMetadata<User> {
      readonly name = 'email';
      readonly label = '이메일';
      readonly customizable = true;

      // @ts-ignore
      readonly render = buildTextFieldRender(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
      // @ts-ignore
      readonly renderEditableFormItem = buildTextFieldRenderEditableFormItem(this);
    },
    new class implements FieldMetadata<User> {
      readonly name = 'name';
      readonly label = '이름';
      readonly customizable = true;

      // @ts-ignore
      readonly render = buildTextFieldRender(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
      // @ts-ignore
      readonly renderEditableFormItem = buildTextFieldRenderEditableFormItem(this);
    },
    new class implements FieldMetadata<User> {
      readonly name = 'birth';
      readonly label = '생일';
      readonly customizable = true;

      // @ts-ignore
      readonly render = buildTextFieldRender(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
      // @ts-ignore
      readonly renderEditableFormItem = buildTextFieldRenderEditableFormItem(this);
    },
    new class implements FieldMetadata<User> {
      readonly name = 'ticketCount';
      readonly label = '티켓 개수';
      readonly customizable = false;

      // @ts-ignore
      readonly render = buildTextFieldRender(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
    },
    new class implements FieldMetadata<User> {
      readonly name = 'isEmailVerified';
      readonly label = '이메일 인증 여부';
      readonly customizable = true;

      // @ts-ignore
      readonly render = (params) => {
        const value = params.value as unknown as boolean;

        const getText = () => {
          if (value) {
            return '인증됨';
          } else {
            return '인증 필요';
          }
        };

        return <Typography.Text>{getText()}</Typography.Text>;
      };
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildBooleanFieldRenderReadOnlyFormItem(this);
      // @ts-ignore
      readonly renderEditableFormItem = buildBooleanFieldRenderEditableFormItem(this);
    },
    new class implements FieldMetadata<User> {
      readonly name = 'createdAt';
      readonly label = '생성일';
      readonly customizable = false;

      // @ts-ignore
      readonly render = buildDateFieldRender(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildDateFieldRenderReadOnlyFormItem(this);
    },
    new class implements FieldMetadata<User> {
      readonly name = 'role';
      readonly label = '권한';
      readonly customizable = false;

      // @ts-ignore
      readonly render = buildRoleFieldRender(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildRoleFieldRenderReadOnlyFormItem(this);
    },
  ] as FieldMetadata<User>[],
  fieldsForBrief: ['id', 'name'],
};

export default userCollectionMetadata;
