import CollectionMetadata from "../data-types/collection-metadata";
import TourCategory from "../data-types/tour-category";
import useReadTourCategory from "../services/read-tour-category";
import useToTourCategoriesPage from "../services/to-tour-categories-page";
import useUpdateTourCategory from "../services/update-tour-category";
import useReadTourCategories from "../services/read-tour-categories";
import useCreateTourCategory from "../services/create-tour-category";
import useToCreateTourCategoryPage from "../services/to-create-tour-category-page";
import FieldMetadata from "../data-types/field-metadata";
import buildTextFieldRender from "../utils/build-text-field-render";
import buildTextFieldRenderReadOnlyFormItem from "../utils/build-text-field-render-read-only-form-item";
import buildTextFieldRenderEditableFormItem from "../utils/build-text-field-render-editable-form-item";
import useToUpdateTourCategoryPage from "../services/to-update-tour-category-page";
import buildMultilingualStringFieldRender from "../utils/build-multilingual-string-field-render";
import buildMultilingualStringFieldRenderReadOnlyFormItem
  from "../utils/build-multilingual-string-field-render-read-only-form-item";
import buildMultilingualStringFieldRenderEditableFormItem
  from "../utils/build-multilingual-string-field-render-editable-form-item";

class IdField implements FieldMetadata<TourCategory> {
  readonly name = 'id';
  readonly label = 'UID';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildTextFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildTextFieldRenderEditableFormItem(this);
}

class NameField implements FieldMetadata<TourCategory> {
  readonly name = 'name';
  readonly label = '이름';
  readonly customizable = true;

  // @ts-ignore
  readonly render = buildMultilingualStringFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildMultilingualStringFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildMultilingualStringFieldRenderEditableFormItem(this);
}

const TourCategoryCollectionMetadata: CollectionMetadata<TourCategory> = {
  useRead: useReadTourCategory,
  useToOverallPage: useToTourCategoriesPage,
  useUpdate: useUpdateTourCategory,
  useReadByPagination: useReadTourCategories,
  useCreate: useCreateTourCategory,
  useToCreatePage: useToCreateTourCategoryPage,
  useDefaultDetailsAction: useToUpdateTourCategoryPage,
  fields: [
    new IdField(),
    new NameField(),
  ],
  fieldsForBrief: ['id', 'name'],
};

export default TourCategoryCollectionMetadata;
