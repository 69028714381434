import useLogout from "./logout";
import useAuth from "./auth";

const useLogoutIfLoggedIn = () => {
  const auth = useAuth();
  const logout = useLogout();

  return async () => {
    if (auth.isLoggedIn) {
      await logout();
    }
  };
};

export default useLogoutIfLoggedIn;
