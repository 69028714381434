import buildReadCollectionDocuments from "../utils/build-read-collection-documents";
import serializeVote from '../from-server-serializers/vote';
import Vote from "../data-types/vote";

const useReadVotes = buildReadCollectionDocuments<Vote>({
  collection: 'votes',
  serializer: serializeVote,
});

export default useReadVotes;
