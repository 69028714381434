import useAuth from "./auth";
import InternalFile from "../data-types/internal-file";
import runIfNotNull from "../utils/run-if-not-null";
import Announcement from "../data-types/announcement";
import { apiAddr } from "../config";

const useDeleteAnnouncement = () => {
  const auth = useAuth();

  return async (id: string) => {
    await auth.callAxios({
      method: "delete",
      url: `${apiAddr}/admin/v1/announcements/${id}`,
    });
  };
};

export default useDeleteAnnouncement;
