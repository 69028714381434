import Region from "../data-types/region";
import useAuth from "./auth";
import {apiAddr} from "../config";
import serializeRegion from '../from-server-serializers/region';

const useCreateRegion = () => {
  const auth = useAuth();

  return async (payload: Partial<Region>): Promise<Region> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/regions`,
      data: payload,
    });

    return serializeRegion(axiosResult.data);
  };
};

export default useCreateRegion;
