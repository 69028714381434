import FieldMetadata from "../data-types/field-metadata";
import {Typography} from "antd";
import Vote from "../data-types/vote";

const buildVoteKindFieldRender = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['render'] => {
  return (params) => {
    const value = params.value as unknown as Vote['voteKind'];

    const getLabel = () => {
      switch (value) {
        case 'IDOL': return '아이돌';
        case 'TOURNAMENT': return '월드컵(일반)';
        case 'EVENT': return '월드컵(이벤트)';
      }
    };

    return <Typography.Text key={that.name}>{getLabel()}</Typography.Text>;
  };
};

export default buildVoteKindFieldRender;
