import buildReadCollectionDocument from "../../utils/build-read-collection-document";
import Vote from "../../data-types/vote";
import serializeVote from "../../from-server-serializers/vote";
import { Attraction } from "../../data-types/attraction";
import buildReadCollectionDocuments from "../../utils/build-read-collection-documents";
import serializeAttraction from "../../from-server-serializers/attraction";
import useAuth from "../auth";
import { apiAddr } from "../../config";
import { AxiosResponse } from "axios";

export const useReadAttractions = buildReadCollectionDocuments<Attraction>({
  collection: "attraction",
  serializer: serializeAttraction,
});

export const readAttraction = (id: string) => {
  return new Promise((res) => {
    fetch(`${apiAddr}/admin/v1/attraction/${id}`, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((result) => {
        console.log(result);
      })
      .catch((e) => {});
  });
};

export const useReadAttraction = buildReadCollectionDocument<Attraction>({
  collection: "attraction",
  serializer: serializeAttraction,
});

const _openApiInfo = {
  domain: "http://apis.data.go.kr/B551011",
  key: "truMc5Qt80wd8SxZ4rymqhtE%2FNsS6YmmK4ADfQOoI5AsljpZxXRWOkbbd0YGuosjnr62qF%2Bq03NwXt81sVTwtg%3D%3D",
};
export interface OpenApiBody {
  items?: { item: OpenApiItem[] };
  numOfRows: number;
  pageNo: number;
  totalCount: number;
}
export interface OpenApiItem {
  addr1?: string;
  addr2?: string;
  mapx?: number;
  mapy?: number;
  title?: string;
  contentid?: string;
  tel?: string;
}
interface OpenApiHeader {
  resultCode?: string;
  resultMsg?: string;
}
interface OpenApiResult {
  header: OpenApiHeader;
  body: OpenApiBody;
}
export const readAttractionListFromGovApi = (
  keyword: string
): Promise<OpenApiBody> => {
  const data = {
    ServiceKey: _openApiInfo.key,
    arrange: "S",
    numOfRows: 100,
    pageNo: 1,
    MobileOS: "ETC",
    MobileApp: "FanUp",
    _type: "json",
    keyword: keyword,
  };
  const type = "/searchKeyword1";
  const keys = Object.keys(data) as Array<keyof typeof data>;
  const params = keys.map((key) => `${key}=${data[key]}`).join("&");
  return new Promise((res) => {
    fetch(`${_openApiInfo.domain}/KorService1${type}?${params}`, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((result) => {
        let data = result.response as OpenApiResult;
        if (data.header.resultCode === "0000") {
          res(result.response.body as OpenApiBody);
        } else {
          res({ totalCount: 0 } as OpenApiBody);
        }
      })
      .catch((e) => {
        if (e) {
          console.log(e);
        }
        res({ totalCount: 0 } as OpenApiBody);
      });
  });
};
