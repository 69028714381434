import React from 'react';
import voteCategoryCollectionMetadata from "../data-metadata/vote-category";
import {strict as assert} from 'assert';
import CollectionRefInput from "../components/collection-ref-input";

type CollectionCode = 'VOTE_CATEGORY';

interface RefInputProps {
  className?: string | undefined;
  value?: string | undefined;
  onChange?: ((value: string | undefined) => void) | undefined;
  collection: CollectionCode;
}

const getCollectionMetadata = (collectionCode: CollectionCode) => {
  switch (collectionCode) {
    case "VOTE_CATEGORY": return voteCategoryCollectionMetadata;
  }

  assert.fail();
};

const RefInput: React.FC<RefInputProps> = (props) => {
  const onChange = props.onChange;

  return <CollectionRefInput
    className={props.className}
    collectionMetadata={getCollectionMetadata(props.collection)}
    onChange={onChange}
    value={props.value}
  />;
};

export default RefInput;
export type {RefInputProps};
