import React from 'react';
import VoteKind from "../../data-types/vote-kind";
import Vote from "../../data-types/vote";
import IdolVoteInput from "./idol-vote-input";
import TournamentVoteInput from "./tournament-vote-input";
import EventVoteInput from "./event-vote-input";

interface VoteInputProps {
  className?: string | undefined;
  voteKind?: VoteKind | undefined;
  value?: Partial<Vote> | undefined;
  onChange?: ((value: Partial<Vote> | undefined) => void) | undefined;
}

const VoteInput: React.FC<VoteInputProps> = (props) => {
  if (props.voteKind == null) { return null; }

  const components = {
    IDOL: IdolVoteInput,
    TOURNAMENT: TournamentVoteInput,
    EVENT: EventVoteInput,
  };

  const Component = components[props.voteKind];

  return <Component
    className={props.className}
    value={props.value}
    onChange={props.onChange}
  />;
};

export default VoteInput;
