import Country from "../data-types/country";

const serializer = (raw: Record<string, any>): Country => {
  return {
    id: raw.id,
    name: raw.name,
  };
};

export default serializer;
