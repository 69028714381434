import {useEffect, useState} from "react";
import CompoundItem, {
  AnnouncementsCompoundItem,
  CommercialCompoundItem, MagazinesCompoundItem, PlainTextCompoundItem,
  RandomDemandingVoteCompoundItem,
  RandomIdolVoteCompoundItem, VotesCompoundItem
} from "../../data-types/compound";
import useHandleAppError from "../../services/handle-app-error";
import useReadAppMainPageContents from "../../services/read-app-main-page-contents";
import {strict as assert} from 'assert';
import useUpdateAppMainPageContents from "../../services/update-app-main-page-contents";
import {v4 as uuid} from 'uuid';

interface LoadingLogic {
  status: 'LOADING';
}

interface LoadedLogic {
  status: 'LOADED';
  mainPageContents: CompoundItem[];
  setMainPageContents: (contents: CompoundItem[]) => void;
  submit: () => void;
  createAppendCommercial: () => void;
  createAppendRandomIdolVote: () => void;
  createAppendRandomDemandingVote: () => void;
  createAppendVotes: () => void;
  createAppendAnnouncements: () => void;
  createAppendPlainText: () => void;
  createAppendMagazines: () => void;
}

interface FailedLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  mainPageContents: CompoundItem[];
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const initialState: State = {status: 'LOADING'};
  const [state, setState] = useState<State>(initialState);
  const handleAppError = useHandleAppError();
  const readMainPageContents = useReadAppMainPageContents();
  const updateMainPageContents = useUpdateAppMainPageContents();

  const tryInit = async () => {
    const mainPageContents = await readMainPageContents();

    setState({status: 'LOADED', mainPageContents});
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, []);

  if (state.status === 'LOADING') {
    return {status: 'LOADING'};
  }

  if (state.status === 'FAILED') {
    return {status: 'FAILED'};
  }

  const setMainPageContents = (contents: CompoundItem[]) => {
    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      return {
        status: 'LOADED',
        mainPageContents: contents,
      };
    });
  };

  const trySubmit = async () => {
    await updateMainPageContents(state.mainPageContents);
    alert('저장되었습니다');
    window.location.reload();
  };

  const createAppendCommercial = () => {
    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      const newContent: CommercialCompoundItem = {
        id: uuid(),
        kind: 'COMMERCIAL',
        link: undefined,
        image: undefined,
      };

      return {
        ...oldState,
        mainPageContents: [...oldState.mainPageContents, newContent],
      };
    });
  };

  const createAppendRandomIdolVote = () => {
    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      const newContent: RandomIdolVoteCompoundItem = {
        id: uuid(),
        kind: 'RANDOM_IDOL_VOTE',
      };

      return {
        ...oldState,
        mainPageContents: [...oldState.mainPageContents, newContent],
      };
    });
  };

  const createAppendRandomDemandingVote = () => {
    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      const newContent: RandomDemandingVoteCompoundItem = {
        id: uuid(),
        kind: 'RANDOM_DEMANDING_VOTE',
      };

      return {
        ...oldState,
        mainPageContents: [...oldState.mainPageContents, newContent],
      };
    });
  };

  const createAppendVotes = () => {
    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      const newContent: VotesCompoundItem = {
        id: uuid(),
        kind: 'VOTES',
      };

      return {
        ...oldState,
        mainPageContents: [...oldState.mainPageContents, newContent],
      };
    });
  };

  const createAppendAnnouncements = () => {
    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      const newContent: AnnouncementsCompoundItem = {
        id: uuid(),
        kind: 'ANNOUNCEMENTS',
      };

      return {
        ...oldState,
        mainPageContents: [...oldState.mainPageContents, newContent],
      };
    });
  };

  const createAppendPlainText = () => {
    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      const newContent: PlainTextCompoundItem = {
        id: uuid(),
        kind: 'PLAIN_TEXT',
        text: '',
      };

      return {
        ...oldState,
        mainPageContents: [...oldState.mainPageContents, newContent],
      };
    });
  };

  const createAppendMagazines = () => {
    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      const newContent: MagazinesCompoundItem = {
        id: uuid(),
        kind: 'MAGAZINE',
      };

      return {
        ...oldState,
        mainPageContents: [...oldState.mainPageContents, newContent],
      };
    });
  };

  const submit = async () => {
    try {
      await trySubmit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  if (state.status === 'LOADED') {
    return {
      status: 'LOADED',
      mainPageContents: state.mainPageContents,
      setMainPageContents,
      submit,
      createAppendCommercial,
      createAppendRandomIdolVote,
      createAppendRandomDemandingVote,
      createAppendVotes,
      createAppendAnnouncements,
      createAppendPlainText,
      createAppendMagazines,
    };
  }

  assert.fail();
};

export default useLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};
