import useAuth from "./auth";
import {apiAddr} from "../config";
import User from "../data-types/user";

const useUpdateUser = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<User>) => {
  // return async (id: string, payload: Pick<User, 'email' | 'name' | 'birth' | 'isEmailVerified'>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/users/${id}`,
      data: payload,
    });
  };
};

export default useUpdateUser;
