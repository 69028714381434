import { Typography } from "antd";
import Layout from "../../components/layout";
import PushAlarmDetail from "../../components/push-alarm/pusb-alarm-detail";

const PushAlarmDetailPage: React.FC = () => {
  return (
    <Layout>
      <Typography.Title>푸쉬 알람 관리</Typography.Title>
      <PushAlarmDetail />
    </Layout>
  );
};

export default PushAlarmDetailPage;
