import buildPaginatedPage from "../../utils/build-paginated-page";
import Idol from '../../data-types/idol';
import idolCollectionMetadata from "../../data-metadata/idol";

const IdolsPage = buildPaginatedPage<Idol>({
  title: '아이돌 관리',
  collectionMetadata: idolCollectionMetadata,
});

export default IdolsPage;
