import useAuth from "./auth";
import { apiAddr } from "../config";

const useUpdateEventRewardingTicketCount = () => {
  const auth = useAuth();

  return async (newValue: number) => {
    await auth.callAxios({
      method: "put",
      url: `${apiAddr}/admin/v1/app/event-rewarding-ticket-count`,
      data: newValue,
      headers: { "Content-Type": "application/json" },
    });
  };
};

export default useUpdateEventRewardingTicketCount;
