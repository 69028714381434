import buildReadCollectionDocuments from "../utils/build-read-collection-documents";
import Region from '../data-types/region';
import serializeRegion from '../from-server-serializers/region';

const useReadRegions = buildReadCollectionDocuments<Region>({
  collection: 'regions',
  serializer: serializeRegion,
});

export default useReadRegions;
