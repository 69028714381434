import { apiAddr } from "../config";
import { PushAlarmT } from "../data-types/push-alarm";
import { Auth } from "../utils/auth";

class PushAlarmApi {
  constructor(private auth: Auth) {}
  async read(): Promise<PushAlarmT[]> {
    this.auth.checkIsLoggedIn();
    const axiosResult = await this.auth.callAxios({
      method: "GET",
      url: `${apiAddr}/admin/v1/push-alarms`,
    });

    const result = await axiosResult.data;
    return result;
  }
  async get(id: string): Promise<PushAlarmT> {
    this.auth.checkIsLoggedIn();
    const axiosResult = await this.auth.callAxios({
      method: "GET",
      url: `${apiAddr}/admin/v1/push-alarms/${id}`,
    });

    const result = await axiosResult.data;
    return result;
  }

  async delete(id: string) {
    this.auth.checkIsLoggedIn();
    const axiosResult = await this.auth.callAxios({
      method: "DELETE",
      url: `${apiAddr}/admin/v1/push-alarms/${id}`,
    });

    const status = await axiosResult.status;
    return status === 204;
  }

  async create(data: PushAlarmT) {
    this.auth.checkIsLoggedIn();
    delete data.id;
    const axiosResult = await this.auth.callAxios({
      method: "POST",
      url: `${apiAddr}/admin/v1/push-alarms`,
      data,
    });

    const result = axiosResult.data;
    return result;
  }

  async update(id: string, data: PushAlarmT) {
    this.auth.checkIsLoggedIn();

    const axiosResult = await this.auth.callAxios({
      method: "PATCH",
      url: `${apiAddr}/admin/v1/push-alarms/${id}`,
      data: {
        scheduleTitle: data.scheduleTitle,
        content: data.content,
        device: data.device,
        scheduleDate: data.scheduleDate,
        sended: data.sended,
      },
    });

    const result = axiosResult.data;
    return result;
  }
}

export default PushAlarmApi;
