import { Button, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ResultVoteType } from "../../../data-types/result-vote";
import useAuth from "../../../services/auth";
import useReadTourCategories from "../../../services/read-tour-categories";
import ResultVoteAPI from "../../../services/resultVoteAPI";

const ResultVoteList: React.FC = () => {
  const navigator = useNavigate();

  const [dataList, setDataList] = useState<ResultVoteType[]>();
  const [tourCategory, setTourCategory] =
    useState<{ text: string; value: string }[]>();
  const categoryApi = useMemo(useReadTourCategories, []);
  const auth = useAuth();
  const api = useMemo(() => {
    return new ResultVoteAPI(auth);
  }, [auth]);

  useEffect(() => {
    async function getData() {
      try {
        const { data } = await categoryApi({ limit: 10, skip: 0 });

        if (data.length === 0) {
          throw Error;
        }
        setTourCategory(
          data.map((i) => ({ text: i.name.kr ?? "", value: i.id }))
        );
      } catch (e) {
        window.alert("Tour 카테고리를 불러오는 도중 에러가 발생 했습니다.");
        navigator("/result-vote");
      }
      const data = await api.getList();

      if (data === null) {
        throw new Error("Qna 데이터를 불러 올 수 없습니다.");
      }

      setDataList(data.data);
    }
    getData();
  }, [api, navigator, categoryApi]);

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          navigator("new");
        }}
      >
        생성
      </Button>
      <br />

      <Table
        dataSource={dataList}
        onRow={(record) => {
          return {
            onClick: () => {
              navigator(`${record.id}`);
            },
          };
        }}
        rowKey={(record) => record.id!}
        style={{ cursor: "pointer" }}
      >
        <Column title="UID" dataIndex="id" />
        <Column
          title="종류"
          dataIndex="category"
          render={(catgoryId: string) => {
            return tourCategory?.find((i) => i.value === catgoryId)?.text;
          }}
        />
        <Column
          title="제목"
          key="title"
          render={(_, record: ResultVoteType) => record.title?.kr}
        />
        <Column
          title="생성일"
          dataIndex="createAt"
          key="createAt"
          render={(_, record: any) => record.createdAt.toString().substr(0, 10)}
        />
      </Table>
    </>
  );
};

export default ResultVoteList;
