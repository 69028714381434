import React from 'react';
import Banner from "../../data-types/banner";
import OrderedInput from "../../components/ordered-input";
import MultilingualStringInput from "../../ant-form-inputs/multilingual-string-input";
import Field from "../../ant-form-inputs/field";
import ImageInput from "../../ant-form-inputs/image-input";
import LinkInput from "../../ant-form-inputs/link-input";

interface ItemProps {
  value?: Banner | undefined;
  onChange?: ((value: Banner) => void) | undefined;
  index: number;
}

const Item: React.FC<ItemProps> = (props) => {
  if (props.value == null) { return null; }

  const onChangeField = (fieldName: string) => (newValue: any) => {
    props.onChange != null && props.onChange({
      ...props.value!,
      [fieldName]: newValue,
    });
  }

  return (
    <div>
      <Field title={'제목'}>
        <MultilingualStringInput
          value={props.value.title}
          onChange={onChangeField('title')}
        />
      </Field>
      <Field title={'이미지'}>
        <ImageInput
          value={props.value.image}
          onChange={onChangeField('image')}
          requestUploadInfoPath={'upload-app-main-banner-image'}
        />
      </Field>
      <Field title={'액션 버튼 텍스트'}>
        <MultilingualStringInput
          value={props.value.actionButtonText}
          onChange={onChangeField('actionButtonText')}
        />
      </Field>
      <Field title={'링크'}>
        <LinkInput
          value={props.value.link}
          onChange={onChangeField('link')}
        />
      </Field>
    </div>
  );
};


interface BannerInputProps {
  className?: string | undefined;
  value?: Banner[] | undefined;
  onChange?: ((value: Banner[]) => void) | undefined;
}

const BannerInput: React.FC<BannerInputProps> = (props) => {
  return <OrderedInput
    itemComponent={Item}
    value={props.value}
    onChange={props.onChange as any}
  />;
};

export default BannerInput;
export type {BannerInputProps};
