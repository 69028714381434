import React from 'react';
import styled from 'styled-components';
import {Typography} from 'antd';

const Cont = styled.div`
  padding: 24px;
`;

const ChildrenCont = styled.div`
  margin-top: 24px;
`;

interface ContentsProps {
  title?: string | undefined;
  children?: any;
}

const Contents: React.FC<ContentsProps> = (props) => {
  return (
    <Cont>
      <Typography.Title level={1}>{props.title}</Typography.Title>
      <ChildrenCont>
        {props.children}
      </ChildrenCont>
    </Cont>
  );
};

export default Contents;
export type {ContentsProps};
