import CollectionMetadata from "../../data-types/collection-metadata";
import React, {useEffect, useState} from "react";
import useHandleAppError from "../../services/handle-app-error";
import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";
import {Form, Typography} from "antd";
import FieldMetadata from "../../data-types/field-metadata";
import styled from 'styled-components';

const Cont = styled.div`
  display: flex;
  flex-direction: column;
`;

interface CollectionDocumentViewProps {
  collectionMetadata: CollectionMetadata<any>;
  brief?: boolean | undefined;
  value?: string | undefined;
}

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  document: any | undefined;
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const CollectionDocumentView: React.FC<CollectionDocumentViewProps> = (props) => {
  const documentId = props.value;
  const readDocument = props.collectionMetadata.useRead();
  const handleAppError = useHandleAppError();

  const [state, setState] = useState<State>({status: 'LOADING'});

  const tryInit = async () => {
    setState({status: 'LOADING'});

    const document = documentId != null ? await readDocument(documentId) : undefined;

    setState({status: 'LOADED', document});
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, [documentId]);

  const fieldElementsWhenBrief = (state: LoadedState) => {
    const isBriefField = (field: FieldMetadata<any>) => {
      const isSameWithField = (briefFieldName: string) => briefFieldName === field.name;
      return props.collectionMetadata.fieldsForBrief.some(isSameWithField);
    };

    const targetFields = props.collectionMetadata.fields.filter(isBriefField);

    return (
      <Cont>
        {targetFields.map((field) => {
          return field.render({value: state.document[field.name]});
        })}
      </Cont>
    )
  };

  const fieldElementsWhenNotBrief = (state: LoadedState) => {
    const targetFields = props.collectionMetadata.fields;

    return (
      <Form initialValues={state.document}>
        {targetFields.map((field) => {
          return field.renderReadOnlyFormItem();
        })}
      </Form>
    );
  };

  const elementWhenNotSelected = <Typography.Text>선택되지 않음</Typography.Text>;

  return renderWhenLoadingOrFailed(state, state => {
    if (state.document != null) {
      if (props.brief) {
        return fieldElementsWhenBrief(state);
      } else {
        return fieldElementsWhenNotBrief(state);
      }
    } else {
      return elementWhenNotSelected;
    }
  });
};

export default CollectionDocumentView;
export type {CollectionDocumentViewProps};
