import React from "react";
import {Radio} from "antd";
import {voteKindMetadata} from "./vote-kind-metadata";
import Vote from "../../data-types/vote";
import VoteKind from "../../data-types/vote-kind";

interface SelectVoteKindProps {
  className?: string | undefined;
  value?: VoteKind | undefined;
  onChange?: ((value: VoteKind | undefined) => void) | undefined;
}

const SelectVoteKind: React.FC<SelectVoteKindProps> = (props) => {
  const onChange = (e: any) => {
    props.onChange != null && props.onChange(e.target.value);
  };

  return (
    <Radio.Group className={props.className} onChange={onChange} value={props.value}>
    {voteKindMetadata.map(
        (item) => {
          return <Radio key={item.name} value={item.name}>{item.label}</Radio>;
        }
      )}
    </Radio.Group>
  );
};

export default SelectVoteKind;
export type {SelectVoteKindProps};
