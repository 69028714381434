import React from 'react';
import EventVoteChoice from "../data-types/event-vote-choice";
import TournamentVoteChoice from "../data-types/tournament-vote-choice";
import {Button, Typography} from "antd";
import {v4 as uuid} from 'uuid';
import Field from "./field";
import MultilingualStringInput from "./multilingual-string-input";
import ImageInput from "./image-input";
import LinkInput from "./link-input";
import emptyMultilingualString from '../utils/empty-multilingual-string';

type VoteChoice = EventVoteChoice & TournamentVoteChoice;
type PartialVoteChoice = Partial<VoteChoice>;

interface CommonDemandingVoteChoicesInputProps {
  className?: string | undefined;
  value?: PartialVoteChoice[];
  onChange?: ((value: PartialVoteChoice[]) => void) | undefined;
  limit?: number | undefined;
  requestUploadInfoPath: string;
}

const arrayReplaceAt = <T,>(arr: T[], replaceAt: number, newValue: T): T[] => {
  const copy = [...arr];
  copy[replaceAt] = newValue;
  return copy;
};

const arrayRemoveAt = <T,>(arr: T[], removeAt: number): T[] => {
  const copy = [...arr];
  copy.splice(removeAt, 1);
  return copy;
};

const CommonDemandingVoteChoicesInput: React.FC<CommonDemandingVoteChoicesInputProps> = (props) => {
  const value = props.value ?? [];

  const createAppendEmptyChoice = () => {
    const newChoice: PartialVoteChoice = {
      id: uuid(),
      content: {
        title: emptyMultilingualString,
        description: emptyMultilingualString,
        image: undefined,
        link: undefined,
      },
    };
    const newValue = [...value, newChoice];
    props.onChange != null && props.onChange(newValue);
  };

  const onCreateChoice = createAppendEmptyChoice;

  return (
    <div className={props.className}>
      <div>
        {value.map((choice, choiceIndex) => {
          const deleteCurrentChoice = () => {
            props.onChange != null && props.onChange(arrayRemoveAt(value, choiceIndex));
          };

          const changeChoiceField = (fieldName: string) => (newValue: any) => {
            const newChoice: PartialVoteChoice = {
              id: choice.id,
              content: {
                ...choice.content!,
                [fieldName]: newValue,
              },
            };
            props.onChange != null && props.onChange(arrayReplaceAt(value, choiceIndex, newChoice));
          };

          const onDeleteCurrentChoice = deleteCurrentChoice;
          const onChoiceFieldChange = changeChoiceField;

          return (
            <div key={choice.id}>
              <Typography.Title level={5}>#{choiceIndex + 1}</Typography.Title>

              <Field title={'제목'}>
                <MultilingualStringInput
                  value={choice.content!.title}
                  onChange={onChoiceFieldChange('title')}
                />
              </Field>

              <Field title={'설명'}>
                <MultilingualStringInput
                  value={choice.content!.description}
                  onChange={onChoiceFieldChange('description')}
                />
              </Field>

              <Field title={'이미지'}>
                <ImageInput
                  value={choice.content!.image}
                  onChange={onChoiceFieldChange('image')}
                  requestUploadInfoPath={props.requestUploadInfoPath}
                />
              </Field>

              <Field title={'링크'}>
                <LinkInput
                  value={choice.content!.link}
                  onChange={onChoiceFieldChange('link')}
                />
              </Field>

              <Button onClick={onDeleteCurrentChoice}>선택지 삭제</Button>
            </div>
          )
        })}
      </div>
      <Button onClick={onCreateChoice}>선택지 만들기</Button>
    </div>
  );
};

export default CommonDemandingVoteChoicesInput;
export type {CommonDemandingVoteChoicesInputProps};
