import FieldMetadata from "../data-types/field-metadata";
import {Typography} from "antd";
import Vote from "../data-types/vote";
import {Attraction, AttractionStatus} from "../data-types/attraction";

const buildAttractStatusFieldRender = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['render'] => {
  return (params) => {
    const value = params.value as unknown as Attraction['statusCd'];

    const getLabel = () => {
      switch (value) {
        case AttractionStatus.REQUEST: return '요청';
        case AttractionStatus.APPROVED: return '승인';
        case AttractionStatus.REJECT: return '반려';
      }
    };

    return <Typography.Text key={that.name}>{getLabel()}</Typography.Text>;
  };
};

export default buildAttractStatusFieldRender;
