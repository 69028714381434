import FieldMetadata from "../data-types/field-metadata";
import {Form, Input} from "antd";

const buildTextFieldRenderReadOnlyFormItem = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['renderReadOnlyFormItem'] => {
  return () => {
    return (
      <Form.Item
        key={that.name}
        label={that.label}
        name={that.name}
      >
        <Input disabled={true}/>
      </Form.Item>
    )
  };
};

export default buildTextFieldRenderReadOnlyFormItem;
