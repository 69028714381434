import useAuth from "./auth";
import Association from "../data-types/association";
import {apiAddr} from "../config";

const useUpdateAssociation = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<Association>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/associations/${id}`,
      data: payload,
    });
  };
};

export default useUpdateAssociation;
