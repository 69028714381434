import CollectionMetadata from "../../data-types/collection-metadata";
import useHandleAppError from "../../services/handle-app-error";
import FieldMetadata from "../../data-types/field-metadata";
import * as R from "ramda";
import {strict as assert} from 'assert';

interface Logic {
  onSubmit: (data: Record<string, any>) => void;
}

const buildLogic = <C>(collectionMetadata: CollectionMetadata<C>) => {
  return (): Logic => {
    assert(collectionMetadata.useCreate != null);

    const handleAppError = useHandleAppError();
    const toOverallPage = collectionMetadata.useToOverallPage();
    const createC = collectionMetadata.useCreate!();

    const onSubmit = async (formData: Record<string, any>) => {
      try {
        const isCustomizable = (field: FieldMetadata<C>) => field.customizable;

        const customizableKeys = R.pipe(
          R.filter(isCustomizable),
          R.map(R.prop('name')) as any
        )(collectionMetadata.fields as any) as string[];

        const createPayload = R.pick(customizableKeys, formData) as Partial<C>;

        await createC(createPayload);

        toOverallPage();
      } catch (error) {
        await handleAppError(error);
      }
    };

    return {onSubmit};
  };
};

export default buildLogic;
