import CollectionMetadata from "../../data-types/collection-metadata";
import {useEffect, useState} from "react";
import {strict as assert} from 'assert';
import Page from "../../data-types/page";
import useHandleAppError from "../../services/handle-app-error";
import WithId from '../with-id';

interface BaseLogic<T extends WithId> {
  limit: number;
  pageIndex: number;
  setPageIndex: (value: number) => void;
}

interface LoadingLogic<T extends WithId> extends BaseLogic<T> {
  status: 'LOADING';
}

interface LoadedLogic<T extends WithId> extends BaseLogic<T> {
  status: 'LOADED';
  page: Page<T>;
  onRowOfIndexPress: (index: number) => void;
}

interface FailedLogic<T extends WithId> extends BaseLogic<T> {
  status: 'FAILED';
}

type Logic<T extends WithId> = LoadingLogic<T> | LoadedLogic<T> | FailedLogic<T>;

interface BaseState<T extends WithId> {
  pageIndex: number;
}

interface LoadingState<T extends WithId> extends BaseState<T> {
  status: 'LOADING';
}

interface LoadedState<T extends WithId> extends BaseState<T> {
  status: 'LOADED';
  page: Page<T>;
}

interface FailedState<T extends WithId> extends BaseState<T> {
  status: 'FAILED';
}

type State<T extends WithId> = LoadingState<T> | LoadedState<T> | FailedState<T>;

const buildLogic = <T extends WithId>(collectionMetadata: CollectionMetadata<T>) => {
  const useLogic = (): Logic<T> => {
    const [state, setState] = useState<State<T>>({
      status: 'LOADING',
      pageIndex: 0,
    });

    const limit = 10;
    const readTs = collectionMetadata.useReadByPagination();
    const onDetails = collectionMetadata.useDefaultDetailsAction();
    const handleAppError = useHandleAppError();

    const tryInit = async () => {
      setState(oldState => ({
        status: 'LOADING',
        pageIndex: oldState.pageIndex,
      }));

      const page = await readTs({
        limit,
        skip: limit * state.pageIndex,
      });

      setState(oldState => ({
        status: 'LOADED',
        page,
        pageIndex: oldState.pageIndex,
      }));
    };

    const init = async () => {
      try {
        await tryInit();
      } catch (error) {
        setState(oldState => ({
          status: 'FAILED',
          pageIndex: oldState.pageIndex,
        }));
        await handleAppError(error);
      }
    };

    useEffect(() => { init(); }, [state.pageIndex]);

    const setPageIndex = (value: number) => {
      setState(oldState => ({
        ...oldState,
        pageIndex: value,
      }));
    };

    const baseLogic: BaseLogic<T> = {
      limit,
      setPageIndex,
      pageIndex: state.pageIndex
    };

    if (state.status === 'LOADING') {
      return {
        ...baseLogic,
        status: 'LOADING',
      };
    }

    if (state.status === 'FAILED') {
      return {
        ...baseLogic,
        status: 'FAILED',
      };
    }

    const onRowOfIndexPress = (rowIndex: number) => {
      const document = state.page.data[rowIndex];

      onDetails(document.id);
    };

    if (state.status === 'LOADED') {
      return {
        ...baseLogic,
        status: 'LOADED',
        page: state.page,
        onRowOfIndexPress,
      };
    }

    assert.fail();
  };

  return useLogic;
};

export default buildLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};
