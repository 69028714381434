import FieldMetadata from "../data-types/field-metadata";
import {Typography} from "antd";
import Period from "../data-types/period";

const buildPeriodFieldRender = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['render'] => {
  return (params) => {
    const value = params.value as unknown as Period;

    const getLabel = () => {
      return `${value.from.toLocaleString()} ~ ${value.to.toLocaleString()}`;
    };

    return <Typography.Text key={that.name}>{getLabel()}</Typography.Text>;
  };
};

export default buildPeriodFieldRender;
