import React from "react";
import WithId from "../with-id";
import CollectionMetadata from "../../data-types/collection-metadata";
import buildLogic from "./build-logic";
import requireLogin from "../../hocs/require-login";
import Layout from "../../components/layout";
import renderWhenLoadingOrFailed from "../render-when-loading-or-failed";
import { Button, Form } from "antd";

const buildUpdateDeletePage = <C extends WithId>(
  title: string,
  collectionMetadata: CollectionMetadata<C>
) => {
  const useLogic = buildLogic<C>(collectionMetadata);

  const Page: React.FC = requireLogin(() => {
    const logic = useLogic();

    return (
      <Layout title={title}>
        {renderWhenLoadingOrFailed(logic, (logic) => {
          return (
            <Form initialValues={logic.document} onFinish={logic.onSubmit}>
              {collectionMetadata.fields.map((field) => {
                if (field.customizable) {
                  return field.renderEditableFormItem!();
                } else {
                  return field.renderReadOnlyFormItem();
                }
              })}
              <Form.Item>
                <Button type={"primary"} htmlType={"submit"}>
                  수정
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  type={"primary"}
                  onClick={() => {
                    if (
                      window.confirm(
                        "해당데이터는 복구할수없습니다.\n삭제하시겠습니까?"
                      )
                    ) {
                      logic.onDelete(title);
                    }
                  }}
                >
                  삭제
                </Button>
              </Form.Item>
            </Form>
          );
        })}
      </Layout>
    );
  });

  return Page;
};

export default buildUpdateDeletePage;
