import React from 'react';
import Link from "../data-types/link";
import {Button, Radio} from "antd";
import {strict as assert} from 'assert';
import LinkEditBody from "./link-edit-body";

interface LinkInputProps {
  className?: string | undefined;
  value?: Link | undefined;
  onChange?: ((value: Link | undefined) => void) | undefined;
}

const getEmptyLink = (newKind: Link['kind']): Link => {
  switch (newKind) {
    case 'URL':
      return {kind: 'URL', url: ''};
  }

  assert.fail();
};

const LinkInput: React.FC<LinkInputProps> = (props) => {
  const changeKind = (newKind: Link['kind']) => {
    props.onChange != null && props.onChange(getEmptyLink(newKind));
  };

  const setAsEmpty = () => {
    props.onChange != null && props.onChange(undefined);
  };

  const onKindChangeFromDom = (e: any) => changeKind(e.target.value);
  const onDelete = setAsEmpty;
  const kind = props.value?.kind;

  return (
    <div>
      <Button onClick={onDelete}>삭제</Button>
      <Radio.Group onChange={onKindChangeFromDom} value={kind}>
        <Radio value={'URL'}>URL</Radio>
      </Radio.Group>
      <LinkEditBody value={props.value} onChange={props.onChange}/>
    </div>
  );
};

export default LinkInput;
export type {LinkInputProps};
