import WithId from "../with-id";
import CollectionMetadata from "../../data-types/collection-metadata";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import useHandleAppError from "../../services/handle-app-error";
import * as R from "ramda";
import { strict as assert } from "assert";
import FieldMetadata from "../../data-types/field-metadata";
import useDeleteAnnouncement from "../../services/delete-announcement";

interface LoadingLogic<C extends WithId> {
  status: "LOADING";
}

interface LoadedLogic<C extends WithId> {
  status: "LOADED";
  document: C;
  onSubmit: (formData: Record<string, any>) => void;
  onDelete: (title: string) => void;
}

interface FailedLogic<C extends WithId> {
  status: "FAILED";
}

type Logic<C extends WithId> =
  | LoadingLogic<C>
  | LoadedLogic<C>
  | FailedLogic<C>;

interface LoadingState<C extends WithId> {
  status: "LOADING";
}

interface LoadedState<C extends WithId> {
  status: "LOADED";
  document: C;
}

interface FailedState<C extends WithId> {
  status: "FAILED";
}

type State<C extends WithId> =
  | LoadingState<C>
  | LoadedState<C>
  | FailedState<C>;

const buildLogic = <C extends WithId>(
  collectionMetadata: CollectionMetadata<C>
) => {
  return (): Logic<C> => {
    const [state, setState] = useState<State<C>>({ status: "LOADING" });

    const urlParams = useParams();
    const readC = collectionMetadata.useRead();
    const updateC = collectionMetadata.useUpdate();
    const handleAppError = useHandleAppError();
    const documentId = urlParams.id!;
    const toOverallPage = collectionMetadata.useToOverallPage();
    const deleteC = useDeleteAnnouncement();

    const tryInit = async () => {
      const document = await readC(documentId);

      setState({
        status: "LOADED",
        document,
      });
    };

    const init = async () => {
      try {
        await tryInit();
      } catch (error) {
        setState({ status: "FAILED" });
        await handleAppError(error);
      }
    };

    useEffect(() => {
      init();
    }, []);

    if (state.status === "LOADING") {
      return { status: "LOADING" };
    }

    if (state.status === "FAILED") {
      return { status: "FAILED" };
    }

    const onSubmit = async (formData: Record<string, any>) => {
      try {
        const isCustomizable = (field: FieldMetadata<C>) => field.customizable;

        const customizableKeys = R.pipe(
          R.filter(isCustomizable),
          R.map(R.prop("name")) as any
        )(collectionMetadata.fields as any) as string[];

        const updatePayload = R.pick(customizableKeys, formData) as Partial<C>;

        await updateC(documentId, updatePayload);

        toOverallPage();
      } catch (error) {
        await handleAppError(error);
      }
    };

    const onDelete = async (title: string) => {
      if (title === "공지 수정") {
        try {
          await deleteC(documentId);
          toOverallPage();
        } catch (e) {
          console.log(e);
        }
      }
    };

    if (state.status === "LOADED") {
      return {
        status: "LOADED",
        document: state.document,
        onSubmit,
        onDelete,
      };
    }

    assert.fail();
  };
};

export default buildLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };
