import React from 'react';
import CollectionMetadata from "../../data-types/collection-metadata";
import styled from 'styled-components';
import CollectionDocumentView from "../collection-document-view";
import CollectionRefSelect from "../collection-ref-select";
import {Button} from 'antd';

const Cont = styled.div`
  display: flex;
  flex-direction: column;
`;

const TheCollectionDocumentView = styled(CollectionDocumentView)`
  flex: 1;
`;

const Input = styled.div`
  display: flex;
`;

interface CollectionRefInputProps {
  className?: string | undefined;
  collectionMetadata: CollectionMetadata<any>;
  value?: string | undefined;
  disabled?:boolean|undefined
  onChange?: ((value: string | undefined) => void) | undefined;
}

const CollectionRefInput: React.FC<CollectionRefInputProps> = (props) => {
  const onDeletePress = () => {
    props.onChange != null && props.onChange(undefined);
  };

  const onDocumentPress = (document: {id: string}) => {
    props.onChange != null && props.onChange(document.id);
  };

  return (
    <Cont>
      <Input>
        <TheCollectionDocumentView collectionMetadata={props.collectionMetadata} value={props.value} />
        <Button danger onClick={onDeletePress}>
          삭제
        </Button>
      </Input>
      <CollectionRefSelect onPress={onDocumentPress} collectionMetadata={props.collectionMetadata} />
    </Cont>
  );
};

export default CollectionRefInput;
export type {CollectionRefInputProps};
