import FromServerSerializer from "../data-types/from-server-serializer";
import useAuth from "../services/auth";
import { apiAddr } from "../config";

interface BuildParams<T> {
  collection: string;
  serializer: FromServerSerializer<T>;
}

const buildReadCollectionDocument =
  <T>(buildParams: BuildParams<T>) =>
  () => {
    const auth = useAuth();

    return async (documentId: string): Promise<T> => {
      auth.checkIsLoggedIn();
      console.log("KDB TEST:::: documentId: " + documentId);
      const axiosResult = await auth.callAxios({
        method: "get",
        url: `${apiAddr}/admin/v1/${buildParams.collection}/${documentId}`,
      });

      const getSerializerParams = () => {
        if (buildParams.serializer.length === 2) {
          return [auth, axiosResult.data];
        }

        return [axiosResult.data];
      };

      // @ts-ignore
      const result = await buildParams.serializer(...getSerializerParams());
      return result;
    };
  };

export default buildReadCollectionDocument;
