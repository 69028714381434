import buildPaginatedPage from "../../utils/build-paginated-page";
import Region from '../../data-types/region';
import regionCollectionMetadata from '../../data-metadata/region';

const RegionsPage = buildPaginatedPage<Region>({
  title: '지역 관리',
  collectionMetadata: regionCollectionMetadata,
});

export default RegionsPage;
