import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoginPage from "../pages/login";
import MainPage from "../pages/main";

import UsersPage from "../pages/users";
import UpdateUserPage from "../pages/update-user";

import CountriesPage from "../pages/countries";
import UpdateCountryPage from "../pages/update-country";
import CreateCountryPage from "../pages/create-country";

import RegionsPage from "../pages/regions";
import UpdateRegionPage from "../pages/update-region";
import CreateRegionPage from "../pages/create-region";

import VoteCategoriesPage from "../pages/vote-categories";
import UpdateVoteCategoryPage from "../pages/update-vote-category";
import CreateVoteCategoryPage from "../pages/create-vote-category";

import TourCategoriesPage from "../pages/tour-categories";
import UpdateTourCategoryPage from "../pages/update-tour-category";
import CreateTourCategoryPage from "../pages/create-tour-category";

import IdolsPage from "../pages/idols";
import UpdateIdolPage from "../pages/update-idol";
import CreateIdolPage from "../pages/create-idol";

import AssociationsPage from "../pages/associations";
import UpdateAssociationPage from "../pages/update-association";
import CreateAssociationPage from "../pages/create-association";

import VotesPage from "../pages/votes";
import UpdateVotePage from "../pages/update-vote";
import CreateVotePage from "../pages/create-vote";

import AnnouncementsPage from "../pages/announcements";
import UpdateAnnouncementPage from "../pages/update-announcement";
import CreateAnnouncementPage from "../pages/create-announcement";

import UpdateAppActiveIdolVotesPage from "../pages/update-app-active-idol-votes";
import UpdateAppMainBannersPage from "../pages/update-app-main-banners";
import UpdateAppMainPageContentsPage from "../pages/update-app-main-page-contents";
import UpdateAppMaxIdolVoteCountPerDayPage from "../pages/update-app-max-idol-vote-count-per-day";
import UpdateAppRewardingTicketCountPage from "../pages/update-app-rewarding-ticket-count";

import NotFoundPage from "../pages/not-found";
import QnasPage from "../pages/qnas";
import QnaDetailPage from "../pages/update-qna";
import ResultVotePage from "../pages/result-vote";
import ResultVoteDetailPage from "../pages/update-result-vote/indext";
import PushAlarmPage from "../pages/push-alarm";
import PushAlarmDetailPage from "../pages/update-push-alarm/indext";
import AttractionPage from "../pages/attraction";
// import UpdateAttractionPage from "../pages/update-attraction";
import CreateAttraction from "../pages/create-attraction";
import CreateAttractionPage from "../pages/create-attraction";
import UpdateAttractionPage from "../pages/update-attraction";

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route index element={<MainPage />} />

        <Route path="users" element={<UsersPage />} />
        <Route path="update-user/:id" element={<UpdateUserPage />} />

        <Route path="countries" element={<CountriesPage />} />
        <Route path="update-country/:id" element={<UpdateCountryPage />} />
        <Route path="create-country" element={<CreateCountryPage />} />

        <Route path={"regions"} element={<RegionsPage />} />
        <Route path={"update-region/:id"} element={<UpdateRegionPage />} />
        <Route path={"create-region"} element={<CreateRegionPage />} />

        <Route path={"vote-categories"} element={<VoteCategoriesPage />} />
        <Route
          path={"update-vote-category/:id"}
          element={<UpdateVoteCategoryPage />}
        />
        <Route
          path={"create-vote-category"}
          element={<CreateVoteCategoryPage />}
        />

        <Route path={"idols"} element={<IdolsPage />} />
        <Route path={"update-idol/:id"} element={<UpdateIdolPage />} />
        <Route path={"create-idol"} element={<CreateIdolPage />} />

        <Route path={"associations"} element={<AssociationsPage />} />
        <Route
          path={"update-association/:id"}
          element={<UpdateAssociationPage />}
        />
        <Route
          path={"create-association"}
          element={<CreateAssociationPage />}
        />

        <Route path={"votes"} element={<VotesPage />} />
        <Route path={"update-vote/:id"} element={<UpdateVotePage />} />
        <Route path={"create-vote"} element={<CreateVotePage />} />

        <Route path={"announcements"} element={<AnnouncementsPage />} />
        <Route
          path={"update-announcement/:id"}
          element={<UpdateAnnouncementPage />}
        />
        <Route
          path={"create-announcement"}
          element={<CreateAnnouncementPage />}
        />

        {/*<Route path={'update-app-active-idol-votes'} element={<UpdateAppActiveIdolVotesPage/>}/>*/}
        <Route
          path={"update-app-main-banners"}
          element={<UpdateAppMainBannersPage />}
        />
        <Route
          path={"update-app-main-page-contents"}
          element={<UpdateAppMainPageContentsPage />}
        />
        <Route
          path={"update-app-max-idol-vote-count-per-day"}
          element={<UpdateAppMaxIdolVoteCountPerDayPage />}
        />
        <Route
          path={"update-app-rewarding-ticket-count"}
          element={<UpdateAppRewardingTicketCountPage />}
        />

        <Route path={"tour-categories"} element={<TourCategoriesPage />} />
        <Route
          path={"update-tour-category/:id"}
          element={<UpdateTourCategoryPage />}
        />
        <Route
          path={"create-tour-category"}
          element={<CreateTourCategoryPage />}
        />
        {/* 어트랙션 관리 */}
        <Route path={"attraction"} element={<AttractionPage />} />
        <Route
          path={"update-attraction/:id"}
          element={<UpdateAttractionPage />}
        />
        <Route path={"create-attraction"} element={<CreateAttractionPage />} />

        {/*투표 결과 */}
        <Route path={"result-vote"} element={<ResultVotePage />} />
        <Route path={"result-vote/:id"} element={<ResultVoteDetailPage />} />

        {/*1대1 문의*/}
        <Route path={"qna"} element={<QnasPage />} />
        <Route path={"qna/:id"} element={<QnaDetailPage />} />

        {/* 푸쉬 알람 */}
        <Route path={"push-alarm"} element={<PushAlarmPage />} />
        <Route path={"push-alarm/:id"} element={<PushAlarmDetailPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
