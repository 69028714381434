import FieldMetadata from "../data-types/field-metadata";
import {Form, Radio} from "antd";

const buildAnnouncementKindFieldRenderEditableFormItem = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['renderEditableFormItem'] => {
  return () => {
    return (
      <Form.Item
        key={that.name}
        label={that.label}
        name={that.name}
      >
        <Radio.Group>
          <Radio value={'INFO'}>
            정보
          </Radio>
          <Radio value={'EVENT'}>
            이벤트
          </Radio>
        </Radio.Group>
      </Form.Item>
    );
  };
};

export default buildAnnouncementKindFieldRenderEditableFormItem;
