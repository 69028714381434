import CollectionMetadata from "../data-types/collection-metadata";
import Country from "../data-types/country";
import useReadCountry from "../services/read-country";
import useToCountriesPage from "../services/to-countries-page";
import useUpdateCountry from "../services/update-country";
import useToUpdateCountryPage from "../services/to-update-country-page";
import useReadCountries from "../services/read-countries";
import FieldMetadata from "../data-types/field-metadata";
import buildTextFieldRender from "../utils/build-text-field-render";
import buildTextFieldRenderReadOnlyFormItem from "../utils/build-text-field-render-read-only-form-item";
import buildMultilingualStringFieldRender from "../utils/build-multilingual-string-field-render";
import buildMultilingualStringFieldRenderEditableFormItem
  from "../utils/build-multilingual-string-field-render-editable-form-item";
import buildMultilingualStringFieldRenderReadOnlyFormItem
  from "../utils/build-multilingual-string-field-render-read-only-form-item";
import useCreateCountry from "../services/create-country";
import useToCreateCountryPage from "../services/to-create-country-page";

const countryCollectionMetadata: CollectionMetadata<Country> = {
  useRead: useReadCountry,
  useToOverallPage: useToCountriesPage,
  useUpdate: useUpdateCountry,
  useDefaultDetailsAction: useToUpdateCountryPage,
  useReadByPagination: useReadCountries,
  useCreate: useCreateCountry,
  useToCreatePage: useToCreateCountryPage,
  fields: [
    new class implements FieldMetadata<Country> {
      readonly name = 'id';
      readonly label = 'UID';
      readonly customizable = false;

      // @ts-ignore
      readonly render = buildTextFieldRender<Country>(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem<Country>(this);
    },
    new class implements FieldMetadata<Country> {
      readonly name = 'name';
      readonly label = '이름';
      readonly customizable = true;

      // @ts-ignore
      readonly render = buildMultilingualStringFieldRender<Country>(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildMultilingualStringFieldRenderReadOnlyFormItem<Country>(this);
      // @ts-ignore
      readonly renderEditableFormItem = buildMultilingualStringFieldRenderEditableFormItem<Country>(this);
    },
  ],
  fieldsForBrief: ['id', 'name'],
};

export default countryCollectionMetadata;
