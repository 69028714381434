import MultilingualString from "../data-types/multilingual-string";

const fromMultilingualString = (
  multilingualString: MultilingualString
): string => {
  if ("kr" in multilingualString) {
    return multilingualString.kr!;
  }

  return multilingualString.en;
};

export default fromMultilingualString;
