import FieldMetadata from "../data-types/field-metadata";
import {Checkbox, Form, Input} from "antd";

const buildBooleanFieldRenderReadOnlyFormItem = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['renderReadOnlyFormItem'] => {
  return () => {
    return (
      <Form.Item
        key={that.name}
        label={that.label}
        name={that.name}
        valuePropName={'checked'}
      >
        <Checkbox disabled={true}/>
      </Form.Item>
    );
  };
};

export default buildBooleanFieldRenderReadOnlyFormItem;
