import React from 'react';
import WithId from '../with-id';
import CollectionMetadata from "../../data-types/collection-metadata";
import buildLogic from "./build-logic";
import requireLogin from "../../hocs/require-login";
import Layout from "../../components/layout";
import renderWhenLoadingOrFailed from "../render-when-loading-or-failed";
import {Button, Form} from "antd";

const buildUpdatePage = <C extends WithId>(
  title: string,
  collectionMetadata: CollectionMetadata<C>
) => {
  const useLogic = buildLogic<C>(collectionMetadata);

  const Page: React.FC = requireLogin(() => {
    const logic = useLogic();

    return (
      <Layout title={title}>
        {renderWhenLoadingOrFailed(
          logic,
          logic => {
            return (
              <Form initialValues={logic.document} onFinish={logic.onSubmit}>
                {collectionMetadata.fields.map((field) => {
                  if (field.customizable) {
                    return field.renderEditableFormItem!();
                  } else {
                    return field.renderReadOnlyFormItem();
                  }
                })}
                <Form.Item>
                  <Button type={'primary'} htmlType={'submit'}>
                    수정
                  </Button>
                </Form.Item>
              </Form>
            );
          }
        )}
      </Layout>
    );
  });

  return Page;
};

export default buildUpdatePage;
