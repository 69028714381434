import React from 'react';
import buildCreatePage from "../../utils/build-create-page";
import VoteCategory from "../../data-types/vote-category";
import voteCategoryCollectionMetadata from "../../data-metadata/vote-category";

const CreateVoteCategoryPage = buildCreatePage<VoteCategory>({
  title: '투표 카테고리 생성',
  collectionMetadata: voteCategoryCollectionMetadata,
});

export default CreateVoteCategoryPage;
