import Association from '../data-types/association';
import CollectionMetadata from "../data-types/collection-metadata";
import useReadAssociation from "../services/read-association";
import useToAssociationsPage from "../services/to-associations-page";
import useUpdateAssociation from "../services/update-association";
import useToUpdateAssociationPage from "../services/to-update-association-page";
import useReadAssociations from "../services/read-associations";
import useCreateAssociation from "../services/create-association";
import useToCreateAssociationPage from "../services/to-create-association-page";
import FieldMetadata from "../data-types/field-metadata";
import buildTextFieldRender from "../utils/build-text-field-render";
import buildTextFieldRenderReadOnlyFormItem from "../utils/build-text-field-render-read-only-form-item";
import buildTextFieldRenderEditableFormItem from "../utils/build-text-field-render-editable-form-item";
import buildMultilingualStringFieldRender from "../utils/build-multilingual-string-field-render";
import buildMultilingualStringFieldRenderReadOnlyFormItem
  from "../utils/build-multilingual-string-field-render-read-only-form-item";
import buildMultilingualStringFieldRenderEditableFormItem
  from "../utils/build-multilingual-string-field-render-editable-form-item";

class IdField implements FieldMetadata<Association> {
  readonly name = 'id';
  readonly label = 'UID';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildTextFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildTextFieldRenderEditableFormItem(this);
}

class NameField implements FieldMetadata<Association> {
  readonly name = 'name';
  readonly label = '이름';
  readonly customizable = true;

  // @ts-ignore
  readonly render = buildMultilingualStringFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildMultilingualStringFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildMultilingualStringFieldRenderEditableFormItem(this);
}

const associationCollectionMetadata: CollectionMetadata<Association> = {
  useRead: useReadAssociation,
  useToOverallPage: useToAssociationsPage,
  useUpdate: useUpdateAssociation,
  useDefaultDetailsAction: useToUpdateAssociationPage,
  useReadByPagination: useReadAssociations,
  useCreate: useCreateAssociation,
  useToCreatePage: useToCreateAssociationPage,
  fields: [
    new IdField(),
    new NameField(),
  ],
  fieldsForBrief: ['id', 'name'],
};

export default associationCollectionMetadata;
