import { Button, Divider } from "antd";
import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CountryType } from "../../../data-types/country";
import {
  ResultVoteImageType,
  ResultVoteRankingType,
  ResultVoteType,
} from "../../../data-types/result-vote";
import useAuth from "../../../services/auth";
import ResultVoteAPI from "../../../services/resultVoteAPI";
import ResultVoteCategory from "./result-vote-category";
import ResultVoteImageForm from "./result-vote-image-form";
import ResultVoteTextForm from "./result-vote-text-form";

const ResultVoteDetail: React.FC = () => {
  const [isNew, setIsNew] = useState(true);
  const ref = createRef<HTMLSelectElement>();
  const [categoryId, setCategoryId] = useState("");
  const [title, setTitle] = useState<Partial<Record<CountryType, string>>>({
    en: "",
    kr: "",
    zh: "",
  });
  const [subtitle, setSubtitle] = useState<
    Partial<Record<CountryType, string>>
  >({ en: "", kr: "", zh: "" });
  const [backgroundImg, setBackgroundImg] =
    useState<Partial<ResultVoteImageType>>();
  const [currentRankingImg, setCurrentRankingImg] =
    useState<Partial<ResultVoteImageType>>();
  const [first, setFirst] = useState<Partial<ResultVoteRankingType>>();
  const [second, setSecond] = useState<Partial<ResultVoteRankingType>>();
  const [third, setThird] = useState<Partial<ResultVoteRankingType>>();
  const [benefits, setBenefits] = useState<Partial<ResultVoteImageType>>();
  const [beforeRankingImg, setBeforeRankingImg] =
    useState<Partial<ResultVoteImageType>>();

  const navigator = useNavigate();

  const { id } = useParams();

  const auth = useAuth();
  const api = useMemo(() => new ResultVoteAPI(auth), [auth]);
  const handleImageChange = (
    e: Partial<ResultVoteImageType>,
    title: string
  ) => {
    switch (title) {
      case "배경이미지":
        setBackgroundImg(e);
        break;
      case "현재 랭킹":
        setCurrentRankingImg(e);
        break;
      case "1등":
        setFirst({ ...e, url: first?.url ?? "", ranking: 1 });
        break;
      case "2등":
        setSecond({ ...e, url: second?.url ?? "", ranking: 2 });
        break;
      case "3등":
        setThird({ ...e, url: third?.url ?? "", ranking: 3 });
        break;
      case "혜택":
        setBenefits(e);
        break;
      case "지난달 랭킹":
        setBeforeRankingImg(e);
        break;
      default:
        throw new Error("올바른 Title이 아닙니다.");
    }
  };

  const handleUrlChange = (e: string, title: string) => {
    switch (title) {
      case "1등":
        first && setFirst({ ...first, url: e, ranking: 1 });
        break;
      case "2등":
        second && setSecond({ ...second, url: e, ranking: 2 });
        break;
      case "3등":
        third && setThird({ ...third, url: e, ranking: 3 });
        break;

      default:
        throw new Error("올바른 Title이 아닙니다.");
    }
  };

  const handleTextChange = (e: string, country: CountryType, title: string) => {
    switch (title) {
      case "Title":
        setTitle((prev) => ({ ...prev, [country]: e }));
        break;
      case "SubTitle":
        setSubtitle((prev) => ({ ...prev, [country]: e }));
        break;

      default:
        throw new Error("올바른 Title이 아닙니다.");
    }
  };
  const handleCategory = (e: string) => {
    setCategoryId(e);
  };
  const onSubmit = async () => {
    const data: Partial<ResultVoteType> = {
      id,
      category: categoryId,
      title: { en: title?.en, kr: title?.kr, zh: title?.zh },
      subtitle: { en: subtitle?.en, kr: subtitle?.kr, zh: subtitle?.zh },
      backgroundImg: {
        en: backgroundImg && backgroundImg?.en?.storeKey,
        kr: backgroundImg && backgroundImg?.kr?.storeKey,
        zh: backgroundImg && backgroundImg?.zh?.storeKey,
      },
      currentRankingImg: {
        en: currentRankingImg && currentRankingImg?.en?.storeKey,
        kr: currentRankingImg && currentRankingImg?.kr?.storeKey,
        zh: currentRankingImg && currentRankingImg?.zh?.storeKey,
      },
      imgFirst: {
        en: first && first?.en?.storeKey,
        kr: first && first?.kr?.storeKey,
        zh: first && first?.zh?.storeKey,
      },
      firstUrlLink: first?.url,
      imgSecond: {
        en: second && second?.en?.storeKey,
        kr: second && second?.kr?.storeKey,
        zh: second && second?.zh?.storeKey,
      },
      secondUrlLink: second?.url,
      imgThird: {
        en: third && third?.en?.storeKey,
        kr: third && third?.kr?.storeKey,
        zh: third && third?.zh?.storeKey,
      },
      thirdUrlLink: third?.url,
      benefits: {
        en: benefits && benefits?.en?.storeKey,
        kr: benefits && benefits?.kr?.storeKey,
        zh: benefits && benefits?.zh?.storeKey,
      },
      beforeRankingImg: {
        en: beforeRankingImg && beforeRankingImg?.en?.storeKey,
        kr: beforeRankingImg && beforeRankingImg?.kr?.storeKey,
        zh: beforeRankingImg && beforeRankingImg?.zh?.storeKey,
      },
    };

    try {
      let result;
      switch (isNew) {
        case true:
          result = await api.create(data);
          break;
        case false:
          result = await api.patch(id!, data);
          break;
      }

      if (result) {
        window.alert("저장이 완료 되었습니다.");
        navigator("/result-vote");
        return;
      }
      throw Error;
    } catch {
      window.alert("저장 도중 문제가 발생했습니다.");
    }
  };
  const onDelete = async () => {
    try {
      if (id && id !== "new") {
        await api.delete(id);
        window.alert("삭제 되었습니다.");
        navigator("/result-vote");
        return;
      }
      throw Error;
    } catch {
      window.alert("삭제 도중 문제가 발생 했습니다.");
      navigator("/result-vote");
    }
  };
  const getData = useCallback(async () => {
    try {
      const data = await api.get(id!);

      setTitle({
        en: data?.title?.en,
        kr: data?.title?.kr,
        zh: data?.title?.zh,
      });
      setCategoryId(data.category);
      setSubtitle({
        en: data?.subtitle?.en,
        kr: data?.subtitle?.kr,
        zh: data?.subtitle?.zh,
      });
      setBackgroundImg({
        en: { isNew: false, url: data?.backgroundImg?.en },
        kr: { isNew: false, url: data?.backgroundImg?.kr },
        zh: { isNew: false, url: data?.backgroundImg?.zh },
      });
      setCurrentRankingImg({
        en: { isNew: false, url: data?.currentRankingImg?.en },
        kr: { isNew: false, url: data?.currentRankingImg?.kr },
        zh: { isNew: false, url: data?.currentRankingImg?.zh },
      });
      setFirst({
        ranking: 1,
        url: data?.firstUrlLink,
        en: { isNew: false, url: data?.imgFirst?.en },
        kr: { isNew: false, url: data?.imgFirst?.kr },
        zh: { isNew: false, url: data?.imgFirst?.zh },
      });
      setSecond({
        ranking: 2,
        url: data?.secondUrlLink,
        en: { isNew: false, url: data?.imgFirst?.en },
        kr: { isNew: false, url: data?.imgFirst?.kr },
        zh: { isNew: false, url: data?.imgFirst?.zh },
      });
      setThird({
        ranking: 3,
        url: data?.thirdUrlLink,
        en: { isNew: false, url: data?.imgFirst?.en },
        kr: { isNew: false, url: data?.imgFirst?.kr },
        zh: { isNew: false, url: data?.imgFirst?.zh },
      });
      setBenefits({
        en: { isNew: false, url: data?.benefits?.en },
        kr: { isNew: false, url: data?.benefits?.kr },
        zh: { isNew: false, url: data?.benefits?.zh },
      });
      setBeforeRankingImg({
        en: { isNew: false, url: data?.beforeRankingImg?.en },
        kr: { isNew: false, url: data?.beforeRankingImg?.kr },
        zh: { isNew: false, url: data?.beforeRankingImg?.zh },
      });
    } catch (e) {
      window.alert("문제가 발생했습니다.");
      navigator("/result-vote");
    }
  }, []);
  useEffect(() => {
    if (id === "new") {
      setIsNew(true);
    } else if (id) {
      setIsNew(false);
      getData();
    } else {
      window.alert("잘못 된 접근 입니다.");
      navigator("/result-vote");
    }
  }, [id]);

  return (
    <>
      <Divider />
      <div>
        <ResultVoteCategory onChange={handleCategory} categoryId={categoryId} />
        <Button
          disabled={isNew}
          type="primary"
          htmlType="button"
          style={{ float: "right", marginRight: "1rem" }}
          onClick={onDelete}
        >
          삭제
        </Button>
      </div>
      <br />
      <ResultVoteTextForm
        title="Title"
        value={title}
        onChange={handleTextChange}
      />
      <ResultVoteTextForm
        title="SubTitle"
        value={subtitle}
        onChange={handleTextChange}
      />
      <ResultVoteImageForm
        title="배경이미지"
        value={backgroundImg}
        onImageChange={handleImageChange}
      />
      <ResultVoteImageForm
        title="현재 랭킹"
        value={currentRankingImg}
        onImageChange={handleImageChange}
      />
      <ResultVoteImageForm
        title="1등"
        value={first}
        onImageChange={handleImageChange}
        onUrlLinkChange={handleUrlChange}
        urlLink={first?.url}
      />
      <ResultVoteImageForm
        title="2등"
        value={second}
        onImageChange={handleImageChange}
        onUrlLinkChange={handleUrlChange}
        urlLink={second?.url}
      />
      <ResultVoteImageForm
        title="3등"
        value={third}
        onImageChange={handleImageChange}
        onUrlLinkChange={handleUrlChange}
        urlLink={third?.url}
      />
      <ResultVoteImageForm
        title="혜택"
        value={benefits}
        onImageChange={handleImageChange}
      />
      <ResultVoteImageForm
        title="지난달 랭킹"
        value={beforeRankingImg}
        onImageChange={handleImageChange}
      />
      <Button
        type="primary"
        htmlType="button"
        onClick={() => {
          navigator("/result-vote");
        }}
        style={{ float: "left", margin: "1rem" }}
      >
        목록보기
      </Button>
      <Button
        type="primary"
        htmlType="button"
        style={{ float: "right", margin: "1rem" }}
        onClick={onSubmit}
      >
        저장
      </Button>
    </>
  );
};

export default ResultVoteDetail;
