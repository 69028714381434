import React from 'react';
import {Input} from "antd";
const { TextArea } = Input;
interface TextAreaInputProps {
  className?: string | undefined;
  value?: string | undefined;
  rows?:number |4
  disabled?:boolean | false;
  onChange?: ((value: string) => void) | undefined;
  onKeyDown?: ((value: any) => void) | undefined;
}

const TextAreaInput: React.FC<TextAreaInputProps> = (props) => {
  const onChange = (e: any) => props.onChange != null && props.onChange(e.target.value);
  const onKeyDown = (e:any) => props.onKeyDown!= null && props.onKeyDown(e);
  return <TextArea className={props.className} rows={props.rows} value={props.value} disabled={props.disabled} onChange={onChange} onKeyDown={onKeyDown} />;
};

export default TextAreaInput;
export type {TextAreaInputProps};
