import React from "react";
import CollectionMetadata from "../../data-types/collection-metadata";
import buildLogic from "./build-logic";
import WithId from "../with-id";
import Layout from "../../components/layout";
import renderWhenLoadingOrFailed from "../render-when-loading-or-failed";
import { Button, Table, TablePaginationConfig } from "antd";
import requireLogin from "../../hocs/require-login";

interface Params<T extends WithId> {
  title: string;
  collectionMetadata: CollectionMetadata<T>;
}

const buildPaginatedPage = <T extends WithId>({
  title,
  collectionMetadata,
}: Params<T>) => {
  const useLogic = buildLogic<T>(collectionMetadata);

  const Page: React.FC = requireLogin(() => {
    const logic = useLogic();
    const toCreate =
      collectionMetadata.useToCreatePage != null
        ? collectionMetadata.useToCreatePage()
        : undefined;

    return (
      <Layout title={title}>
        {toCreate != null ? (
          <Button type="primary" onClick={toCreate}>
            생성
          </Button>
        ) : null}
        {renderWhenLoadingOrFailed(logic, (logic) => {
          logic.page.data.map((document) => {
            console.log("KDB PAGINATEDPAGE:::: " + document.id);
          });
          const dataSource = logic.page.data.map((document) => ({
            ...document,
            key: document.id,
          }));

          const columns = collectionMetadata.fields.map((field) => ({
            title: field.label,
            dataIndex: field.name,
            render: (value: any) => field.render({ value }),
          }));

          const pagination = {
            defaultPageSize: logic.limit,
            defaultCurrent: logic.pageIndex + 1,
            total: logic.page.totalCount,
          };

          const onChange = (config: TablePaginationConfig) => {
            const current = config.current ?? 1;
            logic.setPageIndex(current - 1);
          };

          const onRow = (_: any, index: number | undefined) => {
            if (index == null) {
              return {};
            }

            return {
              onClick: () => logic.onRowOfIndexPress(index),
              style: { cursor: "pointer" },
            };
          };

          return (
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={pagination}
              onChange={onChange}
              onRow={onRow}
            />
          );
        })}
      </Layout>
    );
  });

  return Page;
};

export default buildPaginatedPage;
