import {AuthData} from "../utils/auth";
import useLogoutIfLoggedIn from "./logout-if-logged-in";
import useAuth from "./auth";

const useLoginWithAuthData = () => {
  const logoutIfLoggedIn = useLogoutIfLoggedIn();
  const auth = useAuth();

  return async (authData: AuthData) => {
    await logoutIfLoggedIn();
    auth.login(authData);
  };
};

export default useLoginWithAuthData;
