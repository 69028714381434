// import React from 'react';
// import useLogic from "./use-logic";
// import Layout from "../../components/layout";
// import { Form, Input, Button, Checkbox, Spin, Alert } from 'antd';
// import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";
// import {strict as assert} from 'assert';
// import requireLogin from "../../hocs/require-login";
//
// const UpdateUserPage: React.FC = requireLogin(() => {
//   const logic = useLogic();
//
//   return (
//     <Layout title={'유저 수정'}>
//       {renderWhenLoadingOrFailed(
//         logic,
//         logic => {
//           const getRoleText = () => {
//             if (logic.user.role === 'admin') {
//               return '관리자';
//             }
//
//             if (logic.user.role === 'user') {
//               return '일반';
//             }
//
//             assert.fail();
//           };
//
//           return (
//             <Form
//               initialValues={{
//                 name: logic.user.name,
//                 email: logic.user.email,
//                 birth: logic.user.birth,
//                 ticketCount: logic.user.ticketCount,
//                 isEmailVerified: logic.user.isEmailVerified,
//                 createdAt: logic.user.createdAt.toLocaleString(),
//                 role: getRoleText(),
//               }}
//               onFinish={logic.onSubmit}
//             >
//               <Form.Item
//                 label={'이름'}
//                 name={'name'}
//               >
//                 <Input />
//               </Form.Item>
//
//               <Form.Item
//                 label={'이메일'}
//                 name={'email'}
//               >
//                 <Input />
//               </Form.Item>
//
//               <Form.Item
//                 label={'생일'}
//                 name={'birth'}
//               >
//                 <Input />
//               </Form.Item>
//
//               <Form.Item
//                 label={'티켓'}
//                 name={'ticketCount'}
//               >
//                 <Input disabled={true}/>
//               </Form.Item>
//
//               <Form.Item
//                 label={'이메일 인증'}
//                 name={'isEmailVerified'}
//                 valuePropName={'checked'}
//               >
//                 <Checkbox/>
//               </Form.Item>
//
//               <Form.Item
//                 label={'생성일'}
//                 name={'createdAt'}
//               >
//                 <Input disabled={true}/>
//               </Form.Item>
//
//               <Form.Item
//                 label={'역할'}
//                 name={'role'}
//               >
//                 <Input disabled={true}/>
//               </Form.Item>
//
//               <Form.Item>
//                 <Button type={'primary'} htmlType={'submit'}>
//                   수정
//                 </Button>
//               </Form.Item>
//             </Form>
//           );
//         }
//       )}
//     </Layout>
//   );
// });
//
// export default UpdateUserPage;

import buildUpdatePage from "../../utils/build-update-page";
import userCollectionMetadata from "../../data-metadata/user";

const UpdateUserPage = buildUpdatePage('유저 수정', userCollectionMetadata);

export default UpdateUserPage;
