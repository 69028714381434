import FieldMetadata from "../data-types/field-metadata";
import {Typography} from "antd";
import MultilingualString from "../data-types/multilingual-string";
import fromMultilingualString from "./from-multilingual-string";

const buildMultilingualStringFieldRender = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['render'] => {
  return (params) => {
    const value = params.value as unknown as MultilingualString;

    return <Typography.Text key={that.name}>{fromMultilingualString(value)}</Typography.Text>;
  };
};

export default buildMultilingualStringFieldRender;
