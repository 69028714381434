import useAuth from "./auth";
import IdolVote from "../data-types/idol-vote";
import {apiAddr} from "../config";
import serializeVote from '../from-server-serializers/vote';
import IdolVoteCandidate from "../data-types/idol-vote-candidate";
import createIdolVoteCandidateFragment from "../utils/create-idol-vote-candidate-fragment";

const useCreateIdolVote = () => {
  const auth = useAuth();

  return async (payload: Partial<IdolVote>): Promise<IdolVote> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/votes`,
      data: {
        voteKind: 'IDOL',
        question: payload.question,
        modification: payload.modification,
        backgroundImage: payload.backgroundImage?.storeKey,
        tabName: payload.tabName,
        description: payload.description,
        period: payload.period,
      },
    });

    const idolVote = (await serializeVote(auth, axiosResult.data)) as IdolVote;

    const createCandidate = createIdolVoteCandidateFragment(auth, idolVote.id);

    const candidates = await Promise.all(payload.candidates!.map(createCandidate));

    idolVote.candidates = candidates;

    return idolVote;
  };
};

export default useCreateIdolVote;
