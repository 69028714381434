import FieldMetadata from "../data-types/field-metadata";
import {Checkbox, Form} from "antd";

const buildBooleanFieldRenderEditableFormItem = <T,>(that: FieldMetadata<T>) => () => {
  return (
    <Form.Item
      key={that.name}
      label={that.label}
      name={that.name}
      valuePropName={'checked'}
    >
      <Checkbox/>
    </Form.Item>
  );
};

export default buildBooleanFieldRenderEditableFormItem;
