import FieldMetadata from "../data-types/field-metadata";
import {Typography} from "antd";
import User from "../data-types/user";
import {strict as assert} from "assert";
import fromRole from "./from-role";

const buildRoleFieldRender = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['render'] => {
  return (params) => {
    const value = params.value as unknown as User['role'];

    return <Typography.Text key={that.name}>{fromRole(value)}</Typography.Text>;
  };
};

export default buildRoleFieldRender;
