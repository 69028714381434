import React from 'react';
import MultilingualString from "../data-types/multilingual-string";
import ExternalMultilingualStringInput from '../components/multilingual-string-input';

interface MultilingualStringInputProps {
  className?: string | undefined;
  disabled?:boolean|undefined;
  value?: MultilingualString | undefined;
  onChange?: ((value: MultilingualString | undefined) => void) | undefined;
}

const MultilingualStringInput: React.FC<MultilingualStringInputProps> = (props) => {
  return (
    <ExternalMultilingualStringInput
      className={props.className}
      value={props.value}
      disabled = {props.disabled}
      onChange={props.onChange}
    />
  );
};

export default MultilingualStringInput;
