import Period from "../data-types/period";

const serialize = (r: Record<string, any>): Period => {
  return {
    from: new Date(r.from),
    to: new Date(r.to),
  };
};

export default serialize;
