import React from 'react';
import {Input} from "antd";

interface TextInputProps {
  className?: string | undefined;
  value?: string | undefined;
  onChange?: ((value: string) => void) | undefined;
  onKeyDown?: ((value: any) => void) | undefined;
  disabled?:boolean|undefined;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  const onChange = (e: any) => props.onChange != null && props.onChange(e.target.value);
  const onKeyDown = (e:any) => props.onKeyDown!= null && props.onKeyDown(e);
  return <Input className={props.className} value={props.value} onChange={onChange} onKeyDown={onKeyDown} disabled={props.disabled}/>;
};

export default TextInput;
export type {TextInputProps};
