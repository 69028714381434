import useAuth from "./auth";
import {apiAddr} from "../config";
import VoteCategory from "../data-types/vote-category";

const useUpdateVoteCategory = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<VoteCategory>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/vote-categories/${id}`,
      data: payload,
    });
  };
};

export default useUpdateVoteCategory;
