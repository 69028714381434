import WithId from "../with-id";
import CollectionMetadata from "../../data-types/collection-metadata";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useHandleAppError from "../../services/handle-app-error";
import * as R from 'ramda';
import {strict as assert} from 'assert';
import FieldMetadata from "../../data-types/field-metadata";

interface LoadingLogic<C extends WithId> {
  status: 'LOADING';
}

interface LoadedLogic<C extends WithId> {
  status: 'LOADED';
  document: C;
  onSubmit: (formData: Record<string, any>) => void;
}

interface FailedLogic<C extends WithId> {
  status: 'FAILED';
}

type Logic<C extends WithId> = LoadingLogic<C> | LoadedLogic<C> | FailedLogic<C>;

interface LoadingState<C extends WithId> {
  status: 'LOADING';
}

interface LoadedState<C extends WithId> {
  status: 'LOADED';
  document: C;
}

interface FailedState<C extends WithId> {
  status: 'FAILED';
}

type State<C extends WithId> = LoadingState<C> | LoadedState<C> | FailedState<C>;

const buildLogic = <C extends WithId>(collectionMetadata: CollectionMetadata<C>) => {
  return (): Logic<C> => {
    const [state, setState] = useState<State<C>>({status: 'LOADING'});

    const urlParams = useParams();
    const readC = collectionMetadata.useRead();
    const updateC = collectionMetadata.useUpdate();
    const handleAppError = useHandleAppError();
    const documentId = urlParams.id!;
    const toOverallPage = collectionMetadata.useToOverallPage();

    const tryInit = async () => {
      const document = await readC(documentId);

      setState({
        status: 'LOADED',
        document,
      });
    };

    const init = async () => {
      try {
        await tryInit();
      } catch (error) {
        setState({status: 'FAILED'});
        await handleAppError(error);
      }
    };

    useEffect(() => { init(); }, []);

    if (state.status === 'LOADING') {
      return {status: 'LOADING'};
    }

    if (state.status === 'FAILED') {
      return {status: 'FAILED'};
    }

    const onSubmit = async (formData: Record<string, any>) => {
      try {
        const isCustomizable = (field: FieldMetadata<C>) => field.customizable;

        const customizableKeys = R.pipe(
          R.filter(isCustomizable),
          R.map(R.prop('name')) as any
        )(collectionMetadata.fields as any) as string[];

        const updatePayload = R.pick(customizableKeys, formData) as Partial<C>;

        await updateC(documentId, updatePayload);

        toOverallPage();
      } catch (error) {
        await handleAppError(error);
      }
    };

    if (state.status === 'LOADED') {
      return {
        status: 'LOADED',
        document: state.document,
        onSubmit,
      };
    }

    assert.fail();
  };
};

export default buildLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};
