import React from "react";
import buildCreatePage from "../../utils/build-create-page";
import TourCategory from "../../data-types/tour-category";
import tourCategoryCollectionMetadata from "../../data-metadata/tour-category";

const CreateTourCategoryPage = buildCreatePage<TourCategory>({
	title: "펜업투어 카테고리 생성",
	collectionMetadata: tourCategoryCollectionMetadata,
});

export default CreateTourCategoryPage;
