import buildCreatePage from "../../utils/build-create-page";
import idolCollectionMetadata from "../../data-metadata/idol";
import Idol from "../../data-types/idol";

const CreateIdolPage = buildCreatePage<Idol>({
  title: '아이돌 생성',
  collectionMetadata: idolCollectionMetadata,
});

export default CreateIdolPage;
