import React from 'react';
import CollectionMetadata from "../../data-types/collection-metadata";
import {Table, TablePaginationConfig, TableProps} from "antd";
import FieldMetadata from "../../data-types/field-metadata";
import useLogic, {LoadedLogic} from "./use-logic";
import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";

interface CollectionRefSelectProps {
  className?: string | undefined;
  collectionMetadata: CollectionMetadata<any>;
  onPress: (value: any) => void;
}

const attachIdAsKey = <T extends {id: string}>(document: T): T & {key: string} => {
  return {...document, key: document.id};
};

const fieldToColumn = <T,>(field: FieldMetadata<T>) => ({
  title: field.label,
  dataIndex: field.name,
  render: (value: any) => field.render({value}),
});

const changePageIndexByTablePaginationConfig =
  (logic: LoadedLogic) =>
    (config: TablePaginationConfig) => {
      const current = config.current ?? 1;
      logic.setPageIndex(current - 1);
    };

const callPropsOnPressOnRowEvent =
  (props: CollectionRefSelectProps, logic: LoadedLogic) =>
    (_: any, index: number | undefined) => {
      if (index == null) {
        return {};
      }

      return {
        onClick: () => props.onPress(logic.page.data[index]),
        style: {cursor: 'pointer'},
      };
    };

const tableProps =
  (props: CollectionRefSelectProps, logic: LoadedLogic) => ({
    dataSource: logic.page.data.map(attachIdAsKey),
    columns: props.collectionMetadata.fields.map(fieldToColumn),
    pagination: {
      defaultPageSize: logic.limit,
      defaultCurrent: logic.pageIndex + 1,
      total: logic.page.totalCount,
    },
    onChange: changePageIndexByTablePaginationConfig(logic),
    onRow: callPropsOnPressOnRowEvent(props, logic),
  }) as TableProps<any>;


const CollectionRefSelect: React.FC<CollectionRefSelectProps> = (props) => {
  const logic = useLogic(props);

  return renderWhenLoadingOrFailed(
    logic,
    loadedLogic => <Table className={props.className} {...tableProps(props, loadedLogic)}/>
  );
};

export default CollectionRefSelect;
export type {CollectionRefSelectProps};
