import useAuth from "./auth";
import {apiAddr} from "../config";

const useReadRewardingTicketCount = () => {
  const auth = useAuth();

  return async (): Promise<number> => {
    const axiosResult = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/admin/v1/app/rewarding-ticket-count`,
    });

    return axiosResult.data;
  };
};

export default useReadRewardingTicketCount;
