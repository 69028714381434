import { Typography } from 'antd';
import Layout from '../../components/layout';
import QnaList from '../../components/qna/qna-list';

const QnasPage: React.FC = () => {
	return (
		<Layout>
			<Typography.Title>1대1 문의 관리</Typography.Title>
			<QnaList />
		</Layout>
	);
};

export default QnasPage;
