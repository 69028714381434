import FieldMetadata from "../data-types/field-metadata";
import {Form, Input} from "antd";

const buildTextFieldRenderEditableFormItem = <T,>(that: FieldMetadata<T>) => () => {
  return (
    <Form.Item
      key={that.name}
      label={that.label}
      name={that.name}
    >
      <Input/>
    </Form.Item>
  );
};

export default buildTextFieldRenderEditableFormItem;
