import React from 'react';
import TournamentVoteChoice from "../data-types/tournament-vote-choice";
import CommonDemandingVoteChoicesInput from "./common-demanding-vote-choices-input";

interface TournamentVoteChoicesInputProps {
  className?: string | undefined;
  value?: Partial<TournamentVoteChoice>[];
  onChange?: ((value: Partial<TournamentVoteChoice>[]) => void) | undefined;
}

const TournamentVoteChoicesInput: React.FC<TournamentVoteChoicesInputProps> = (props) => {
  return <CommonDemandingVoteChoicesInput
    {...props}
    requestUploadInfoPath={'upload-tournament-vote-choice-image'}
  />;
};

export default TournamentVoteChoicesInput;
export type {TournamentVoteChoicesInputProps};
