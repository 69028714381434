import React, {useEffect, useState} from 'react';
import Vote from "../../data-types/vote";
import Field from "../../ant-form-inputs/field";
import MultilingualStringInput from "../../ant-form-inputs/multilingual-string-input";
import IdolVote from "../../data-types/idol-vote";
import {strict as assert} from 'assert';
import ImageInput from "../../ant-form-inputs/image-input";
import PeriodInput from "../../ant-form-inputs/period-input";
import IdolVoteCandidatesInput from "../../ant-form-inputs/idol-vote-candidates-input";
import {Button} from "antd";
import useHandleAppError from "../../services/handle-app-error";
import useToVotesPage from "../../services/to-votes-page";
import useUpdateIdolVote from "../../services/update-idol-vote";
import IdolVoteCandidate from "../../data-types/idol-vote-candidate";
import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";
import useReadIdolVoteCandidates from "../../services/read-idol-vote-candidates";

interface UpdateVoteIdolProps {
  className?: string | undefined;
  vote: Vote;
}

const toIdolVoteForm = (vote_: Vote): Partial<IdolVote> => {
  assert(vote_.voteKind === 'IDOL');

  const vote: IdolVote = vote_;

  return {
    question: vote.question,
    modification: vote.modification,
    backgroundImage: vote.backgroundImage,
    tabName: vote.tabName,
    description: vote.description,
    period: vote.period,
    candidates: [],
  };
};

interface LoadingCandidatesState {
  status: 'LOADING';
}

interface LoadedCandidatesState {
  status: 'LOADED';
  value: IdolVoteCandidate[];
}

interface FailedCandidatesState {
  status: 'FAILED';
}

type CandidatesState = LoadingCandidatesState | LoadedCandidatesState | FailedCandidatesState;

const useCandidates = (voteId: string): CandidatesState => {
  const [state, setState] = useState<CandidatesState>({status: 'LOADING'});
  const readIdolVoteCandidates = useReadIdolVoteCandidates();
  const handleAppError = useHandleAppError();

  const tryInit = async () => {
    const candidates = await readIdolVoteCandidates(voteId);

    setState({status: 'LOADED', value: candidates});
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, []);

  return state;
};

const UpdateVoteIdol: React.FC<UpdateVoteIdolProps> = (props) => {
  const candidates = useCandidates(props.vote.id);
  const [form, setForm] = useState<Partial<IdolVote>>(toIdolVoteForm(props.vote));
  const handleAppError = useHandleAppError();
  const updateIdolVote = useUpdateIdolVote();
  const toVotesPage = useToVotesPage();

  return renderWhenLoadingOrFailed(candidates, loadedCandidates => {
    const changeField = (fieldName: string) => (newValue: any) => {
      setForm({...form, [fieldName]: newValue});
    };

    const changeCandidatesField = (candidates: Partial<IdolVoteCandidate>[]) => {
      const dict: Record<string, Partial<IdolVoteCandidate>> = {};
      for (let i = 0; i < candidates.length; ++i) {
        const candidate = candidates[i];
        dict[candidate.idol!] = candidate;
      }
      for (let i = 0; i < loadedCandidates.value.length; ++i) {
        delete dict[loadedCandidates.value[i].idol];
      }
      setForm({...form, candidates: Object.values(dict) as IdolVoteCandidate[]});
    };

    const tryUpdate = async () => {
      await updateIdolVote(props.vote.id, form);
      toVotesPage();
    };

    const update = async () => {
      try {
        await tryUpdate();
      } catch (error) {
        await handleAppError(error);
      }
    };

    const onFieldChange = changeField;
    const onCandidatesFieldChange = changeCandidatesField;
    const onUpdateClick = update;

    return (
      <div className={props.className}>
        <Field title={'질문'}>
          <MultilingualStringInput
            value={form.question}
            onChange={onFieldChange('question')}
          />
        </Field>

        <Field title={'수식어'}>
          <MultilingualStringInput
            value={form.modification}
            onChange={onFieldChange('modification')}
          />
        </Field>

        <Field title={'배경사진'}>
          <ImageInput
            value={form.backgroundImage}
            onChange={onFieldChange('backgroundImage')}
            requestUploadInfoPath={'upload-idol-vote-background-image'}
          />
        </Field>

        <Field title={'탭 이름'}>
          <MultilingualStringInput
            value={form.tabName}
            onChange={onFieldChange('tabName')}
          />
        </Field>

        <Field title={'설명'}>
          <MultilingualStringInput
            value={form.description}
            onChange={onFieldChange('description')}
          />
        </Field>

        <Field title={'기간'}>
          <PeriodInput
            value={form.period}
            onChange={onFieldChange('period')}
          />
        </Field>

        <Field title={'지원자'}>
          <IdolVoteCandidatesInput
            value={[...loadedCandidates.value, ...form.candidates!]}
            onChange={onCandidatesFieldChange}
          />
        </Field>

        <Button onClick={onUpdateClick}>수정</Button>
      </div>
    );
  });
};

export default UpdateVoteIdol;
export type {UpdateVoteIdolProps};
