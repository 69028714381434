import useAuth from "./auth";
import TournamentVote from "../data-types/tournament-vote";
import {apiAddr} from "../config";
import internalFileToStoreKeyWhenUpdate from "../utils/internal-file-to-store-key-when-update";

const useUpdateTournamentVote = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<TournamentVote>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/votes/${id}`,
      data: {
        voteKind: 'TOURNAMENT',
        title: payload.title,
        description: payload.description,
        period: payload.period,
        thumbnail: internalFileToStoreKeyWhenUpdate(payload.thumbnail),
        category: payload.category,
      },
    });
  };
};

export default useUpdateTournamentVote;
