import { AxiosResponse } from "axios";
import { apiAddr } from "../config";
import { ResultVoteType } from "../data-types/result-vote";
import { Auth } from "../utils/auth";

class ResultVoteAPI {
	constructor(private auth: Auth) {}

	async create(data: Partial<ResultVoteType>) {
		this.auth.checkIsLoggedIn();
		const axiosResult = await this.auth.callAxios({
			method: "POST",
			url: `${apiAddr}/admin/v1/fan-tours`,
			data,
		});

		const result = await axiosResult.data;
		return result;
	}

	async get(id: string) {
		this.auth.checkIsLoggedIn();
		let axiosResult: AxiosResponse;
		try {
			axiosResult = await this.auth.callAxios({
				method: "GET",
				url: `${apiAddr}/admin/v1/fan-tours/${id}`,
			});
			console.log(axiosResult);

			return axiosResult.data;
		} catch (e) {
			if (axiosResult!.status === 404) {
				return {};
			} else {
				throw e;
			}
		}
	}

	async getList() {
		this.auth.checkIsLoggedIn();
		const axiosResult = await this.auth.callAxios({
			method: "GET",
			url: `${apiAddr}/admin/v1/fan-tours?limit=100000&skip=0`,
		});

		const result = await axiosResult.data;
		return result;
	}

	async patch(id: string, data: Partial<ResultVoteType>) {
		this.auth.checkIsLoggedIn();
		const axiosResult = await this.auth.callAxios({
			method: "PATCH",
			url: `${apiAddr}/admin/v1/fan-tours/${id}`,
			data,
		});

		const result = await axiosResult.data;
		return result;
	}

	async delete(id: string) {
		this.auth.checkIsLoggedIn();
		const axiosResult = await this.auth.callAxios({
			method: "DELETE",
			url: `${apiAddr}/admin/v1/fan-tours/${id}`,
		});
	}
}

export default ResultVoteAPI;
