import FieldMetadata from "../data-types/field-metadata";
import {Typography} from "antd";

const buildDateFieldRender = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['render'] => {
  return (params) => {
    const value = params.value as unknown as Date;

    return <Typography.Text key={that.name}>{value.toLocaleString()}</Typography.Text>;
  };
};

export default buildDateFieldRender;
