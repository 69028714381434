import React from 'react';
import Layout from "../../components/layout";
import {Button, InputNumber} from "antd";
import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";
import useLogic from "./use-logic";

const UpdateAppMaxIdolVoteCountPerDayPage: React.FC = () => {
  const logic = useLogic();

  return (
    <Layout title={'하루 아이돌 투표 제한 관리'}>
      {renderWhenLoadingOrFailed(logic, loadedLogic => {
        return <>
          <InputNumber
            min={1}
            max={1000}
            value={loadedLogic.maxIdolVoteCountPerDay}
            onChange={loadedLogic.setMaxIdolVoteCountPerDay}
          />
          <Button onClick={loadedLogic.submit}>
            저장
          </Button>
        </>;
      })}
    </Layout>
  );
};

export default UpdateAppMaxIdolVoteCountPerDayPage;
