import useAuth from "./auth";
import {apiAddr} from "../config";
import TourCategory from "../data-types/tour-category";

const useUpdateTourCategory = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<TourCategory>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/tour-categories/${id}`,
      data: payload,
    });
  };
};

export default useUpdateTourCategory;
