import VoteCategory from "../data-types/vote-category";
import serializeVoteCategory from '../from-server-serializers/vote-category';
import useAuth from "./auth";
import {apiAddr} from "../config";

const useCreateVoteCategory = () => {
  const auth = useAuth();

  return async (payload: Partial<VoteCategory>): Promise<VoteCategory> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/vote-categories`,
      data: payload,
    });

    return serializeVoteCategory(axiosResult.data);
  };
};

export default useCreateVoteCategory;
