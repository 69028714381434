import FieldMetadata from "../data-types/field-metadata";
import Announcement from "../data-types/announcement";
import {Typography} from "antd";
import {strict as assert} from 'assert';

const buildAnnouncementKindFieldRender = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['render'] => {
  return (params) => {
    const value = params.value as unknown as Announcement['kind'];

    const getKindName = () => {
      switch (value) {
        case 'INFO': return '정보';
        case 'EVENT': return '이벤트';
        default: assert.fail();
      }
    };

    return <Typography.Text key={that.name}>{getKindName()}</Typography.Text>
  };
};

export default buildAnnouncementKindFieldRender;
