import useAuth from "./auth";
import Banner from "../data-types/banner";
import {apiAddr} from "../config";
import internalFileToStoreKeyWhenUpdate from "../utils/internal-file-to-store-key-when-update";

const useUpdateAppMainBanners = () => {
  const auth = useAuth();

  return async (banners: Banner[]) => {
    await auth.callAxios({
      method: 'put',
      url: `${apiAddr}/admin/v1/app/main-banners`,
      data: banners.map((banner: Banner) => {
        return {
          id: banner.id,
          title: banner.title,
          image: internalFileToStoreKeyWhenUpdate(banner.image),
          actionButtonText: banner.actionButtonText,
          link: banner.link,
        };
      }),
    });
  };
};

export default useUpdateAppMainBanners;
