import React, { useState } from "react";
import { Button, Form, Input } from "antd";

import { useNavigate } from "react-router-dom";
import { QnaDetail } from "../../../data-types/qna";
import QnaApi from "../../../services/qnaAPI";



const QnaAnswerForm: React.FC<{ QnaData: QnaDetail; api: QnaApi }> = ({ QnaData, api }) => {
	const navigator = useNavigate();
	const [answer, setAnswer] = useState(QnaData.answer ?? "");

	const validateMessages = {
		required: "${label}은 필수 값 입니다.",
	};
	const onFinish = async (values: any) => {
		const result = await api.answerQna(QnaData.id, values.answer);
		if (result) {
			window.alert("답변이 완료 되었습니다.");
			navigator("/qna");
		} else {
			window.alert("답변 중 오류가 발생 했습니다.");
		}
	};
	const onDelete = async () => {
		const result = await api.deleteQna(QnaData.id);

		if (result) {
			window.alert("삭제 되었습니다.");
			navigator("/qna");
		} else {
			window.alert("삭제 중 오류가 발생 했습니다.");
		}
	};

	const { TextArea } = Input;

	return (
		<>
			<Form validateMessages={validateMessages} onFinish={onFinish}>

				<Form.Item name={"answer"} label="답변" rules={[{ required: true }]}>
					<TextArea id="answer" autoSize={{ minRows: 10 }} defaultValue={answer} onChange={(e) => setAnswer(e.target.value)} />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="button" onClick={() => navigator("/qna")}>
						목록 보기
					</Button>

					<Button type="primary" htmlType="submit" style={{ float: "right" }}>
						답변하기
					</Button>
					<Button type="primary" onClick={onDelete} htmlType="button" style={{ float: "right", marginRight: "1rem" }}>
						삭제하기
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default QnaAnswerForm;
