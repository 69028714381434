import { Typography } from "antd";
import Layout from "../../components/layout";
import ResultVoteList from "../../components/result-vote/result-vote-list";

const ResultVotePage: React.FC = () => {
	return (
		<Layout>
			<Typography.Title>펜업투어 관리</Typography.Title>
			<ResultVoteList />
		</Layout>
	);
};

export default ResultVotePage;
