import React, {useEffect} from 'react';
import useAuth from "../services/auth";
import useToLoginPage from "../services/to-login-page";
import useHandleAppError from "../services/handle-app-error";

const requireLogin = <Props,>(Comp: React.FC<Props>): React.FC<Props> => {
  const NewComp: React.FC<Props> = (props) => {
    const auth = useAuth();
    const toLoginPage = useToLoginPage();
    const handleAppError = useHandleAppError();

    const toLoginIfIsNotLoggedIn = async () => {
      try {
        if (auth.isLoggedIn) {
          return;
        }

        toLoginPage();
      } catch (error) {
        await handleAppError(error);
      }
    };

    useEffect(() => { toLoginIfIsNotLoggedIn(); }, []);

    try {
      if (!auth.isLoggedIn) {
        return null;
      }
    } catch (error) {
      handleAppError(error);
    }

    return <Comp {...props}/>
  };

  const getDisplayName = (): string => Comp.displayName ?? Comp.name;

  NewComp.displayName = `RequireLogin_${getDisplayName()}`;

  return NewComp;
};

export default requireLogin;
