import React, { useState } from "react";
import Field from "../../ant-form-inputs/field";
import { Attraction } from "../../data-types/attraction";
import MultilingualStringInput from "../../ant-form-inputs/multilingual-string-input";
import "leaflet/dist/leaflet.css";
import TextInput from "../../ant-form-inputs/text-input";
import { Button, Radio, Select, Table, Typography } from "antd";
import {
  OpenApiItem,
  readAttractionListFromGovApi,
} from "../../services/attraction/read-attraction";
import TextAreaInput from "../../ant-form-inputs/text-area-input";
import ImageInput from "../../ant-form-inputs/image-input";
import useCreateAttraction from "../../services/attraction/create-attraction";
import {
  useUpdateAttraction,
  useUpdateAttractionStatus,
} from "../../services/attraction/update-attraction";
import { NavigateFunction, useNavigate } from "react-router-dom";

const ClassType1 = [
  { label: "Artist", value: "Artist" },
  { label: "Content", value: "CONTENT" },
];
const ClassType2 = [
  { label: "Drama", value: "Drama" },
  { label: "K-Pop", value: "K-Pop" },
  { label: "K-Culture", value: "K-Culture" },
  { label: "Movie", value: "Movie" },
  { label: "Web Comic", value: "Web Comic" },
  { label: "K-Variety", value: "K-Variety" },
];
const AppState = [
  { label: "요청", value: "01" },
  { label: "승인", value: "02" },
  { label: "반려", value: "99" },
];
/**
 * 관광지 인풋 프로퍼티
 */
interface AttractionInputProps {
  className?: string | undefined;
  value: Partial<Attraction> | undefined;
  isCreate: boolean | undefined;
  onChange: ((value: Partial<Attraction>) => void) | undefined;
}

let _setApiData: React.Dispatch<React.SetStateAction<OpenApiItem[]>>;
let _props: AttractionInputProps;
let _attraction: Partial<Attraction>;
let _useCreateAttraction: (payload: Partial<Attraction>) => Promise<Attraction>;
let _useModifyAttraction: (
  id: string,
  payload: Partial<Attraction>
) => Promise<void>;
let _useModifyAttractionState: (id: string, statusCd: string) => Promise<void>;
let _toAttractionPage = () => {};
let _navigate: NavigateFunction;
/**
 * 등록
 */
const onCreateAttraction = async () => {
  try {
    _useCreateAttraction && (await _useCreateAttraction(_attraction));
    _toAttractionPage();
  } catch (error) {
    console.error(error);
    alert("오류가 발생하였습니다");
  }
};
/**
 * 수정
 */
const onModifyAttraction = async () => {
  try {
    _useModifyAttraction &&
      (await _useModifyAttraction(_attraction.id!, _attraction));
    _toAttractionPage();
  } catch (error) {
    console.error(error);
    alert("오류가 발생하였습니다");
  }
};
/**
 * 상태변경
 * @param statusCd
 */
const onModifyAttractionState = async (statusCd: string) => {
  try {
    _useModifyAttractionState &&
      (await _useModifyAttractionState(_attraction.id!, statusCd));
    _toAttractionPage();
  } catch (error) {
    console.error(error);
    alert("오류가 발생하였습니다");
  }
};
/**
 * 오픈api 관광지 검색
 * @param keyword
 */
const searchAttractionList = (keyword: string) => {
  readAttractionListFromGovApi(keyword).then((result) => {
    if (result.totalCount > 0) {
      _setApiData(result.items!.item);
    } else {
      _setApiData([]);
    }
  });
};

/**
 * 관광지 검색 결과 테이블
 * @param apiData
 */
const openApiTable: React.FC<OpenApiItem[]> = (apiData: OpenApiItem[]) => {
  const columns = [
    {
      title: "명칭",
      dataIndex: "title",
      render: (value: any) => {
        return <Typography.Text>{value}</Typography.Text>;
      },
    },
    {
      title: "주소",
      dataIndex: "addr1",
      render: (value: any) => {
        return <Typography.Text>{value}</Typography.Text>;
      },
    },
    {
      title: "상세주소",
      dataIndex: "addr2",
      render: (value: any) => {
        return <Typography.Text>{value}</Typography.Text>;
      },
    },
    {
      title: "좌표x",
      dataIndex: "mapx",
      render: (value: any) => {
        return <Typography.Text>{value}</Typography.Text>;
      },
    },
    {
      title: "좌표y",
      dataIndex: "mapy",
      render: (value: any) => {
        return <Typography.Text>{value}</Typography.Text>;
      },
    },
  ];
  return (
    <div>
      <Field title={"관광지 검색"}>
        <TextInput
          onKeyDown={(e) => {
            if (e.key === "Enter" && (e.target.value || "").length > 0) {
              searchAttractionList(e.target.value);
            }
          }}
        ></TextInput>
      </Field>
      <Table<OpenApiItem>
        dataSource={apiData}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              _attraction.name = {
                en: _attraction?.name?.en ?? "",
                kr: record.title,
                zh: _attraction?.name?.zh ?? "",
              };
              _attraction.address = {
                en: _attraction?.address?.en ?? "",
                kr: `${record.addr1} ${record.addr2}`,
                zh: _attraction?.address?.zh ?? "",
              };
              _attraction.latitude = Number(record.mapy);
              _attraction.longitude = Number(record.mapx);
              _attraction.location = [
                _attraction.latitude,
                _attraction.longitude,
              ];
              _attraction.tel = record.tel;
              onFieldChange("name")({
                en: _attraction?.name?.en ?? "",
                kr: record.title,
                zh: _attraction?.name?.zh ?? "",
              });
            }, // click row
          };
        }}
        style={{ marginTop: "25px" }}
      />
    </div>
  );
};
/**
 * 필드 변경이벤트
 * @param fieldName
 */
const onFieldChange =
  (fieldName: string) =>
  <T,>(newValue: T) => {
    _props.onChange != null &&
      _props.onChange({
        ..._attraction,
        [fieldName]: newValue,
      });
  };
/**
 * 생성시 버튼
 */
const createButtonGroup: React.FC<any> = () => {
  return (
    <div style={{ marginTop: "35px" }}>
      <Button onClick={onCreateAttraction} type={"primary"}>
        생성
      </Button>
    </div>
  );
};
/**
 * 수정시 버튼
 */
const modifyButtonGroup: React.FC<any> = () => {
  return (
    <div style={{ marginTop: "35px" }}>
      <Button
        onClick={onModifyAttraction}
        type={"primary"}
        style={{ marginRight: "10px" }}
      >
        수정
      </Button>
      <Button
        onClick={() => {
          onModifyAttractionState("02");
        }}
        type={"primary"}
        style={{ marginRight: "10px" }}
      >
        승인
      </Button>
      <Button
        onClick={() => {
          onModifyAttractionState("99");
        }}
      >
        반려
      </Button>
    </div>
  );
};
/**
 * 관공지 입력 인풋
 * @param props
 * @constructor
 */
const AttractionInput: React.FC<AttractionInputProps> = (props) => {
  const [apiData, setApiData] = useState<OpenApiItem[]>([]);

  _setApiData = setApiData;
  _props = props;
  _attraction = props.value ?? {};

  _useCreateAttraction = useCreateAttraction();
  _useModifyAttraction = useUpdateAttraction();
  _useModifyAttractionState = useUpdateAttractionStatus();
  _navigate = useNavigate();
  _toAttractionPage = () => {
    _navigate("/attraction");
  };

  const onFieldChange =
    (fieldName: string) =>
    <T,>(newValue: T) => {
      _props.onChange != null &&
        _props.onChange({
          ..._attraction,
          [fieldName]: newValue,
        });
    };
  console.log(_attraction);
  return (
    <div className={props.className}>
      {props.isCreate ? openApiTable(apiData) : null}
      <Field title={"상태"}>
        <Select
          disabled={true}
          defaultValue={"01"}
          style={{ width: "120px" }}
          options={AppState}
          value={_attraction.statusCd}
          onChange={(e) => {
            console.log(e);
          }}
        />
      </Field>
      <Field title={"관광지 명칭"}>
        <MultilingualStringInput
          disabled={_attraction.statusCd === "99"}
          className={"input-md"}
          value={_attraction.name}
          onChange={onFieldChange("name")}
        />
      </Field>
      <Field title={"주소"}>
        <MultilingualStringInput
          disabled={_attraction.statusCd === "99"}
          value={_attraction.address}
          onChange={onFieldChange("address")}
        />
      </Field>
      <Field title={"좌표"}>
        <TextInput
          className={"input-sm"}
          disabled={_attraction.statusCd === "99"}
          value={_attraction.latitude?.toString()}
          onChange={onFieldChange("latitude")}
        />
        <TextInput
          className={"input-sm"}
          disabled={_attraction.statusCd === "99"}
          value={_attraction.longitude?.toString()}
          onChange={onFieldChange("longitude")}
        />
      </Field>
      <Field title={"전화"}>
        <TextInput
          className={"input-sm"}
          disabled={_attraction.statusCd === "99"}
          value={_attraction.tel}
          onChange={onFieldChange("tel")}
        />
      </Field>
      <Field title={"영업시간"}>
        <TextAreaInput
          className={"input-md"}
          disabled={_attraction.statusCd === "99"}
          value={_attraction.time}
          onChange={onFieldChange("time")}
        />
      </Field>
      <Field title={"메모"}>
        <MultilingualStringInput
          value={_attraction.memo}
          disabled={_attraction.statusCd === "99"}
          onChange={onFieldChange("memo")}
        />
      </Field>
      <Field title={"여행키워드"}>
        <Radio.Group
          options={ClassType1}
          onChange={(e) => {
            onFieldChange("class1")(e.target.value);
          }}
          value={_attraction.class1}
          optionType="button"
          disabled={_attraction.statusCd === "99"}
        />
      </Field>
      <Field title={"중 카테고리"}>
        <Radio.Group
          options={ClassType2}
          onChange={(e) => {
            onFieldChange("class2")(e.target.value);
          }}
          value={_attraction.class2}
          optionType="button"
          disabled={_attraction.statusCd === "99"}
        />
      </Field>
      <Field title={"하 카테고리"}>
        <MultilingualStringInput
          className={"input-sm"}
          value={_attraction.class3}
          onChange={onFieldChange("class3")}
          disabled={_attraction.statusCd === "99"}
        />
      </Field>
      <Field title={"하 카테고리 이미지"}>
        <ImageInput
          className={"class-icon"}
          value={_attraction.class3Image}
          onChange={onFieldChange("class3Image")}
          disabled={_attraction.statusCd === "99"}
          isMultipleUpload={false}
          requestUploadInfoPath={"upload-attraction-image"}
        />
      </Field>
      <Field title={"관광지 사진"}>
        <ImageInput
          className={"class-picture"}
          multipleValue={_attraction.images}
          onChangeMultiple={onFieldChange("images")}
          isMultipleUpload={true}
          disabled={_attraction.statusCd === "99"}
          requestUploadInfoPath={"upload-attraction-image"}
        />
      </Field>
      {props.isCreate
        ? createButtonGroup(props)
        : _attraction.statusCd !== "99"
        ? modifyButtonGroup(props)
        : null}
    </div>
  );
};
export default AttractionInput;
export type { AttractionInputProps };
