import React, { useState } from "react";
import Vote from "../../data-types/vote";
import useCreateTournamentVote from "../../services/create-tournament-vote";
import Field from "../../ant-form-inputs/field";
import MultilingualStringInput from "../../ant-form-inputs/multilingual-string-input";
import PeriodInput from "../../ant-form-inputs/period-input";
import ImageInput from "../../ant-form-inputs/image-input";
import RefInput from "../../ant-form-inputs/ref-input";
import TournamentVoteChoicesInput from "../../ant-form-inputs/tournament-vote-choices-input";
import { Button } from "antd";
import TournamentVote from "../../data-types/tournament-vote";
import useToVotesPage from "../../services/to-votes-page";
import styled from "styled-components";
import { ReactComponent as ClockIcon } from "./svg/access_time.svg";
import { ReactComponent as VsIcon } from "./svg/vs.svg";

interface TournamentVoteInputProps {
  className?: string | undefined;
  value: Partial<Vote> | undefined;
  onChange: ((value: Partial<Vote>) => void) | undefined;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  value: Partial<TournamentVote> | undefined;
}
const HeaderDiv = styled.div`
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 18px 0px 0px;
  background-color: white;
`;
const HeaderSpan = styled.div`
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  background: rgb(149, 197, 236);
  border-radius: 8px;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  width: 327px;
  height: 40px;
  letter-spacing: -0.24px;
  color: rgb(255, 255, 255);
`;

const TitleDiv = styled.div`
  padding: 24px 24px 12px;
  background-color: white;
`;

const TitleSpanTop = styled.span`
  display: block;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.24px;
  padding: 3px;
  color: rgb(5, 19, 49);
`;

const TitleSpanBottom = styled.span`
  display: block;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.24px;
  color: rgb(108, 118, 128);
`;

const NextDiv = styled.div`
  display: flex;
  align-self: center;
  text-align: center;
  width: 100%;
  border-radius: 0px;
`;

const NextInnerDiv = styled.div`
  height: 40px;
  padding: 5px 27px 0px;
  background-color: rgb(149, 197, 236);
  width: 100%;
  align-self: center;
  text-align: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
`;

const NextInnerSpan = styled.span`
  font-family: BalooBhaijaan;
  font-size: 24px;
  font-weight: bold;
  color: rgb(255, 255, 255);
`;

const TournamentDiv = styled.div`
  padding: 5px 16px 18px;
  background-color: white;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
`;
const TournamentTitleDiv = styled.div`
  padding: 18px 0px;
  background-color: white;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
`;
const TournamentTitleLeftSpan = styled.span`
  color: rgb(149, 197, 236);
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 23px;
`;
const TournamentTitleCenterDiv = styled.div`
  margin-left: -20px;
  color: rgb(41, 46, 51);
  font-weight: bold;
`;
const TournamentTitleRightSpan = styled.span`
  color: rgb(149, 197, 236);
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 23px;
`;
const TournamentPhotoDiv = styled.div`
  display: flex;
  position: relative;
`;
const TournamentPhotoVsDiv = styled.div`
  position: absolute;
  margin-top: 60px;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  display: flex;
`;
const TournamentPhotoLeftDiv = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  cursor: pointer;
  text-align: center;
  border: 1px solid rgb(218, 224, 230);
`;
const TournamentPhotoCenterDiv = styled.div`
  width: 8px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
`;
const TournamentPhotoRightDiv = styled.div`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  cursor: pointer;
  text-align: center;
  border: 1px solid rgb(218, 224, 230);
`;
const TournamentPhotoInnerDiv = styled.div`
  opacity: 1;
  background-image: url(none);
  background-position: center 50px;
  background-repeat: no-repeat;
`;
const TournamentPhotoInnerTopDiv = styled.div`
  display: block;
`;
const TournamentPhotoInnerTopImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow-clip-margin: content-box;
  overflow: clip;
`;
const TournamentPhotoInnerCenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  min-height: 158px;
  -webkit-box-pack: center;
  justify-content: center;
  border-top: 1px solid rgb(218, 224, 230);
  padding: 16px;
`;

const TournamentPhotoInnerCenterTopSpan = styled.span`
  display: block;
  text-align: center;
  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: rgb(5, 19, 49);
`;
const TournamentPhotoInnerCenterBottomSpan = styled.span`
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  color: rgb(108, 118, 128);
  margin-top: 4px;
`;
const TournamentPhotoInnerBottomSpan = styled.span`
  display: block;
  height: 54px;
  line-height: 54px;
  text-align: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  color: rgb(149, 197, 236);
  font-size: 14px;
  cursor: pointer;
  background-color: white;
  border-top: 1px solid rgb(218, 224, 230) !important;
`;

const Modal: React.FC<ModalProps> = (props) => {
  const modalStyle: React.CSSProperties = {
    display: props.isOpen ? "flex" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
  };
  const modalContentStyle: React.CSSProperties = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "550px",
    height: "62%",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    maxWidth: "550px",
    margin: "0 auto",
    position: "relative",
  };
  const closeStyle: React.CSSProperties = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    fontSize: "24px",
    color: "#777",
    zIndex: 9999,
  };
  const svgIcon: React.CSSProperties = {
    width: "23",
    height: "23",
    fill: "none",
  };
  let category;
  if (props.value?.category !== undefined) {
    category = props.value?.category;
  }
  let imgUrl1, imgUrl2;
  let title1, title2;
  let content1, content2;

  if (category === "624c334f9e9cbf416370556b") {
    category = "볼거리";
  }
  if (category === "624c33649e9cbf416370556e") {
    category = "먹거리";
  }
  if (category === "624c338c9e9cbf4163705571") {
    category = "놀거리";
  }

  //console.log(" Category: " + category);
  //console.log("test Value Id : " + props.value?.id);
  //console.log("test Value voteTitle : " + props.value?.voteTitle?.kr);
  //console.log("test Value voteKind : " + props.value?.voteKind);
  //console.log("test Value votePeriod : " + props.value?.votePeriod?.from);
  //console.log("test Value votePeriod : " + props.value?.votePeriod?.to);
  //console.log("test Value period : " + props.value?.period?.from.toLocaleDateString());
  //console.log("test Value period : " + props.value?.period?.to);
  //console.log("test Value description : " + props.value?.description?.kr);
  //console.log("test Value thumbnail : " + props.value?.thumbnail?.url);
  //console.log("test Value category : " + props.value?.category);
  //console.log("test Value choices : " + props.value?.choices);
  //console.log("test Value choices length : " + props.value?.choices?.length);
  let random1: number, random2: number;
  let length = props.value?.choices?.length;
  if (length !== undefined) {
    random1 = Math.floor(Math.random() * length);
    random2 = Math.floor(Math.random() * length);
    if (random1 === random2) {
      if (random1 === 0) {
        random2 = length - 1;
        console.log("동일 if");
      } else {
        random2 -= 1;
      }
    }
  } else {
    length = 0;
  }

  props.value?.choices?.map((e, index) => {
    if (index === random1) {
      imgUrl1 = e.content.image?.url;
      title1 = e.content.title.kr;
      content1 = e.content.description.kr;
    }
    if (index === random2) {
      imgUrl2 = e.content.image?.url;
      title2 = e.content.title.kr;
      content2 = e.content.description.kr;
    }
    //console.log("choices" + index + " title : " + e.content.title.kr);
    //console.log(
    // "choices" + index + " description : " + e.content.description.kr);
    //console.log("choices" + index + " image : " + e.content.image?.url);
    //console.log("choices" + index + " id : " + e.id);
    //console.log("choices" + index + " voteCount : " + e.voteCount);
  });

  console.log("test Value title : " + props.value?.title?.kr);

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <span style={closeStyle} onClick={props.onClose}>
          &times;
        </span>
        <HeaderDiv>
          <HeaderSpan>
            {category}&nbsp;{props.value?.choices?.length}강 월드컵
          </HeaderSpan>
        </HeaderDiv>
        <TitleDiv>
          <TitleSpanTop>{props.value?.title?.kr}</TitleSpanTop>
          <TitleSpanBottom>
            {props.value?.period?.from.toLocaleDateString()}
            {" ~ "}
            {props.value?.period?.to.toLocaleDateString()}
          </TitleSpanBottom>
        </TitleDiv>
        <NextDiv>
          <NextInnerDiv>
            <NextInnerSpan>NEXT&nbsp;&nbsp;{">"} </NextInnerSpan>
          </NextInnerDiv>
        </NextDiv>
        <TournamentDiv>
          <TournamentTitleDiv>
            <TournamentTitleLeftSpan>
              <ClockIcon style={svgIcon} /> D-86
            </TournamentTitleLeftSpan>
            <TournamentTitleCenterDiv>
              {props.value?.choices?.length}강
            </TournamentTitleCenterDiv>
            <TournamentTitleRightSpan>
              1/{props.value?.choices?.length}
            </TournamentTitleRightSpan>
          </TournamentTitleDiv>
          <TournamentPhotoDiv>
            <TournamentPhotoVsDiv>
              <VsIcon />
            </TournamentPhotoVsDiv>
            <TournamentPhotoLeftDiv>
              <TournamentPhotoInnerDiv>
                <TournamentPhotoInnerTopDiv>
                  <TournamentPhotoInnerTopImg
                    src={imgUrl1}
                  ></TournamentPhotoInnerTopImg>
                </TournamentPhotoInnerTopDiv>
                <TournamentPhotoInnerCenterDiv>
                  <TournamentPhotoInnerCenterTopSpan>
                    {title1}
                  </TournamentPhotoInnerCenterTopSpan>
                  <TournamentPhotoInnerCenterBottomSpan>
                    {content1}
                  </TournamentPhotoInnerCenterBottomSpan>
                </TournamentPhotoInnerCenterDiv>
                <TournamentPhotoInnerBottomSpan>
                  더 보기
                </TournamentPhotoInnerBottomSpan>
              </TournamentPhotoInnerDiv>
            </TournamentPhotoLeftDiv>
            <TournamentPhotoCenterDiv></TournamentPhotoCenterDiv>
            <TournamentPhotoRightDiv>
              <TournamentPhotoInnerTopDiv>
                <TournamentPhotoInnerTopImg
                  src={imgUrl2}
                ></TournamentPhotoInnerTopImg>
              </TournamentPhotoInnerTopDiv>
              <TournamentPhotoInnerCenterDiv>
                <TournamentPhotoInnerCenterTopSpan>
                  {title2}
                </TournamentPhotoInnerCenterTopSpan>
                <TournamentPhotoInnerCenterBottomSpan>
                  {content2}
                </TournamentPhotoInnerCenterBottomSpan>
              </TournamentPhotoInnerCenterDiv>
              <TournamentPhotoInnerBottomSpan>
                더 보기
              </TournamentPhotoInnerBottomSpan>
            </TournamentPhotoRightDiv>
          </TournamentPhotoDiv>
        </TournamentDiv>
      </div>
    </div>
  );
};

const TournamentVoteInput: React.FC<TournamentVoteInputProps> = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const createTournamentVote = useCreateTournamentVote();
  const toVotesPage = useToVotesPage();

  if (props.value != null && props.value?.voteKind !== "TOURNAMENT") {
    return null;
  }

  const value: Partial<TournamentVote> = props.value ?? {
    voteKind: "TOURNAMENT",
  };

  const onFieldChange =
    (fieldName: string) =>
    <T,>(newValue: T) => {
      props.onChange != null &&
        props.onChange({
          ...value,
          [fieldName]: newValue,
        });
    };

  const create = async () => {
    try {
      await createTournamentVote(value);
      toVotesPage();
    } catch (error) {
      console.error(error);
      alert("오류가 발생하였습니다");
    }
  };

  const onCreateClick = create;

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={props.className}>
      <Field title={"제목"}>
        <MultilingualStringInput
          value={value.title}
          onChange={onFieldChange("title")}
        />
      </Field>

      <Field title={"설명"}>
        <MultilingualStringInput
          value={value.description}
          onChange={onFieldChange("description")}
        />
      </Field>

      <Field title={"기간"}>
        <PeriodInput value={value.period} onChange={onFieldChange("period")} />
      </Field>

      <Field title={"썸네일"}>
        <ImageInput
          value={value.thumbnail}
          onChange={onFieldChange("thumbnail")}
          requestUploadInfoPath={"upload-tournament-vote-thumbnail"}
        />
      </Field>

      <Field title={"카테고리"}>
        <RefInput
          value={value.category}
          onChange={onFieldChange("category")}
          collection={"VOTE_CATEGORY"}
        />
      </Field>

      <Field title={"선택지"}>
        <TournamentVoteChoicesInput
          value={value.choices}
          onChange={onFieldChange("choices")}
        />
      </Field>

      <Button onClick={onCreateClick}>생성</Button>
      <Button onClick={openModal}>미리보기</Button>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} value={value}></Modal>
      )}
    </div>
  );
};

export default TournamentVoteInput;
export type { TournamentVoteInputProps };
