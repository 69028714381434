import { Typography } from "antd";
import Layout from "../../components/layout";
import QnaDetail from "../../components/qna/qna-detail";

const QnaDetailPage: React.FC = () => {
	return (
		<Layout>
			<Typography.Title>1대1 문의 관리</Typography.Title>
			<QnaDetail />
		</Layout>
	);
};

export default QnaDetailPage;
