import InternalFile from "../data-types/internal-file";
import {strict as assert} from "assert";

const internalFileToStoreKeyWhenUpdate = (file: InternalFile | undefined): string | null | undefined => {
  if (file == null) {
    return null;
  }

  if (file.storeKey == null) {
    return undefined;
  }

  if (file.storeKey != null) {
    return file.storeKey;
  }

  assert.fail();
};

export default internalFileToStoreKeyWhenUpdate;
