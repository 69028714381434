import {useNavigate} from "react-router-dom";

const buildToUrl = (url: string) => {
  return () => {
    const navigate = useNavigate();

    return () => navigate(url);
  };
};

export default buildToUrl;
