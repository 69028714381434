import CollectionMetadata from "../data-types/collection-metadata";
import country from '../data-metadata/country';
import region from '../data-metadata/region';
import user from '../data-metadata/country';
import association from '../data-metadata/association';
import idol from '../data-metadata/idol';
import {strict as assert} from 'assert';

const getCollectionMetadataById = (id: string): CollectionMetadata<any> => {
  switch (id) {
    case 'country': return country;
    case 'region': return region;
    case 'user': return user;
    case 'association': return association;
    case 'idol': return idol;
  }

  assert.fail();
};

export default getCollectionMetadataById;
