import CompoundItem from "../data-types/compound";
import internalFileFromUrl from "../utils/internal-file-from-url";
import {strict as assert} from 'assert';

const serialize = (r: Record<string, any>): CompoundItem => {
  switch (r.kind as CompoundItem['kind']) {
    case 'RANDOM_IDOL_VOTE':
      return {
        id: r.id,
        kind: 'RANDOM_IDOL_VOTE',
      };

    case 'RANDOM_DEMANDING_VOTE':
      return {
        id: r.id,
        kind: 'RANDOM_DEMANDING_VOTE',
      };

    case 'VOTES':
      return {
        id: r.id,
        kind: 'VOTES',
      };

    case 'ANNOUNCEMENTS':
      return {
        id: r.id,
        kind: 'ANNOUNCEMENTS',
      };

    case 'PLAIN_TEXT':
      return {
        id: r.id,
        kind: 'PLAIN_TEXT',
        text: r.text,
      };

    case 'COMMERCIAL':
      return {
        id: r.id,
        kind: 'COMMERCIAL',
        link: r.link,
        image: internalFileFromUrl(r.imageUrl),
      };

    case 'MAGAZINE':
      return {
        id: r.id,
        kind: 'MAGAZINE',
      };
  }

  assert.fail();
};

export default serialize;
