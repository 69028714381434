import React, {useState} from 'react';
import Vote from "../../data-types/vote";
import EventVote from "../../data-types/event-vote";
import {strict as assert} from 'assert';
import useHandleAppError from "../../services/handle-app-error";
import useUpdateEventVote from "../../services/update-event-vote";
import useToVotesPage from "../../services/to-votes-page";
import Field from "../../ant-form-inputs/field";
import MultilingualStringInput from "../../ant-form-inputs/multilingual-string-input";
import ImageInput from "../../ant-form-inputs/image-input";
import RefInput from "../../ant-form-inputs/ref-input";
import {Button} from "antd";
import PeriodInput from "../../ant-form-inputs/period-input";

interface UpdateVoteEventProps {
  className?: string | undefined;
  vote: Vote;
}

const toEventVoteForm = (vote_: Vote): Partial<EventVote> => {
  assert(vote_.voteKind === 'EVENT');

  const vote: EventVote = vote_;

  return {
    title: vote.title,
    description: vote.description,
    period: vote.period,
    thumbnail: vote.thumbnail,
    category: vote.category,
  };
};

const UpdateVoteEvent: React.FC<UpdateVoteEventProps> = (props) => {
  const [form, setForm] = useState<Partial<EventVote>>(toEventVoteForm(props.vote));
  const handleAppError = useHandleAppError();
  const updateEventVote = useUpdateEventVote();
  const toVotesPage = useToVotesPage();

  const changeField = (fieldName: string) => (newValue: any) => {
    setForm({...form, [fieldName]: newValue});
  };

  const tryUpdate = async () => {
    await updateEventVote(props.vote.id, form);
    toVotesPage();
  };

  const update = async () => {
    try {
      await tryUpdate();
    } catch (error) {
      await handleAppError(error);
    }
  };

  const onFieldChange = changeField;
  const onUpdateClick = update;

  return (
    <div className={props.className}>
      <Field title={'제목'}>
        <MultilingualStringInput
          value={form.title}
          onChange={onFieldChange('title')}
        />
      </Field>

      <Field title={'설명'}>
        <MultilingualStringInput
          value={form.description}
          onChange={onFieldChange('description')}
        />
      </Field>

      <Field title={'썸네일'}>
        <ImageInput
          value={form.thumbnail}
          onChange={onFieldChange('thumbnail')}
          requestUploadInfoPath={'upload-event-vote-thumbnail'}
        />
      </Field>

      <Field title={'카테고리'}>
        <RefInput
          value={form.category}
          onChange={onFieldChange('category')}
          collection={'VOTE_CATEGORY'}
        />
      </Field>

      <Field title={'기간'}>
        <PeriodInput
          value={form.period}
          onChange={onFieldChange('period')}
        />
      </Field>

      <Button onClick={onUpdateClick}>수정</Button>
    </div>
  );
};

export default UpdateVoteEvent;
export type {UpdateVoteEventProps};
