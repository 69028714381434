import TourCategory from "../data-types/tour-category";
import serializeTourCategory from '../from-server-serializers/tour-category';
import useAuth from "./auth";
import {apiAddr} from "../config";

const useCreateTourCategory = () => {
  const auth = useAuth();

  return async (payload: Partial<TourCategory>): Promise<TourCategory> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/tour-categories`,
      data: payload,
    });

    return serializeTourCategory(axiosResult.data);
  };
};

export default useCreateTourCategory;
