import React from 'react';
import buildPaginatedPage from "../../utils/build-paginated-page";
import Country from '../../data-types/country';
import countryCollectionMetadata from "../../data-metadata/country";

const CountriesPage = buildPaginatedPage<Country>({
  title: '국가 수정',
  collectionMetadata: countryCollectionMetadata,
});

export default CountriesPage;
