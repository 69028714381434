import buildCreatePage from "../../utils/build-create-page";
import associationCollectionMetadata from "../../data-metadata/association";
import Association from "../../data-types/association";

const CreateAssociationPage = buildCreatePage<Association>({
  title: '소속 생성',
  collectionMetadata: associationCollectionMetadata,
});

export default CreateAssociationPage;
