import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import TourCategory from "../../../data-types/tour-category";
import useReadTourCategories from "../../../services/read-tour-categories";
interface IProps {
	categoryId: string;
	onChange: (e: string) => void;
}
const ResultVoteCategory: React.FC<IProps> = (props) => {
	const [tourCategory, setTourCategory] = useState<TourCategory[]>();
	const api = useMemo(useReadTourCategories, []);
	const navigator = useNavigate();

	useEffect(() => {
		async function getData() {
			try {
				const { data } = await api({ limit: 10, skip: 0 });
				if (data.length === 0) {
					throw Error;
				}
				setTourCategory(data);
				props.onChange(data[0].id);
			} catch (e) {
				window.alert("Tour 카테고리를 불러오는 도중 에러가 발생 했습니다.");
				navigator("/result-vote");
			}
		}
		getData();
	}, [api, navigator]);
	if (!tourCategory) {
		return null;
	}
	return (
		<>
			<strong>카테고리 : </strong>
			<select
				title="category"
				onChange={(e) => {
					props.onChange(e.target.value);
				}}
				value={props.categoryId}
			>
				{tourCategory.map((item) => (
					<option key={item.id} value={item.id}>
						{item.name.en}
					</option>
				))}
			</select>
		</>
	);
};

export default ResultVoteCategory;
