import useAuth from "../auth";
import { apiAddr } from "../../config";
import { Attraction, AttractionPayload } from "../../data-types/attraction";
import internalFileToStoreKeyWhenUpdate from "../../utils/internal-file-to-store-key-when-update";

export const useUpdateAttraction = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<Attraction>) => {
    console.log("KDB ::TEST:: id: " + id + ", " + payload.id);
    const data: AttractionPayload = {
      name: payload.name,
      address: payload.address,
      latitude: payload.latitude,
      longitude: payload.longitude,
      location: [Number(payload.longitude || 0), Number(payload.latitude || 0)],
      tel: payload.tel,
      time: payload.time,
      memo: payload.memo,
      class1: payload.class1,
      class2: payload.class2,
      class3: payload.class3,
      class3Image: internalFileToStoreKeyWhenUpdate(payload.class3Image),
      images: [],
      modifiedAt: new Date(),
      modifiedId: auth.userId,
    };
    if (Array.isArray(payload.images) && payload.images.length > 0) {
      payload.images.forEach((item) => {
        let storeKey = internalFileToStoreKeyWhenUpdate(item);
        if (storeKey) {
          data.images!.push(storeKey);
        }
      });
    }
    await auth.callAxios({
      method: "post",
      url: `${apiAddr}/admin/v1/attraction/${id}`,
      data: data,
    });
    // return axiosResult;
    // console.log(axiosResult);
  };
};

export const useUpdateAttractionStatus = () => {
  const auth = useAuth();

  return async (id: string, statusCd: string) => {
    const data: AttractionPayload = {
      statusCd: statusCd,
      modifiedAt: new Date(),
      modifiedId: auth.userId,
      approvedAt: new Date(),
      approvedId: auth.userId,
    };

    const axiosResult = await auth.callAxios({
      method: "patch",
      url: `${apiAddr}/admin/v1/attraction/${id}`,
      data: data,
    });
    console.log(axiosResult);
  };
};

export default useUpdateAttraction;
