import IdolVote from "../data-types/idol-vote";
import serializePeriod from './period';
import internalFileFromUrl from "../utils/internal-file-from-url";
import runIfNotNull from "../utils/run-if-not-null";
import {Auth} from "../utils/auth";
import IdolVoteCandidate from "../data-types/idol-vote-candidate";
import {apiAddr} from "../config";

const serializeCandidate = (r: Record<string, any>): IdolVoteCandidate => {
  return {
    id: r.id,
    idol: r.idol,
    voteCount: r.voteCount,
  };
};

const joinCandidates = async (auth: Auth, voteId: string): Promise<IdolVoteCandidate[]> => {
  const axiosResult = await auth.callAxios({
    method: 'get',
    url: `${apiAddr}/admin/v1/votes/${voteId}/idol-vote-candidates`,
  });

  const result = axiosResult.data.map(serializeCandidate);
  return result;
};

const serializeIdolVote = async (auth: Auth, r: Record<string, any>): Promise<IdolVote> => {
  const obj: Partial<IdolVote> = {
    id: r.id,
    voteKind: 'IDOL',
    question: r.question,
    description: r.description,
    modification: r.modification,
    tabName: r.tabName,
    period: serializePeriod(r.period),
    backgroundImage: runIfNotNull(internalFileFromUrl, r.backgroundImage) ?? undefined,
    candidates: await joinCandidates(auth, r.id),
  };

  obj.voteTitle = obj.question;
  obj.votePeriod = obj.period;

  return obj as IdolVote;
};

export default serializeIdolVote;
