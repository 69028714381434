import React from 'react';
import Link from "../data-types/link";
import Field from "./field";
import TextInput from "./text-input";

interface LinkEditBodyUrlProps {
  className?: string | undefined;
  value?: Link | undefined;
  onChange?: ((value: Link) => void) | undefined;
}

const LinkEditBodyUrl: React.FC<LinkEditBodyUrlProps> = (props) => {
  const onUrlChange = (newUrl: string) => {
    const newLink: Link = {kind: 'URL', url: newUrl};

    props.onChange != null && props.onChange(newLink);
  };

  return (
    <Field title={'URL'}>
      <TextInput
        value={props.value?.url ?? ''}
        onChange={onUrlChange}
      />
    </Field>
  );
};

export default LinkEditBodyUrl;
