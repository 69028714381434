import { Typography } from "antd";
import Layout from "../../components/layout";
import PushAlarmList from "../../components/push-alarm/pusb-alarm-list";

const PushAlarmPage: React.FC = () => {
  return (
    <Layout>
      <Typography.Title>푸쉬 알람 관리</Typography.Title>
      <PushAlarmList />
    </Layout>
  );
};

export default PushAlarmPage;
