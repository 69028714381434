import { Auth } from "../utils/auth";
import runIfNotNull from "../utils/run-if-not-null";
import internalFileFromUrl from "../utils/internal-file-from-url";
import { Attraction } from "../data-types/attraction";

const serializeAttraction = async (
  auth: Auth,
  r: Record<string, any>
): Promise<Attraction> => {
  const obj: Partial<Attraction> = {
    id: r.id,
    name: r.name,
    address: r.address,
    latitude: r.latitude,
    longitude: r.longitude,
    location: r.location,
    tel: r.tel,
    time: r.time,
    memo: r.memo,
    class1: r.class1,
    class2: r.class2,
    class3: r.class3,
    class3Image: runIfNotNull(internalFileFromUrl, r.class3Image),
    images: [],
    statusCd: r.statusCd || "01",
    approvedAt: r.approvedAt,
    modifiedAt: r.modifiedAt,
    requestAt: r.requestAt,
  };
  if (Array.isArray(r.images) && r.images.length > 0) {
    r.images.forEach((item: string) => {
      obj.images!.push(runIfNotNull(internalFileFromUrl, item));
    });
  }
  return obj as Attraction;
};
export default serializeAttraction;
