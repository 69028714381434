import { useEffect, useState } from "react";
import useHandleAppError from "../../services/handle-app-error";
import { strict as assert } from "assert";
import useReadRewardingTicketCount from "../../services/read-rewarding-ticket-count";
import useUpdateRewardingTicketCount from "../../services/update-rewarding-ticket-count";
import useReadEventRewardingTicketCount from "../../services/read-event-rewarding-ticket-count";
import useUpdateEventRewardingTicketCount from "../../services/update-event-rewarding-ticket-count";

interface LoadingLogic {
  status: "LOADING";
}

interface LoadedLogic {
  status: "LOADED";
  rewardingTicketCount: number;
  setRewardingTicketCount: (value: any) => void;
  eventRewardingTicketCount: number;
  setEventRewardingTicketCount: (value: any) => void;
  submit: () => void;
}

interface FailedLogic {
  status: "FAILED";
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: "LOADING";
}

interface LoadedState {
  status: "LOADED";
  rewardingTicketCount: number;
  eventRewardingTicketCount: number;
}

interface FailedState {
  status: "FAILED";
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const initialState: State = { status: "LOADING" };
  const [state, setState] = useState<State>(initialState);
  const handleAppError = useHandleAppError();
  const readRewardingTicketCount = useReadRewardingTicketCount();
  const updateRewardingTicketCount = useUpdateRewardingTicketCount();
  const readEventRewardingTicketCount = useReadEventRewardingTicketCount();
  const updateEventRewardingTicketCount = useUpdateEventRewardingTicketCount();

  const tryInit = async () => {
    const rewardingTicketCount = await readRewardingTicketCount();
    const eventRewardingTicketCount = await readEventRewardingTicketCount();
    setState({
      status: "LOADED",
      rewardingTicketCount,
      eventRewardingTicketCount,
    });
  };

  const toFailed = () => setState({ status: "FAILED" });

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      toFailed();
      await handleAppError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (state.status === "LOADING") {
    return { status: "LOADING" };
  }

  if (state.status === "FAILED") {
    return { status: "FAILED" };
  }

  const trySubmit = async () => {
    await updateRewardingTicketCount(state.rewardingTicketCount);
    await updateEventRewardingTicketCount(state.eventRewardingTicketCount);
    alert("저장되었습니다");
    window.location.reload();
  };

  const setRewardingTicketCount = (newValue: number) => {
    setState((oldState) => ({ ...oldState, rewardingTicketCount: newValue }));
  };
  const setEventRewardingTicketCount = (newValue: number) => {
    setState((oldState) => ({
      ...oldState,
      eventRewardingTicketCount: newValue,
    }));
  };

  const submit = async () => {
    try {
      await trySubmit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  if (state.status === "LOADED") {
    return {
      status: "LOADED",
      rewardingTicketCount: state.rewardingTicketCount,
      setRewardingTicketCount,
      eventRewardingTicketCount: state.eventRewardingTicketCount,
      setEventRewardingTicketCount,
      submit,
    };
  }

  assert.fail();
};

export default useLogic;
export type { LoadingLogic, LoadedLogic, FailedLogic, Logic };
