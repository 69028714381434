import React from "react";
import Layout from "../../components/layout";
import { Button, InputNumber } from "antd";
import styled from "styled-components";
import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";
import useLogic from "./use-logic";
const Text = styled.span`
  width: 130px;
  display: inline-block;
  line-height: 40px;
`;

const UpdateAppRewardingTicketCountPage: React.FC = () => {
  const logic = useLogic();

  return (
    <Layout title={"보상 투표권수 관리"}>
      {renderWhenLoadingOrFailed(logic, (loadedLogic) => {
        return (
          <>
            <Text>일반투표 보상 : </Text>
            <InputNumber
              min={1}
              max={1000}
              value={loadedLogic.rewardingTicketCount}
              onChange={loadedLogic.setRewardingTicketCount}
            />
            <br></br>
            <Text>이벤트 투표 보상 : </Text>
            <InputNumber
              min={1}
              max={1000}
              value={loadedLogic.eventRewardingTicketCount}
              onChange={loadedLogic.setEventRewardingTicketCount}
            />
            <br></br>
            <Button onClick={loadedLogic.submit}>저장</Button>
          </>
        );
      })}
    </Layout>
  );
};

export default UpdateAppRewardingTicketCountPage;
