import useAuth from "../auth";
import { apiAddr } from "../../config";
import {
  Attraction,
  AttractionPayload,
  AttractionStatus,
} from "../../data-types/attraction";
import serializeAttraction from "../../from-server-serializers/attraction";

const useCreateAttraction = () => {
  const auth = useAuth();
  return async (payload: Partial<Attraction>): Promise<Attraction> => {
    const data: AttractionPayload = {
      name: payload.name,
      address: payload.address,
      latitude: payload.latitude,
      longitude: payload.longitude,
      location: [Number(payload.longitude || 0), Number(payload.latitude || 0)],
      tel: payload.tel,
      time: payload.time,
      memo: payload.memo,
      class1: payload.class1,
      class2: payload.class2,
      class3: payload.class3,
      class3Image: payload.class3Image?.storeKey,
      images: [],
      statusCd: AttractionStatus.REQUEST,
      modifiedAt: new Date(),
      modifiedId: auth.userId,
      requestAt: new Date(),
      requestId: auth.userId,
    };
    if (Array.isArray(payload.images) && payload.images.length > 0) {
      payload.images.forEach((item) => {
        if (item.storeKey && typeof item.storeKey !== "undefined") {
          data.images!.push(item.storeKey);
        }
      });
    }
    const axiosResult = await auth.callAxios({
      method: "post",
      url: `${apiAddr}/admin/v1/attraction`,
      data: data,
    });

    const attraction = (await serializeAttraction(
      auth,
      axiosResult.data
    )) as Attraction;
    return attraction;
  };
};

export default useCreateAttraction;
