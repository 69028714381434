import { apiAddr } from "../config";
import { Auth } from "../utils/auth";

class QnaApi {
	constructor(private auth: Auth) {}

	async getList(page: number) {
		this.auth.checkIsLoggedIn();
		const axiosResult = await this.auth.callAxios({
			method: "GET",
			url: `${apiAddr}/admin/v1/qnas?page=${page}`,
		});

		const result = await axiosResult.data;
		return result;
	}

	async getQna(id: string) {
		this.auth.checkIsLoggedIn();
		const axiosResult = await this.auth.callAxios({
			method: "GET",
			url: `${apiAddr}/admin/v1/qnas/${id}`,
		});

		const result = await axiosResult.data;
		return result;
	}

	async deleteQna(id: string) {
		this.auth.checkIsLoggedIn();
		const axiosResult = await this.auth.callAxios({
			method: "DELETE",
			url: `${apiAddr}/admin/v1/qnas/${id}`,
		});

		const status = await axiosResult.status;
		return status === 204;
	}

	async answerQna(id: string, answer: string) {
		this.auth.checkIsLoggedIn();
		const axiosResult = await this.auth.callAxios({
			method: "PATCH",
			url: `${apiAddr}/admin/v1/qnas/${id}`,
			data: { answer },
		});

		const result = axiosResult.data;
		return result;
	}
}

export default QnaApi;
