import React, {useState} from 'react';
import styled from 'styled-components';
import InternalFile from "../../../data-types/internal-file";
import {Button, Input, Typography} from "antd";
import registerTemporaryFile from "../../register-temporary-file";

const Cont = styled.div``;
const ValueCont = styled.div``;
const Image = styled.img`
  width: 260px;
  max-height: 800px;
`;

interface MediumImageInputProps {
  className?: string | undefined;
  value?: InternalFile | undefined;
  multipleValue?: InternalFile[] | undefined;
  onChange?: ((value: InternalFile | undefined) => void) | undefined;
  onChangeMultiple?:((value: InternalFile[] | undefined) => void) | undefined;
  requestUploadInfoPath?: string | undefined;
  disabled?:boolean|undefined;
  isMultipleUpload? : boolean | undefined;

}

const MediumImageInput: React.FC<MediumImageInputProps> = (props) => {
  const [inProgress, setInProgress] = useState<boolean>(false);

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  };

  const uploadAndTakeStoreKey = async (file: File): Promise<string> => {
    const requestUploadInfoPath = props.requestUploadInfoPath ?? 'upload-idol-thumbnail';
    const registerIdolThumbnail = registerTemporaryFile(requestUploadInfoPath);
    const key = await registerIdolThumbnail(file);
    return key;
  };

  const uploadAndCreateInternalFile = async (file: File): Promise<InternalFile> => {
    return {
      url: await blobToBase64(file),
      isNew: true,
      storeKey: await uploadAndTakeStoreKey(file),
    };
  };
  const setValue = async (file: File) => {
    try {
      if (inProgress)
      setInProgress(true);
      props.onChange != null && props.onChange(await uploadAndCreateInternalFile(file));
    } finally {
      setInProgress(false);
    }
  };
  const setMultipleValue = async (files: File[]) => {
    try {
      let rtnFile : InternalFile[] = [];
      if (inProgress)
        setInProgress(true);
      for(let i=0;i<files.length;i++){
        rtnFile.push(await uploadAndCreateInternalFile(files[i]));
      }
      props.onChangeMultiple != null && props.onChangeMultiple(rtnFile);
    } finally {
      setInProgress(false);
    }
  };
  const onFileChange = async (e: any) => {
    console.log(e);
    const hasFile = (e: any) => {
      return e.target.files.length !== 0;
    };

    const firstFile = (e: any) => {
      return e.target.files[0];
    };

    console.log(e.target.files);
    if (hasFile(e)) {
      if(props.isMultipleUpload){
        await setMultipleValue(e.target.files);
      }else{
        await setValue(firstFile(e));
      }

    }
  };

  const onDeleteClick = () => {
    if(props.isMultipleUpload){
      props.onChangeMultiple!=null && props.onChangeMultiple(undefined);
    }else{
      props.onChange != null && props.onChange(undefined);
    }
  };

  return (
    <Cont className={props.className}>
      <Input type='file' onChange={onFileChange} multiple={props.isMultipleUpload} style={{display:props.disabled?'none':''}}/>
      <Button danger onClick={onDeleteClick} style={{display:props.disabled?'none':''}}>
        삭제
      </Button>
      <ValueCont>
        {props.value != null ?
          <Image src={props.value?.url}/> :
            (props.isMultipleUpload?null:<Typography.Text>이미지 없음</Typography.Text>)
        }
        {
          props.multipleValue !=null && Array.isArray(props.multipleValue)?
              props.multipleValue.map((item,idx)=><Image key={idx} src={item.url}/>):
              (props.isMultipleUpload?<Typography.Text>이미지 없음</Typography.Text>:null)
        }
      </ValueCont>
    </Cont>
  );
};

export default MediumImageInput;
export type {MediumImageInputProps};
