import buildPaginatedPage from "../../utils/build-paginated-page";
import associationCollectionMetadata from "../../data-metadata/association";
import Association from "../../data-types/association";

const AssociationsPage = buildPaginatedPage<Association>({
  title: '소속 관리',
  collectionMetadata: associationCollectionMetadata,
});

export default AssociationsPage;
