import useAuth from "./auth";
import {apiAddr} from "../config";

const useReadAppMaxIdolVoteCountPerDay = () => {
  const auth = useAuth();

  return async (): Promise<number> => {
    const axiosResult = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/admin/v1/app/max-idol-vote-count-per-day`,
    });

    return axiosResult.data;
  };
};

export default useReadAppMaxIdolVoteCountPerDay;
