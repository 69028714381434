import useAuth from "./auth";
import InternalFile from "../data-types/internal-file";
import runIfNotNull from "../utils/run-if-not-null";
import Announcement from "../data-types/announcement";
import {apiAddr} from "../config";

const useUpdateAnnouncement = () => {
  const auth = useAuth();

  const updateInternalToExternal = (payload: Partial<Announcement>): Record<string, any> => {
    const takeKey = (a: InternalFile) => a.storeKey;

    return {
      ...payload,
      image: runIfNotNull(takeKey, payload.image ?? null),
      issuedAt: payload.createdAt,
    };
  };

  return async (id: string, payload: Partial<Announcement>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/announcements/${id}`,
      data: updateInternalToExternal(payload),
    });
  };
};

export default useUpdateAnnouncement;
