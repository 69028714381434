import { useNavigate } from "react-router-dom";

const buildToUrlWithId = (url: string) => {
  return () => {
    const navigate = useNavigate();

    return (id: string) => navigate(`${url}/${id}`);
  };
};

export default buildToUrlWithId;
