import React from 'react';
import Layout from "../../components/layout";
import useLogic from "./use-logic";
import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";
import {Button} from "antd";
import CompoundItemsInput from "./compound-items-input";

const UpdateAppMainPageContentsPage: React.FC = () => {
  const logic = useLogic();

  return (
    <Layout title={'메인 페이지 컨텐츠 관리'}>
      {renderWhenLoadingOrFailed(logic, loadedLogic => {
        const onAddCommercial = loadedLogic.createAppendCommercial;
        const onAddRandomIdolVote = loadedLogic.createAppendRandomIdolVote;
        const onAddRandomDemandingVote = loadedLogic.createAppendRandomDemandingVote;
        const onAddVotes = loadedLogic.createAppendVotes;
        const onAddAnnouncements = loadedLogic.createAppendAnnouncements;
        const onAddPlainText = loadedLogic.createAppendPlainText;
        const onAddMagazines = loadedLogic.createAppendMagazines;


        return (
          <>
            <CompoundItemsInput
              value={loadedLogic.mainPageContents}
              onChange={loadedLogic.setMainPageContents}
            />
            <div>
              <Button onClick={onAddCommercial}>광고 추가</Button>
              <Button onClick={onAddRandomIdolVote}>랜덤 아이돌 투표 추가</Button>
              <Button onClick={onAddRandomDemandingVote}>랜덤 월드텁 투표 추가</Button>
              <Button onClick={onAddVotes}>투표 추가</Button>
              <Button onClick={onAddAnnouncements}>공지 추가</Button>
              <Button onClick={onAddPlainText}>단순 텍스트 추가</Button>
              <Button onClick={onAddMagazines}>매거진 추가</Button>
            </div>
            <Button onClick={loadedLogic.submit}>
              저장
            </Button>
          </>
        );
      })}
    </Layout>
  );
};

export default UpdateAppMainPageContentsPage;
