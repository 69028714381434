import useAuth from "./auth";
import { apiAddr } from "../config";

const useReadEventRewardingTicketCount = () => {
  const auth = useAuth();

  return async (): Promise<number> => {
    const axiosResult = await auth.callAxios({
      method: "get",
      url: `${apiAddr}/admin/v1/app/event-rewarding-ticket-count`,
    });

    return axiosResult.data;
  };
};

export default useReadEventRewardingTicketCount;
