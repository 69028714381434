import React from 'react';
import styled from 'styled-components';

const Cont = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

interface SmallImageProps {
  className?: string | undefined;
  src?: string | undefined;
}

const SmallImage: React.FC<SmallImageProps> = (props) => {
  return <Cont className={props.className} src={props.src}/>;
};

export default SmallImage;
export type {SmallImageProps};
