import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {strict as assert} from 'assert';
import AuthEventHandler from './auth-event-handler';
import AuthEventResolver from './auth-event-resolver';
import AuthData from './auth-data';
import {apiAddr} from '../../config';
import {v4 as uuid} from 'uuid';
import NotLoggedInError from "../../errors/not-logged-in";
import cookies from 'js-cookie';
import RefreshFailedError from "./errors/refresh-failed";
import UnacceptedAuthDataError from "./errors/unaccepted-auth-data";
import MalformedAuthDataError from "../../errors/malformed-auth-data";

const normalizeAuthData = (authData: AuthData): AuthData => {
  try {
    return {
      user: authData.user,
      tokens: {
        access: {
          ...authData.tokens.access,
          expires: typeof authData.tokens.access.expires === 'string' ?
            new Date(authData.tokens.access.expires) :
            authData.tokens.access.expires,
        },
        refresh: {
          ...authData.tokens.refresh,
          expires: typeof authData.tokens.refresh.expires === 'string' ?
            new Date(authData.tokens.refresh.expires) :
            authData.tokens.refresh.expires,
        },
      },
    };
  } catch (error) {
    throw new MalformedAuthDataError();
  }
};

class AlreadyRefreshingError extends Error {}

class AuthImpl {
  authData: AuthData | undefined;
  authCookieName: string;
  eventHandlers: Record<string, AuthEventHandler>;
  callQueue: [{res: (value: any) => void, rej: (error: any) => void}, AxiosRequestConfig][];
  isRefreshing: boolean;

  constructor() {
    this.eventHandlers = {};
    this.authCookieName = 'admin-auth';
    this.callQueue = [];
    this.isRefreshing = false;
  }

  writeAuthData(authData: AuthData | undefined) {
    if(authData == null) {
      cookies.remove(this.authCookieName);
      this.authData = undefined;
      return;
    }

    cookies.set(this.authCookieName, JSON.stringify(authData));
    this.authData = normalizeAuthData(authData);
  }

  readAuthData(): AuthData | undefined {
    if (this.authData != null) {
      return this.authData;
    }

    const storedAuthDataJson = cookies.get(this.authCookieName);

    if (storedAuthDataJson == null) {
      return undefined;
    }

    const storedAuthData = normalizeAuthData(JSON.parse(storedAuthDataJson));
    this.authData = storedAuthData;
    return this.authData;
  }

  login(authData: AuthData) {
    if (this.isLoggedIn) {
      this.logout();
    }

    this.writeAuthData(authData);
    this.emitLoginEvent(this.readAuthData()!);
  }

  logout() {
    try {
      if (!this.isLoggedIn) {
        return;
      }
    } catch (error) {
      console.error(error);
    }

    this.writeAuthData(undefined);
    this.emitLogoutEvent();
  }

  subscribe(eventHandler: AuthEventHandler): AuthEventResolver {
    const handlerId = uuid();
    this.eventHandlers[handlerId] = eventHandler;
    return () => delete this.eventHandlers[handlerId];
  }

  emitLoginEvent(authData: AuthData) {
    Object.entries(this.eventHandlers).forEach(
      ([key, handler]) => {
        handler({ kind: 'LOGIN', authData });
      }
    );
  }

  emitLogoutEvent() {
    Object.entries(this.eventHandlers).forEach(
      ([key, handler]) => {
        handler({ kind: 'LOGOUT' });
      }
    );
  }

  get isLoggedIn(): boolean {
    return this.readAuthData() != null;
  }

  get accessTokenExpired(): boolean {
    assert(this.isLoggedIn);
    return this.readAuthData()!.tokens.access.expires.valueOf() < new Date().valueOf() + 10000;
  }

  get refreshTokenExpired(): boolean {
    assert(this.isLoggedIn);
    return this.readAuthData()!.tokens.refresh.expires.valueOf() < new Date().valueOf();
  }

  checkIfRefreshTokenExpired() {
    if (this.refreshTokenExpired) {
      throw new RefreshFailedError();
    }
  }

  async refresh() {
    this.checkIfRefreshTokenExpired();

    if (this.isRefreshing) {
      throw new AlreadyRefreshingError();
    }

    let result;
    try {
      this.isRefreshing = true;
      result = await axios({
        method: 'post',
        url: `${apiAddr}/v1/auth/refresh-tokens`,
        data: {refreshToken: this.refreshToken},
      });

      this.writeAuthData(normalizeAuthData({
        ...this.readAuthData()!,
        tokens: result.data,
      }));
    } catch (error) {
      console.error(error);
      throw new RefreshFailedError();
    } finally {
      this.isRefreshing = false;
      for (let i = 0; i < this.callQueue.length; ++i) {
        const [promise, axiosOptions] = this.callQueue[i];
        promise.rej(0);
      }
      this.callQueue = [];
    }

    for (let i = 0; i < this.callQueue.length; ++i) {
      const [promise, axiosOptions] = this.callQueue[i];

      try {
        const result = await this.callAxios(axiosOptions);
        promise.res(result);
      } catch (error) {
        promise.rej(error);
      }
    }
    this.callQueue = [];
  }

  async refreshIfRequired() {
    assert(this.isLoggedIn);

    if (this.accessTokenExpired) {
      await this.refresh();
    }
  }

  get accessToken(): string | undefined {
    return this.readAuthData()?.tokens.access.token;
  }

  get refreshToken(): string | undefined {
    return this.readAuthData()?.tokens.refresh.token;
  }

  async attachAccessTokenToOptions(options: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    if (!this.isLoggedIn) {
      return options;
    }

    await this.refreshIfRequired();

    return {
      ...options,
      headers: {
        ...('headers' in options) ? options.headers : {},
        Authorization: `Bearer ${this.accessToken!}`,
      },
    };
  }

  async callAxios(options: AxiosRequestConfig, isRetry: boolean = false): Promise<AxiosResponse> {
    let optionsWithAccessToken;
    try {
      optionsWithAccessToken = await this.attachAccessTokenToOptions(options);
    } catch (error) {
      if (error instanceof AlreadyRefreshingError) {
        return new Promise((res, rej) => {
          this.callQueue = [...this.callQueue, [{res, rej}, options]];
        });
      }

      throw error;
    }

    try {
      const result = await axios(optionsWithAccessToken);
      return result;
    } catch (error: any) {
      const status: number | undefined = error?.response?.status;
      if (this.isLoggedIn && (status === 401 || status === 403)) {
        throw new UnacceptedAuthDataError();
      }

      throw error;
    }
  }

  get userId(): string | undefined {
    return this.readAuthData()?.user.id;
  }

  checkIsLoggedIn() {
    if (!this.isLoggedIn) {
      throw new NotLoggedInError();
    }
  }

  get email(): string | undefined {
    return this.readAuthData()?.user.email;
  }
}

export default AuthImpl;
