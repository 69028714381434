import FieldMetadata from "../data-types/field-metadata";
import { Typography } from "antd";

const buildTextFieldRender = <T,>(
  that: FieldMetadata<T>
): FieldMetadata<T>["render"] => {
  return (params) => {
    console.log(params);
    const value = params.value as unknown as string;

    return <Typography.Text key={that.name}>{value}</Typography.Text>;
  };
};

export default buildTextFieldRender;
