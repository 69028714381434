import {Auth} from "../utils/auth";
import serializePeriod from './period';
import runIfNotNull from "../utils/run-if-not-null";
import internalFileFromUrl from "../utils/internal-file-from-url";
import serializeVoteChoiceContent from './vote-choice-content';
import EventVoteChoice from "../data-types/event-vote-choice";
import EventVote from "../data-types/event-vote";

const serializeChoice = (r: Record<string, any>): EventVoteChoice => {
  return {
    id: r.id,
    voteCount: r.voteCount,
    content: serializeVoteChoiceContent(r.content),
  };
};

const serialize = async (auth: Auth, r: Record<string, any>): Promise<EventVote> => {
  const obj: Partial<EventVote> = {
    id: r.id,
    voteKind: 'EVENT',
    title: r.title,
    description: r.description,
    period: serializePeriod(r.period),
    category: r.category,
    thumbnail: runIfNotNull(internalFileFromUrl, r.thumbnail) ?? undefined,
    choices: r.choices.map(serializeChoice),
  };

  obj.voteTitle = obj.title;
  obj.votePeriod = obj.period;

  return obj as EventVote;
};

export default serialize;
