import React from 'react';
import {Typography} from "antd";
import styled from 'styled-components';

const Cont = styled.div`
  margin-top: 60px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

interface FieldProps {
  className?: string | undefined;
  title?: string | undefined;
  children?: any;
}

const Field: React.FC<FieldProps> = (props) => {
  return (
    <Cont className={props.className}>
      <Typography.Title level={4}>{props.title}</Typography.Title>
      {props.children}
    </Cont>
  );
};

export default Field;
export type {FieldProps};
