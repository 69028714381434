import React, { useState,useEffect } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import {Button, Form, Input, Layout, Menu,Radio } from 'antd';

import '../../index.css';
import useLogin from "../../services/login";
import useHandleAppError from "../../services/handle-app-error";
import useToMainPage from "../../services/to-main-page";

const { Header, Sider, Content } = Layout;


const LoginPage: React.FC = () => {
  const login = useLogin();
  const handleAppError = useHandleAppError();
  const toMain = useToMainPage();

    const [collapsed, setCollapsed] = useState(false);
    const [apiServerUrl, setApiServerUrl] = useState<string>('prod');

  const onFinish = async (formData: Record<string, any>) => {
    try {
      await login({email: formData.email, password: formData.password});
      toMain();
    } catch (error) {
      await handleAppError(error);
    }
  };

  const onChangeServer = (e:any)=>{

      console.log(e.target.value)
      sessionStorage.setItem("apiServerUrl",e.target.value);
  }

  useEffect(()=>{
      sessionStorage.removeItem("apiServerUrl");
      const serverUrl = sessionStorage.getItem("apiServerUrl");
      if(serverUrl){
          setApiServerUrl(serverUrl);
      }else{
          sessionStorage.setItem("apiServerUrl","prod");
          setApiServerUrl("prod");
      }
  })

  return (
      <Layout style={{ minHeight: "100vh" }}>
          <Sider trigger={null} collapsible collapsed={collapsed} width={`240`} style={{
              minHeight: 800,
          }}>
              <div className="logo" ><img src={`/logo-new.png`}/></div>
              <Menu
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={['1']}
                  items={[
                      {
                          key: '1',
                          icon: <UserOutlined />,
                          label: 'Login',
                      },
                      // {
                      //     key: '2',
                      //     icon: <VideoCameraOutlined />,
                      //     label: 'nav 2',
                      // },
                      // {
                      //     key: '3',
                      //     icon: <UploadOutlined />,
                      //     label: 'nav 3',
                      // },
                  ]}
              />
          </Sider>
          <Layout className="site-layout">
              <Header className="site-layout-background" style={{ padding: 0 }}>
                  {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                      className: 'trigger',
                      onClick: () => {
                      },
                  })}
                  Fanup Admin
              </Header>
              <Content
                  className="site-layout-background"
                  style={{
                      margin: '24px 16px',
                      padding: 24,
                      minHeight: 800,
                  }}
              >
                  <Form onFinish={onFinish}
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                  >
                      <Form.Item
                          label={'이메일'}
                          name={'email'}
                      >
                          <Input/>
                      </Form.Item>
                      <Form.Item
                          label={'비밀번호'}
                          name={'password'}

                      >
                          <Input type={`password`}/>
                      </Form.Item>
                      {/*<Form.Item*/}
                      {/*    wrapperCol={{ offset: 4, span: 20 }}*/}
                      {/*>*/}
                      {/*    <Radio.Group defaultValue={apiServerUrl} buttonStyle="solid" style={{ marginTop: 16 }} onChange={onChangeServer}>*/}
                      {/*        <Radio.Button value="prod">Product</Radio.Button>*/}
                      {/*        <Radio.Button value="dev">Develop</Radio.Button>*/}
                      {/*    </Radio.Group>*/}
                      {/*</Form.Item>*/}
                      <Form.Item
                          wrapperCol={{ offset: 4, span: 20 }}
                      >
                          <Button type={'primary'} htmlType={'submit'}>
                              로그인
                          </Button>
                      </Form.Item>
                  </Form>
              </Content>
          </Layout>
      </Layout>
  );
};

export default LoginPage;

