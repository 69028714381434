import buildReadCollectionDocument from "../utils/build-read-collection-document";
import Region from '../data-types/region';
import serializeRegion from '../from-server-serializers/region';

const useReadRegion = buildReadCollectionDocument<Region>({
  collection: 'regions',
  serializer: serializeRegion,
});

export default useReadRegion;
