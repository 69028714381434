import useDelegateUnhandledError from "./delegate-unhandled-error";
import RefreshFailedError from "../utils/auth/errors/refresh-failed";
import UnacceptedAuthDataError from "../utils/auth/errors/unaccepted-auth-data";
import useAuth from "./auth";
import MalformedEmailError from "../errors/malformed-email";
import MalformedPasswordError from "../errors/malformed-password";
import MalformedAuthDataError from "../errors/malformed-auth-data";

const useHandleAppError = () => {
  const delegateUnhandledError = useDelegateUnhandledError();
  const auth = useAuth();

  return async (error: any) => {
    if (error instanceof MalformedEmailError) {
      alert('입력하신 이메일의 형식이 잘못되었습니다');
      return;
    }

    if (error instanceof MalformedPasswordError) {
      alert('입력하신 비밀번호의 형식이 잘못되었습니다');
      return;
    }

    if (error instanceof RefreshFailedError) {
      auth.logout();
      return;
    }

    if (error instanceof UnacceptedAuthDataError) {
      auth.logout();
      return;
    }

    if (error instanceof MalformedAuthDataError) {
      auth.logout();
      return;
    }

    await delegateUnhandledError(error);
  };
};

export default useHandleAppError;
