import Region from "../data-types/region";
import useAuth from "./auth";
import {apiAddr} from "../config";

const useUpdateRegion = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<Region>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/regions/${id}`,
      data: payload,
    });
  };
};

export default useUpdateRegion;
