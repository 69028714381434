import buildPaginatedPage from "../../utils/build-paginated-page";
import CollectionMetadata from "../../data-types/collection-metadata";
import FieldMetadata from "../../data-types/field-metadata";
import buildTextFieldRender from "../../utils/build-text-field-render";
import { Attraction } from "../../data-types/attraction";
import buildAttractStatusFieldRender from "../../utils/build-attract-status-field-render";
import buildDateFieldRender from "../../utils/build-date-field-render";
import {
  useReadAttraction,
  useReadAttractions,
} from "../../services/attraction/read-attraction";
import useToUpdateAttraction from "../../services/attraction/to-update-attraction-page";
import useToCreateAttraction from "../../services/attraction/to-create-attraction-page";
import buildMultilingualStringFieldRender from "../../utils/build-multilingual-string-field-render";

class IdField implements Partial<FieldMetadata<Attraction>> {
  readonly name = "id";
  readonly label = "UID";
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildTextFieldRender(this);
}

class TitleField implements Partial<FieldMetadata<Attraction>> {
  readonly name = "name";
  readonly label = "명칭";
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildMultilingualStringFieldRender(this);
}
class StatusField implements Partial<FieldMetadata<Attraction>> {
  readonly name = "statusCd";
  readonly label = "상태";
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildAttractStatusFieldRender(this);
}

class RequestField implements Partial<FieldMetadata<Attraction>> {
  readonly name = "requestAt";
  readonly label = "등록일자";
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildDateFieldRender(this);
}

const collectionMetadata: Partial<CollectionMetadata<Attraction>> = {
  useRead: useReadAttraction,
  useReadByPagination: useReadAttractions,
  useDefaultDetailsAction: useToUpdateAttraction,
  useToCreatePage: useToCreateAttraction,
  fields: [
    new IdField() as FieldMetadata<Attraction>,
    new TitleField() as FieldMetadata<Attraction>,
    new StatusField() as FieldMetadata<Attraction>,
    new RequestField() as FieldMetadata<Attraction>,
  ],
};

const AttractionPage = buildPaginatedPage<Attraction>({
  title: "관광지 관리",
  collectionMetadata: collectionMetadata as CollectionMetadata<Attraction>,
});

export default AttractionPage;
