import React from 'react';
import FieldMetadata from "../data-types/field-metadata";
import {Form, Input, Typography} from "antd";
import User from "../data-types/user";
import {strict as assert} from "assert";
import fromRole from "./from-role";

interface ReadOnlyRoleInputProps{
  value: User['role'];
}

const ReadOnlyRoleInput: React.FC<ReadOnlyRoleInputProps> = (props) => {
  return <Input disabled={true} value={fromRole(props.value)}/>;
};

const buildRoleFieldRenderReadOnlyFormItem = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['renderReadOnlyFormItem'] => {
  return () => {
    return (
      <Form.Item
        key={that.name}
        label={that.label}
        name={that.name}
      >
        {/* @ts-ignore */}
        <ReadOnlyRoleInput/>
      </Form.Item>
    );
  };
};

export default buildRoleFieldRenderReadOnlyFormItem;
