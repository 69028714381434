import FieldMetadata from "../../data-types/field-metadata";
import {Form} from "antd";
import MediumImage from "./medium-image";

const buildImageFieldRenderReadOnlyFormItem = <T,>(that: FieldMetadata<T>): FieldMetadata<T>['renderReadOnlyFormItem'] => {
  return () => {
    return (
      <Form.Item
        key={that.name}
        label={that.label}
        name={that.name}
      >
        <MediumImage/>
      </Form.Item>
    );
  };
};

export default buildImageFieldRenderReadOnlyFormItem;
