import Idol from "../data-types/idol";
import useAuth from "./auth";
import {apiAddr} from "../config";
import runIfNotNull from "../utils/run-if-not-null";
import InternalFile from "../data-types/internal-file";

const useUpdateIdol = () => {
  const auth = useAuth();

  const updateInternalToExternal = (payload: Partial<Idol>): Record<string, any> => {
    const takeKey = (a: InternalFile) => a.storeKey;

    return {
      ...payload,
      thumbnail: runIfNotNull(takeKey, payload.thumbnail ?? null),
    };
  };

  return async (id: string, payload: Partial<Idol>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/idols/${id}`,
      data: updateInternalToExternal(payload),
    });
  };
};

export default useUpdateIdol;
