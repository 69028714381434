import buildReadCollectionDocument from "../utils/build-read-collection-document";
import Idol from "../data-types/idol";
import serializeIdol from '../from-server-serializers/idol';

const useReadIdol = buildReadCollectionDocument<Idol>({
  collection: 'idols',
  serializer: serializeIdol,
});

export default useReadIdol;
