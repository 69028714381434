import React, {useEffect, useState} from "react";
import Layout from "../../components/layout";
import {Attraction} from "../../data-types/attraction";
import AttractionInput from "../../components/attraction/attraction-input";
import {useParams} from "react-router-dom";
import {useReadAttraction} from "../../services/attraction/read-attraction";

/**
 * 데이터 조회
 * @param id
 * @constructor
 */
const GetData = async (id : string)=>{
    return await useReadAttraction()(id);
}

const UpdateAttractionPage : React.FC = () => {
    const { id } = useParams();
    const [attraction, setAttraction] = useState<Partial<Attraction> | undefined>(undefined);
    useEffect(() => {
        GetData(id!).then((result)=>{
            setAttraction(result);
        });
    }, [id]);
    return (
        <Layout title={'관광지 수정'}>
            <AttractionInput
                value={attraction}
                isCreate={false}
                onChange={setAttraction}
            />
        </Layout>
    );
};

export default UpdateAttractionPage;
