import CollectionMetadata from "../data-types/collection-metadata";
import VoteCategory from "../data-types/vote-category";
import useReadVoteCategory from "../services/read-vote-category";
import useToVoteCategoriesPage from "../services/to-vote-categories-page";
import useUpdateVoteCategory from "../services/update-vote-category";
import useReadVoteCategories from "../services/read-vote-categories";
import useCreateVoteCategory from "../services/create-vote-category";
import useToCreateVoteCategoryPage from "../services/to-create-vote-category-page";
import FieldMetadata from "../data-types/field-metadata";
import buildTextFieldRender from "../utils/build-text-field-render";
import buildTextFieldRenderReadOnlyFormItem from "../utils/build-text-field-render-read-only-form-item";
import buildTextFieldRenderEditableFormItem from "../utils/build-text-field-render-editable-form-item";
import useToUpdateVoteCategoryPage from "../services/to-update-vote-category-page";
import buildMultilingualStringFieldRender from "../utils/build-multilingual-string-field-render";
import buildMultilingualStringFieldRenderReadOnlyFormItem
  from "../utils/build-multilingual-string-field-render-read-only-form-item";
import buildMultilingualStringFieldRenderEditableFormItem
  from "../utils/build-multilingual-string-field-render-editable-form-item";

class IdField implements FieldMetadata<VoteCategory> {
  readonly name = 'id';
  readonly label = 'UID';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildTextFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildTextFieldRenderEditableFormItem(this);
}

class NameField implements FieldMetadata<VoteCategory> {
  readonly name = 'name';
  readonly label = '이름';
  readonly customizable = true;

  // @ts-ignore
  readonly render = buildMultilingualStringFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildMultilingualStringFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildMultilingualStringFieldRenderEditableFormItem(this);
}

const voteCategoryCollectionMetadata: CollectionMetadata<VoteCategory> = {
  useRead: useReadVoteCategory,
  useToOverallPage: useToVoteCategoriesPage,
  useUpdate: useUpdateVoteCategory,
  useReadByPagination: useReadVoteCategories,
  useCreate: useCreateVoteCategory,
  useToCreatePage: useToCreateVoteCategoryPage,
  useDefaultDetailsAction: useToUpdateVoteCategoryPage,
  fields: [
    new IdField(),
    new NameField(),
  ],
  fieldsForBrief: ['id', 'name'],
};

export default voteCategoryCollectionMetadata;
