import useAuth from "./auth";
import CompoundItem from "../data-types/compound";
import {apiAddr} from "../config";
import serializeCompoundItem from '../from-server-serializers/compound-item';

const useReadAppMainPageContents = () => {
  const auth = useAuth();

  return async (): Promise<CompoundItem[]> => {
    const axiosResult = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/admin/v1/app/main-page-contents`,
    });

    return axiosResult.data.map(serializeCompoundItem);
  };
};

export default useReadAppMainPageContents;
