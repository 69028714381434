import axios, {AxiosResponse} from 'axios';
import { apiAddr } from '../config';
import { AuthData } from '../utils/auth';
import useLoginWithAuthData from "./login-with-auth-data";
import checkEmail from "../errors/check-email";
import checkPassword from "../errors/check-password";

interface LoginParams {
  email: string;
  password: string;
}

const useLogin = () => {
  const loginWithAuthData = useLoginWithAuthData();

  const validateInput = (params: LoginParams) => {
    checkEmail(params.email);
    checkPassword(params.password);
  };

  return async (params: LoginParams) => {
    validateInput(params);

    let axiosResult: AxiosResponse;

    const getAuthDataFromAxiosResult = () => {
      return axiosResult.data as AuthData;
    };

    axiosResult = await axios({
      method: 'post',
      url: `${apiAddr}/v1/auth/login`,
      data: {
        email: params.email,
        password: params.password,
      },
    });

    await loginWithAuthData(getAuthDataFromAxiosResult());
  };
};

export default useLogin;
