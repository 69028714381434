import useAuth from "./auth";
import CompoundItem, {CommercialCompoundItem} from "../data-types/compound";
import {apiAddr} from "../config";

const useUpdateAppMainPageContents = () => {
  const auth = useAuth();

  return async (payload: CompoundItem[]) => {
    await auth.callAxios({
      method: 'put',
      url: `${apiAddr}/admin/v1/app/main-page-contents`,
      data: payload.map((one) => {
        const bypass = (a: any) => a;

        const serializers = {
          'COMMERCIAL': (item: CommercialCompoundItem) => ({
            id: item.id,
            kind: 'COMMERCIAL',
            link: item.link,
            image: item.image?.storeKey ?? item.image?.url,
          }),
          'RANDOM_IDOL_VOTE': bypass,
          'RANDOM_DEMANDING_VOTE': bypass,
          'VOTES': bypass,
          'ANNOUNCEMENTS': bypass,
          'PLAIN_TEXT': bypass,
          'MAGAZINE': bypass,
        };

        return serializers[one.kind](one as any);
      }),
    });
  };
};

export default useUpdateAppMainPageContents;
