import VoteCategory from "../data-types/vote-category";

const serialize = (raw: Record<string, any>): VoteCategory => {
  return {
    id: raw.id,
    name: raw.name,
  };
};

export default serialize;
