import React from 'react';
import Link from "../data-types/link";
import {strict as assert} from 'assert';
import LinkEditBodyUrl from "./link-edit-body-url";

interface LinkEditBodyProps {
  className?: string | undefined;
  value?: Link | undefined;
  onChange?: ((value: Link) => void) | undefined;
}

const LinkEditBody: React.FC<LinkEditBodyProps> = (props) => {
  if (props.value == null) { return null; }

  switch (props.value.kind) {
    case 'URL': return <LinkEditBodyUrl {...props}/>;
  }

  assert.fail();
};

export default LinkEditBody;
export type {LinkEditBodyProps};
