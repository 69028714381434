import IdolVoteCandidate from "../data-types/idol-vote-candidate";
import {apiAddr} from "../config";
import {Auth} from "./auth";

const createIdolVoteCandidateFragment = (auth: Auth, voteId: string) => async (payload: Partial<IdolVoteCandidate>): Promise<IdolVoteCandidate> => {
  const axiosResult = await auth.callAxios({
    method: 'post',
    url: `${apiAddr}/admin/v1/votes/${voteId}/idol-vote-candidates`,
    data: {idol: payload.idol},
  });

  return axiosResult.data;
};

export default createIdolVoteCandidateFragment;
