import React from 'react';
import FieldMetadata from "../data-types/field-metadata";
import {Form, Input, Typography} from "antd";
import CollectionRefInput from "../components/collection-ref-input";
import getCollectionMetadataById from "./get-collection-metadata-by-id";
import CollectionMetadata from "../data-types/collection-metadata";

const buildRefFieldRenderEditableFormItem = <OwnerT, RefT>(
  that: FieldMetadata<OwnerT>,
  collectionMetadataId: string
): FieldMetadata<OwnerT>['renderEditableFormItem'] => () => {
  const collectionMetadata: CollectionMetadata<RefT> = getCollectionMetadataById(collectionMetadataId);

  return (
    <Form.Item
      key={that.name}
      label={that.label}
      name={that.name}
    >
      <CollectionRefInput collectionMetadata={collectionMetadata}/>
    </Form.Item>
  );
};

export default buildRefFieldRenderEditableFormItem;
