import useAuth from "./auth";
import InternalFile from "../data-types/internal-file";
import runIfNotNull from "../utils/run-if-not-null";
import Announcement from "../data-types/announcement";
import {apiAddr} from "../config";
import serializeAnnouncement from "../from-server-serializers/announcement";

const useCreateAnnouncement = () => {
  const auth = useAuth();

  const createInternalToExternal = (payload: Partial<Announcement>): Record<string, any> => {
    const takeKey = (a: InternalFile) => a.storeKey;

    return {
      ...payload,
      image: runIfNotNull(takeKey, payload.image),
      issuedAt: payload.createdAt,
    };
  };

  return async (payload: Partial<Announcement>): Promise<Announcement> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/announcements`,
      data: createInternalToExternal(payload),
    });

    return serializeAnnouncement(axiosResult.data);
  };
};

export default useCreateAnnouncement;
