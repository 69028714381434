import FromServerSerializer from "../data-types/from-server-serializer";
import useAuth from "../services/auth";
import Page from "../data-types/page";
import {apiAddr} from "../config";
import serializePage from "./serialize-page";

interface BuildParams<T> {
  collection: string;
  serializer: FromServerSerializer<T>;
}

interface ServiceParams {
  limit: number;
  skip?: number | undefined;
}

const buildReadCollectionDocuments = <T>(buildParams: BuildParams<T>) => () => {
  const auth = useAuth();

  return async (serviceParams: ServiceParams): Promise<Page<T>> => {
    auth.checkIsLoggedIn();

    const axiosResult = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/admin/v1/${buildParams.collection}`,
      params: {limit: serviceParams.limit, skip: serviceParams.skip},
    });

    const getSerializer = () => {
      if (buildParams.serializer.length === 2) {
        return (r: Record<string, any>) => buildParams.serializer(auth, r);
      }

      return buildParams.serializer;
    };

    const result = await serializePage(axiosResult.data, getSerializer());
    return result;
  };
};

export default buildReadCollectionDocuments;
