import React from 'react';
import EventVoteChoice from "../data-types/event-vote-choice";
import CommonDemandingVoteChoicesInput from "./common-demanding-vote-choices-input";

interface EventVoteChoicesInputProps {
  className?: string | undefined;
  value?: Partial<EventVoteChoice>[];
  onChange?: ((value: Partial<EventVoteChoice>[]) => void) | undefined;
}

const EventVoteChoicesInput: React.FC<EventVoteChoicesInputProps> = (props) => {
  return <CommonDemandingVoteChoicesInput
    {...props}
    limit={2}
    requestUploadInfoPath={'upload-event-vote-choice-image'}
  />
};

export default EventVoteChoicesInput;
export type {EventVoteChoicesInputProps};
