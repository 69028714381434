import Announcement from "../data-types/announcement";
import internalFileFromUrl from "../utils/internal-file-from-url";

const serialize = (r: Record<string, any>): Announcement => {
  return {
    id: r.id,
    title: r.title,
    kind: r.kind,
    image: internalFileFromUrl(r.image),
    createdAt: new Date(r.issuedAt),
  };
};

export default serialize;
