import User from "../data-types/user";

const serialize = (raw: Record<string, any>): User => {
  return {
    id: raw.id,
    createdAt: new Date(raw.createdAt),
    birth: raw.birth,
    email: raw.email,
    isEmailVerified: raw.isEmailVerified,
    name: raw.name,
    role: raw.role,
    ticketCount: raw.ticketCount,
  };
};

export default serialize;
