import IdolVote from "../data-types/idol-vote";
import useAuth from "./auth";
import {apiAddr} from "../config";
import createIdolVoteCandidateFragment from "../utils/create-idol-vote-candidate-fragment";
import internalFileToStoreKeyWhenUpdate from "../utils/internal-file-to-store-key-when-update";

const useUpdateIdolVote = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<IdolVote>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/votes/${id}`,
      data: {
        voteKind: 'IDOL',
        question: payload.question,
        modification: payload.modification,
        backgroundImage: internalFileToStoreKeyWhenUpdate(payload.backgroundImage),
        tabName: payload.tabName,
        description: payload.description,
        period: payload.period,
      },
    });

    const createCandidate = createIdolVoteCandidateFragment(auth, id);

    await Promise.all(payload.candidates!.map(createCandidate));
  };
};

export default useUpdateIdolVote;
