import React from 'react';
import CompoundItem, {CommercialCompoundItem, PlainTextCompoundItem} from "../../data-types/compound";
import OrderedInput from "../../components/ordered-input";
import Field from "../../ant-form-inputs/field";
import {Typography} from "antd";
import LinkInput from "../../ant-form-inputs/link-input";
import ImageInput from "../../ant-form-inputs/image-input";
import TextInput from "../../ant-form-inputs/text-input";

interface CompoundItemsInputProps {
  className?: string | undefined;
  value?: CompoundItem[] | undefined;
  onChange?: ((value: CompoundItem[]) => void) | undefined;
}

interface ItemProps {
  value?: CompoundItem | undefined;
  onChange?: ((newValue: CompoundItem) => void) | undefined;
  index: number;
}

const ItemWrapper: React.FC<{title: string; children?: any}> = (props) => {
  return (
    <div>
      <Typography.Title level={5}>
        {props.title}
      </Typography.Title>
      {props.children}
    </div>
  );
};

const ItemCommercial: React.FC<ItemProps> = (props) => {
  if (props.value == null) { return null; }

  const item = props.value as CommercialCompoundItem;

  const onFieldChange = (fieldName: string) => (newValue: any) => {
    props.onChange != null && props.onChange({
      ...item,
      [fieldName]: newValue,
    });
  };

  return (
    <ItemWrapper title={'광고'}>
      <Field title={'링크'}>
        <LinkInput value={item.link} onChange={onFieldChange('link')}/>
      </Field>
      <Field title={'이미지'}>
        <ImageInput
          value={item.image}
          onChange={onFieldChange('image')}
          requestUploadInfoPath={'upload-app-main-page-contents-commercial-image'}
        />
      </Field>
    </ItemWrapper>
  );
};

const ItemRandomIdolVote: React.FC<ItemProps> = (props) => {
  return <ItemWrapper title={'랜덤 아이돌 투표'}/>;
};

const ItemRandomDemandingVote: React.FC<ItemProps> = (props) => {
  return <ItemWrapper title={'랜덤 월드컵 투표'}/>
};

const ItemVotes: React.FC<ItemProps> = (props) => {
  return <ItemWrapper title={'랜덤 투표'}/>;
};

const ItemAnnouncements: React.FC<ItemProps> = (props) => {
  return <ItemWrapper title={'공지'}/>;
};

const ItemPlainText: React.FC<ItemProps> = (props) => {
  const value = props.value as PlainTextCompoundItem;

  const onTextChange = (newValue: string) => {
    props.onChange != null && props.onChange({...value, text: newValue});
  };

  return (
    <ItemWrapper title={'단순 텍스트'}>
      <TextInput
        value={value.text}
        onChange={onTextChange}
      />
    </ItemWrapper>
  );
};
const ItemMagazines: React.FC<ItemProps> = (props) => {
  return <ItemWrapper title={'매거진'}/>;
};


const Item: React.FC<ItemProps> = (props) => {
  if (props.value == null) { return null; };

  const components = {
    COMMERCIAL: ItemCommercial,
    RANDOM_IDOL_VOTE: ItemRandomIdolVote,
    RANDOM_DEMANDING_VOTE: ItemRandomDemandingVote,
    VOTES: ItemVotes,
    ANNOUNCEMENTS: ItemAnnouncements,
    PLAIN_TEXT: ItemPlainText,
    MAGAZINE: ItemMagazines,
  };

  const Component = components[props.value.kind];

  return <Component {...props}/>;
};

const CompoundItemsInput: React.FC<CompoundItemsInputProps> = (props) => {
  return <OrderedInput
    itemComponent={Item}
    value={props.value}
    onChange={props.onChange as any}
  />;
};

export default CompoundItemsInput;
export type {CompoundItemsInputProps};
