import { Badge, Pagination, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QnaTitle } from "../../../data-types/qna";
import QnaApi from "../../../services/qnaAPI";
import useAuth from "../../../services/auth";

const QnaList: React.FC = () => {
  const navigator = useNavigate();

  const [qnaList, setQnaList] = useState<QnaTitle[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalQna, setTotalQna] = useState(0);

  const auth = useAuth();
  const api = useMemo(() => {
    return new QnaApi(auth);
  }, [auth]);

  useEffect(() => {
    async function getData(page: number) {
      const data = await api.getList(page);
      if (data === null) {
        throw new Error("Qna 데이터를 불러 올 수 없습니다.");
      }

      setQnaList(data.results);
      setTotalQna(data.totalResults);
    }
    getData(page);
  }, [page, api]);

  return (
    <>
      <Table
        dataSource={qnaList}
        onRow={(record) => {
          return {
            onClick: () => {
              navigator(`${record.id}`);
            },
          };
        }}
        pagination={false}
        style={{ cursor: "pointer" }}
        rowKey={(record) => record.id}
      >
        <Column title="아이디" dataIndex="id" key="id" />
        <Column
          title="답변 여부"
          dataIndex="isAnswered"
          render={(isAnswered: boolean) =>
            isAnswered ? <Badge status="success" /> : <Badge status="warning" />
          }
        />
        <Column title="제목" dataIndex="title" />
        <Column title="작성자" dataIndex="email" />
        <Column title="카테고리" dataIndex="category" />
        <Column
          title="생성일"
          dataIndex="createAt"
          key="createAt"
          render={(_, record: any) => record.createdAt.toString().substr(0, 10)}
        />
      </Table>
      <Pagination
        style={{ float: "right", padding: "1rem" }}
        defaultCurrent={1}
        total={totalQna}
        showSizeChanger={false}
        onChange={(e) => {
          setPage(e);
        }}
      />
    </>
  );
};

export default QnaList;
