import React from 'react';
import {Alert, Spin} from 'antd';
import {strict as assert} from 'assert';

type LoadedLogic = {status: 'LOADED'};

type Logic =
  LoadedLogic |
  {
    status: 'FAILED';
  } |
  {
    status: 'LOADING';
  };

const renderWhenLoadingOrFailed = <L extends Logic>(
  logic: L,
  whenLoaded: (l: L & LoadedLogic) => any
): any => {
  if (logic.status === 'LOADING') {
    return <Spin/>;
  }

  if (logic.status === 'FAILED') {
    return <Alert type={'error'} message={'에러가 발생하였습니다'}/>;
  }

  if (logic.status === 'LOADED') {
    return whenLoaded(logic as L & LoadedLogic);
  }

  assert.fail();
};

export default renderWhenLoadingOrFailed;
