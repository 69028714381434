import {Auth} from "../utils/auth";
import TournamentVote from "../data-types/tournament-vote";
import serializePeriod from './period';
import runIfNotNull from "../utils/run-if-not-null";
import internalFileFromUrl from "../utils/internal-file-from-url";
import TournamentVoteChoice from "../data-types/tournament-vote-choice";
import serializeVoteChoiceContent from './vote-choice-content';

const serializeChoice = (r: Record<string, any>): TournamentVoteChoice => {
  return {
    id: r.id,
    voteCount: r.voteCount,
    content: serializeVoteChoiceContent(r.content),
  };
};

const serialize = async (auth: Auth, r: Record<string, any>): Promise<TournamentVote> => {
  const obj: Partial<TournamentVote> = {
    id: r.id,
    voteKind: 'TOURNAMENT',
    title: r.title,
    description: r.description,
    period: serializePeriod(r.period),
    category: r.category,
    thumbnail: runIfNotNull(internalFileFromUrl, r.thumbnail) ?? undefined,
    choices: r.choices.map(serializeChoice),
  };

  obj.voteTitle = obj.title;
  obj.votePeriod = obj.period;

  return obj as TournamentVote;
};

export default serialize;
