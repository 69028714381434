import { Divider, InputRef, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../services/auth";
import PushAlarmApi from "../../../services/pushAlarmAPI";
import { Button, DatePicker, Form, Input, Select, Space } from "antd";
import { PusbAlarmDeviceT, PushAlarmT } from "../../../data-types/push-alarm";
const init: PushAlarmT = {
  id: "new",
  scheduleTitle: "",
  content: "",
  device: "all",
  scheduleDate: new Date(),
  sended: false,
};
const PushAlarmDetail: React.FC = () => {
  const { id } = useParams();
  const [data, setData] = useState<PushAlarmT>(init);
  const navigator = useNavigate();
  const auth = useAuth();
  const api = useMemo(() => {
    return new PushAlarmApi(auth);
  }, [auth]);

  const onSubmit = async () => {
    console.log(data);
    try {
      if (id === "new") {
        await api.create(data);
      } else {
        await api.update(data.id!, data);
      }
      window.alert("저장이 성공했습니다.");
      navigator("/push-alarm");
    } catch {
      window.alert("저장 도중 오류가 발생 했습니다.");
      setData(init);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    async function getData() {
      try {
        const pushAlarm = await api.get(id!);
        console.log(pushAlarm);
        setData({
          id: pushAlarm.id,
          scheduleTitle: pushAlarm.scheduleTitle,
          content: pushAlarm.content,
          device: pushAlarm.device,
          scheduleDate: pushAlarm.scheduleDate,
          sended: pushAlarm.sended,
        });
      } catch {
        window.alert("잘못된 접근 입니다.");
        navigator("/push-alarm");
      }
    }

    if (id !== "new") {
      getData();
    }
  }, [id, api, navigator]);

  return (
    <>
      <Divider />
      <Form
        name="push-alarm"
        onFinish={onSubmit}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
      >
        <Form.Item label="타이틀">
          <Space>
            <Form.Item
              noStyle
              rules={[{ required: true, message: "타이틀은 필수 값 입니다." }]}
            >
              <Input
                style={{ width: 700 }}
                placeholder="Please input"
                name="scheduleTitle"
                defaultValue={data.scheduleTitle}
                value={data.scheduleTitle}
                onChange={onChange}
              />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="컨텐트">
          <Space>
            <Form.Item
              noStyle
              rules={[{ required: true, message: "컨텐트는 필수 값 입니다." }]}
            >
              <Input
                style={{ width: 700 }}
                placeholder="Please input"
                name="content"
                defaultValue={data.content}
                value={data.content}
                onChange={onChange}
              />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="디바이스">
          <Select
            placeholder="Select province"
            style={{ width: "300px" }}
            defaultValue={data.device ?? "all"}
            value={data.device}
            onChange={(e) => {
              setData({ ...data, device: e as PusbAlarmDeviceT });
            }}
          >
            <Select.Option value="all">all</Select.Option>
            <Select.Option value="ios">ios</Select.Option>
            <Select.Option value="android">android</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="발송일"
          rules={[{ required: true, message: "발송 시간은 필수 값입니다." }]}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm:ss"
            onChange={(e) => {
              setData({ ...data, scheduleDate: e?.toDate()! });
            }}
          />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit">
            저장
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PushAlarmDetail;
