import React, { useState, useEffect } from "react";
import Vote from "../../data-types/vote";
import Field from "../../ant-form-inputs/field";
import styled from "styled-components";
import MultilingualStringInput from "../../ant-form-inputs/multilingual-string-input";
import ImageInput from "../../ant-form-inputs/image-input";
import PeriodInput from "../../ant-form-inputs/period-input";
import IdolVoteCandidatesInput from "../../ant-form-inputs/idol-vote-candidates-input";
import { Button } from "antd";
import useCreateIdolVote from "../../services/create-idol-vote";
import IdolVote from "../../data-types/idol-vote";
import useToVotesPage from "../../services/to-votes-page";
import MultilingualString from "../../data-types/multilingual-string";
import InternalFile from "../../data-types/internal-file";
import Period from "../../data-types/period";
import IdolVoteCandidate from "../../data-types/idol-vote-candidate";
import { apiAddr } from "../../config";
import useAuth from "../../services/auth";
import { ReactComponent as VoteIcon } from "./svg/Vector.svg";
import { ReactComponent as ArrowIcon } from "./svg/Arrow4.svg";
import { ReactComponent as VoteMiniIcon } from "./svg/VoteMini.svg";

interface IdolVoteInputProps {
  className?: string | undefined;
  value: Partial<Vote> | undefined;
  onChange: ((value: Partial<Vote>) => void) | undefined;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  value: Partial<Vote> | undefined;
  title: MultilingualString | undefined;
  subTitle: MultilingualString | undefined;
  backImg: InternalFile | undefined;
  tabName: MultilingualString | undefined;
  time: Period | undefined;
  idols: IdolVoteCandidate[];
  description: MultilingualString | undefined;
}

const TabHeaderFont = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 18px;
  font-family: BalooBhaijaan;
  color: rgb(251, 193, 198);
`;
const TabHeaderLine = styled.div`
  height: 2px;
  width: 500px;
  background-color: rgb(251, 193, 198);
`;

const Cont = styled.div`
  position: relative;
`;
const SubCont = styled.div`
  position: absolute;
  top: 275px;
`;
const Sub2Cont = styled.div`
  position: absolute;
  top: 400px;
  border-top: 8px solid #ededed;
  width: 500px;
  border-bottom: 8px solid #ededed;
  heigth: 150px;
`;
const Sub3Cont = styled.div`
  position: absolute;
  top: 535px;
  border-top: 8px solid #ededed;
  width: 500px;
`;
const Sub4Cont = styled.div`
  position: absolute;
  top: 650px;
  border-top: 8px solid #ededed;
  width: 500px;
`;
const Sub5Cont = styled.div`
  position: absolute;
  top: 750px;
  border-top: 8px solid #ededed;
  width: 500px;
`;
const GraySpace = styled.div`
  height: 8px;
  background-color: #ededed;
`;
const Title = styled.span`
  display: block;
  font-size: 32px;
  color: white;
  line-height: 130%;
  font-weight: bold;
`;

const Dark = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 500px;
  height: 200px;
  background-color: black;
  opacity: 0.5;
`;

const Description = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: white;
`;
const Image = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 500px;
  height: 200px;
  object-fit: cover;
`;

const InfoCont = styled.div`
  position: relative;
  left: 24px;
  right: 24px;
  top: 134px;
`;

const RealFont = styled.span`
  font-weight: bold;
  padding-left: 15px;
`;
const ChartFont = styled.span`
  position: fixed;
  font-size: 20px;
  padding-left: 0px;
`;
const ChartSubFont = styled.span`
  position: fixed;
  font-size: 12px;
  margin-top: 23px;
`;
const ChartArea = styled.div`
  padding: 13px 16px;
  display: block;
  height: 127px;
`;
const VoteArea = styled.div`
  padding: 33px 16px;
  display: block;
  height: 110px;
`;
const ChartClockDiv = styled.div`
  margin-top: 40px;
  height: 40px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(5, 19, 49);
`;
const ClockFont = styled.span`
  color: white;
`;

const RealChart = styled.div`
  margin-top: 12px;
  padding-right: 30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 12px;
  height: 100px;
  width: 500px;
  border-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`;
const BlackFont = styled.span`
  color: black;
  font-weight: bold;
`;
const GrayFont = styled.span`
  color: gray;
  font-weight: bold;
`;
const PinkFont = styled.span`
  color: rgb(253, 123, 178);
  font-weight: bold;
`;
const PinkVoteFont = styled.span`
  color: rgb(253, 123, 178);
  font-size: 25px;
  font-weight: bold;
`;
const RealChartFont = styled.div`
  margin-left: 12px;
  font-size: 48px;
  line-height: 60px;
  color: rgb(253, 123, 178);
  font-weight: bold;
`;

const ChartImg = styled.img`
  margin-left: 14px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;
const ChartIdolBox = styled.span`
  margin-left: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  flex: 1 1 0%;
  align-self: start;
`;
const ChartIdolName = styled.span`
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: rgb(5, 19, 49);
`;
const ChartGroupName = styled.span`
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: rgb(152, 165, 179);
`;
const ChartVote = styled.span`
  display: block;
  margin-top: 6px;
  color: rgb(5, 19, 49);
  font-size: 12px;
  line-height: 15px;
`;
const ChartProgressBar = styled.div`
  margin-top: 4px;
  height: 8px;
  background: rgb(218, 224, 230);
  display: flex;
  border-radius: 4px;
`;
const ChartProgressBarColor = styled.div`
  height: 8px;
  width: 50%;
  border-radius: 4px;
  background-color: rgb(253, 123, 178);
`;

const DescriptionDiv = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
`;

const OtherChartTopImgDiv = styled.div`
  margin-left: 15px;
  width: 76px;
  height: 76px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 3px solid rgb(253, 123, 178);
`;
const OtherChartImgDiv = styled.div`
  margin-left: 15px;
  width: 76px;
  height: 76px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const OtherChartImg = styled.img`
  width: 100%;
  height: 100%;
  overflow-clip-margin: content-box;
  overflow: clip;
`;
const OtherChartSpanDiv = styled.div`
  top: 0px;
  left: 0px;
  position: absolute;
  width: 20px;
  height: 20px;
  border-bottom-right-radius: 2px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.88);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
`;
const OtherChartTopSpan = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: rgb(253, 123, 178) !important;
`;
const OtherChartSpan = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: rgb(251, 193, 198) !important;
`;

const OtherVoteIconDiv = styled.div`
  width: 80px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(218, 224, 230);
  border-radius: 4px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
`;
const OtherVoteIconSpan = styled.div`
  margin-top: 1px;
  font-size: 14px;
  line-height: 18px;
  color: rgb(5, 19, 49);
`;

const OtherChartRightDiv = styled.div`
  margin-left: 8px;
  flex: 1 1 0%;
  display: block;
`;
const OtherChartContentDiv = styled.div`
  display: flex;
`;
const OtherChartTextDiv = styled.div`
  flex: 1 1 0%;
`;

const OtherChartTopProgressBarDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
`;
const OtherChartTopProgressBarColor = styled.div`
  height: 16px;
  width: 10%;
  border-radius: 8px;
  background-image: linear-gradient(-90deg, #ff176b 20%, #a329fa 80%);
`;
const OtherChartProgressBarColor = styled.div`
  height: 16px;
  width: 5%;
  border-radius: 8px;
  background-color: rgb(251, 193, 198);
`;
const OtherChartTopProgressBarSpan = styled.span`
  margin-left: 8px;
  color: white;
  font-size: 10px;
`;
const ChartTopProgressBar = styled.div`
  margin-top: 8px;
  height: 16px;
  background-color: rgb(218, 224, 230);
  display: flex;
  border-radius: 8px;
  position: relative;
`;

interface IdolType {
  id: string;
  name: MultilingualString;
  region: string | undefined;
  association: string | undefined;
  thumbnail: InternalFile | undefined;
  createdAt: Date;
}

// 데이터 처리 함수
function processData(idolData: IdolType[]): {
  firstIdol: IdolType | null;
  otherIdols: IdolType[];
} {
  if (idolData.length === 0) {
    return { firstIdol: null, otherIdols: [] };
  }

  // 첫 번째 아이돌 데이터 추출
  const firstIdol = idolData[0];
  // 나머지 아이돌 데이터 추출
  const otherIdols = idolData.slice(1);

  return { firstIdol, otherIdols };
}

const Modal: React.FC<ModalProps> = (props) => {
  const [idolIds, setIdolIds] = useState<string[]>([]);
  const [idolData, setIdolData] = useState<IdolType[]>([]);
  const [isDescription, setIsDescription] = useState(false);
  const auth = useAuth();

  const readIdol = async (id: string) => {
    return await auth.callAxios({
      method: "get",
      url: `${apiAddr}/admin/v1/idols/${id}`,
    });
  };
  useEffect(() => {
    const extractedIds = props.idols.map((item) => item.idol);
    setIdolIds(extractedIds);
  }, [props.idols]);

  useEffect(() => {
    if (props.isOpen) {
      setIdolData([]);

      idolIds.reduce(async (previousPromise, idolId) => {
        try {
          // 이전 비동기 호출이 끝날 때까지 기다림
          await previousPromise;

          const IdolData = await readIdol(idolId);

          // 데이터를 idolData 배열에 추가
          setIdolData((prevData) => [...prevData, IdolData.data]);
        } catch (error) {
          console.error("API 요청 중 오류 발생: ", error);
        }
      }, Promise.resolve());
    }
  }, [props.isOpen, idolIds]);

  if (!props.isOpen) return null;

  const { firstIdol, otherIdols } = processData(idolData);

  // 첫 번째 데이터를 렌더링하는 컴포넌트
  function FirstIdolComponent({ data }: { data: IdolType | null }) {
    if (!data) {
      return null;
    }

    const url: string | undefined = data.thumbnail?.toString();
    return (
      <RealChart>
        <RealChartFont>1</RealChartFont>
        <ChartImg src={url}></ChartImg>
        <ChartIdolBox>
          <ChartIdolName>{data.name.kr}</ChartIdolName>
          <ChartGroupName>{data.name.en}</ChartGroupName>
          <ChartVote>0</ChartVote>
          <ChartProgressBar>
            <ChartProgressBarColor></ChartProgressBarColor>
          </ChartProgressBar>
        </ChartIdolBox>
        <VoteIcon className="svgIcon" />
      </RealChart>
    );
  }
  // 나머지 데이터를 렌더링하는 컴포넌트
  function OtherTopIdolsComponent({ data }: { data: IdolType | null }) {
    if (!data) {
      return null;
    }

    return (
      <div>
        <RealChart>
          <OtherChartTopImgDiv>
            <OtherChartImg src={data.thumbnail?.toString()}></OtherChartImg>
            <OtherChartSpanDiv>
              <OtherChartTopSpan>1</OtherChartTopSpan>
            </OtherChartSpanDiv>
          </OtherChartTopImgDiv>
          <OtherChartRightDiv>
            <OtherChartContentDiv>
              <OtherChartTextDiv>
                <ChartIdolName>{data.name.kr}</ChartIdolName>
                <ChartGroupName>{data.name.en}</ChartGroupName>
              </OtherChartTextDiv>
              <OtherVoteIconDiv>
                <VoteMiniIcon className="svgIcon" />
                <OtherVoteIconSpan>Vote</OtherVoteIconSpan>
              </OtherVoteIconDiv>
            </OtherChartContentDiv>

            <ChartTopProgressBar>
              <OtherChartTopProgressBarColor></OtherChartTopProgressBarColor>
              <OtherChartTopProgressBarDiv>
                <OtherChartTopProgressBarSpan>0</OtherChartTopProgressBarSpan>
              </OtherChartTopProgressBarDiv>
            </ChartTopProgressBar>
          </OtherChartRightDiv>
        </RealChart>
      </div>
    );
  }
  // 나머지 데이터를 렌더링하는 컴포넌트
  function OtherIdolsComponent({ data }: { data: IdolType[] }) {
    if (data.length === 0) {
      return null;
    }

    return (
      <div>
        {data.map((idol, index) => (
          <RealChart>
            <OtherChartImgDiv>
              <OtherChartImg src={idol.thumbnail?.toString()}></OtherChartImg>
              <OtherChartSpanDiv>
                <OtherChartSpan>{index + 2}</OtherChartSpan>
              </OtherChartSpanDiv>
            </OtherChartImgDiv>
            <OtherChartRightDiv>
              <OtherChartContentDiv>
                <OtherChartTextDiv>
                  <ChartIdolName>{idol.name.kr}</ChartIdolName>
                  <ChartGroupName>{idol.name.en}</ChartGroupName>
                </OtherChartTextDiv>
                <OtherVoteIconDiv>
                  <VoteMiniIcon className="svgIcon" />
                  <OtherVoteIconSpan>Vote</OtherVoteIconSpan>
                </OtherVoteIconDiv>
              </OtherChartContentDiv>

              <ChartTopProgressBar>
                <OtherChartProgressBarColor></OtherChartProgressBarColor>
                <OtherChartTopProgressBarDiv>
                  <OtherChartTopProgressBarSpan>0</OtherChartTopProgressBarSpan>
                </OtherChartTopProgressBarDiv>
              </ChartTopProgressBar>
            </OtherChartRightDiv>
          </RealChart>
        ))}
      </div>
    );
  }

  const modalStyle: React.CSSProperties = {
    display: props.isOpen ? "flex" : "none",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
  };
  const modalContentStyle: React.CSSProperties = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "550px",
    height: "100%",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    maxWidth: "550px",
    margin: "0 auto",
    position: "relative",
  };
  const closeStyle: React.CSSProperties = {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    fontSize: "24px",
    color: "#777",
    zIndex: 9999,
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <span style={closeStyle} onClick={props.onClose}>
          &times;
        </span>
        <TabHeaderLine></TabHeaderLine>
        <TabHeaderFont>{props.tabName?.kr}</TabHeaderFont>
        <Cont>
          <Image src={props.backImg?.url} />
          <Dark />
          <InfoCont>
            <Title>{props.title?.kr}</Title>
            <Description>{props.subTitle?.kr}</Description>
          </InfoCont>
        </Cont>
        <SubCont>
          <RealFont>Real-Time Chart</RealFont>
          <FirstIdolComponent data={firstIdol} />
        </SubCont>
        <GraySpace></GraySpace>

        <Sub2Cont>
          <ChartArea>
            <ChartFont>CHART</ChartFont>
            <ChartSubFont>
              <BlackFont>1</BlackFont>
              <GrayFont>일</GrayFont> <PinkFont>1000</PinkFont>
              <GrayFont>표 투표가능 |</GrayFont> <BlackFont>1</BlackFont>
              <GrayFont> 회 당</GrayFont>
            </ChartSubFont>
            <ChartClockDiv>
              <ClockFont>{props.time?.to.toLocaleString("KR")}</ClockFont>
            </ChartClockDiv>
            <DescriptionDiv onClick={() => setIsDescription(!isDescription)}>
              <GrayFont>투표 정보</GrayFont>
            </DescriptionDiv>
          </ChartArea>
        </Sub2Cont>
        <GraySpace></GraySpace>
        {isDescription && (
          <div>
            <Sub3Cont>
              <VoteArea>
                <GrayFont>{props.description?.kr}</GrayFont>
              </VoteArea>
            </Sub3Cont>

            <Sub4Cont>
              <VoteArea>
                <PinkVoteFont>
                  지난투표순위
                  확인하기&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <ArrowIcon />
                </PinkVoteFont>
              </VoteArea>
            </Sub4Cont>
            <Sub5Cont>
              <OtherTopIdolsComponent data={firstIdol} />
              <OtherIdolsComponent data={otherIdols} />
            </Sub5Cont>
          </div>
        )}
        <GraySpace></GraySpace>
        {!isDescription && (
          <div>
            <Sub3Cont>
              <VoteArea>
                <PinkVoteFont>
                  지난투표순위
                  확인하기&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <ArrowIcon />
                </PinkVoteFont>
              </VoteArea>
            </Sub3Cont>
            <Sub4Cont>
              <OtherTopIdolsComponent data={firstIdol} />
              <OtherIdolsComponent data={otherIdols} />
            </Sub4Cont>
          </div>
        )}
      </div>
    </div>
  );
};

const IdolVoteInput: React.FC<IdolVoteInputProps> = (props) => {
  const createIdolVote = useCreateIdolVote();
  const toVotesPage = useToVotesPage();
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (props.value != null && props.value?.voteKind !== "IDOL") {
    return null;
  }

  const value: Partial<IdolVote> = props.value ?? { voteKind: "IDOL" };

  const onFieldChange =
    (fieldName: string) =>
    <T,>(newValue: T) => {
      props.onChange != null &&
        props.onChange({
          ...value,
          [fieldName]: newValue,
        });
    };

  const create = async () => {
    try {
      await createIdolVote(value);
      toVotesPage();
    } catch (error) {
      console.error(error);
      alert("오류가 발생하였습니다");
    }
  };

  const onCreateClick = create;

  return (
    <div className={props.className}>
      <Field title={"질문"}>
        <MultilingualStringInput
          value={value.question}
          onChange={onFieldChange("question")}
        />
      </Field>

      <Field title={"수식어"}>
        <MultilingualStringInput
          value={value.modification}
          onChange={onFieldChange("modification")}
        />
      </Field>

      <Field title={"배경사진"}>
        <ImageInput
          value={value.backgroundImage}
          onChange={onFieldChange("backgroundImage")}
          requestUploadInfoPath={"upload-idol-vote-background-image"}
        />
      </Field>

      <Field title={"탭 이름"}>
        <MultilingualStringInput
          value={value.tabName}
          onChange={onFieldChange("tabName")}
        />
      </Field>

      <Field title={"설명"}>
        <MultilingualStringInput
          value={value.description}
          onChange={onFieldChange("description")}
        />
      </Field>

      <Field title={"기간"}>
        <PeriodInput value={value.period} onChange={onFieldChange("period")} />
      </Field>

      <Field title={"지원자"}>
        <IdolVoteCandidatesInput
          value={value.candidates}
          onChange={onFieldChange("candidates")}
        />
      </Field>

      <Button onClick={onCreateClick}>생성</Button>
      <Button onClick={openModal}>미리보기</Button>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        value={value}
        title={value.question}
        subTitle={value.modification}
        backImg={value.backgroundImage}
        time={value.period}
        tabName={value.tabName}
        idols={value.candidates ? value.candidates : []}
        description={value.description}
      />
    </div>
  );
};

export default IdolVoteInput;
export type { IdolVoteInputProps };
