import CollectionMetadata from "../data-types/collection-metadata";
import Idol from "../data-types/idol";
import useReadIdol from "../services/read-idol";
import useToIdolsPage from "../services/to-idols-page";
import useUpdateIdol from "../services/update-idol";
import useToUpdateIdolPage from "../services/to-update-idol-page";
import useToCreateIdolPage from "../services/to-create-idol-page";
import useCreateIdol from "../services/create-idol";
import useReadIdols from "../services/read-idols";
import FieldMetadata from "../data-types/field-metadata";
import buildTextFieldRender from "../utils/build-text-field-render";
import buildTextFieldRenderReadOnlyFormItem from "../utils/build-text-field-render-read-only-form-item";
import buildMultilingualStringFieldRender from "../utils/build-multilingual-string-field-render";
import buildMultilingualStringFieldRenderReadOnlyFormItem
  from "../utils/build-multilingual-string-field-render-read-only-form-item";
import buildMultilingualStringFieldRenderEditableFormItem
  from "../utils/build-multilingual-string-field-render-editable-form-item";
import buildRefFieldRender from "../utils/build-ref-field-render";
import buildRefFieldRenderReadOnlyFormItem from "../utils/build-ref-field-render-read-only-form-item";
import buildRefFieldRenderEditableFormItem from "../utils/build-ref-field-render-editable-form-item";
import buildDateFieldRender from "../utils/build-date-field-render";
import buildDateFieldRenderReadOnlyFormItem from "../utils/build-date-field-render-read-only-form-item";
import buildImageFieldRender from "../utils/build-image-field-render";
import buildImageFieldRenderReadOnlyFormItem from "../utils/build-image-field-render-read-only-form-item";
import buildImageFieldRenderEditableFormItem from "../utils/build-image-field-render-editable-form-item";

class IdField implements FieldMetadata<Idol> {
  readonly name = 'id';
  readonly label = 'UID';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildTextFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
}

class NameField implements FieldMetadata<Idol> {
  readonly name = 'name';
  readonly label = '이름';
  readonly customizable = true;

  // @ts-ignore
  readonly render = buildMultilingualStringFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildMultilingualStringFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildMultilingualStringFieldRenderEditableFormItem(this);
}

class RegionField implements FieldMetadata<Idol> {
  readonly name = 'region';
  readonly label = '지역';
  readonly customizable = true;

  render_: FieldMetadata<Idol>['render'] | undefined;
  get render(): FieldMetadata<Idol>['render'] {
    if (this.render_ == null) {
      this.render_ = buildRefFieldRender(this, 'region');
    }

    return this.render_;
  }

  // @ts-ignore
  // readonly render = buildRefFieldRender(this, 'region');
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildRefFieldRenderReadOnlyFormItem(this, 'region');
  // @ts-ignore
  readonly renderEditableFormItem = buildRefFieldRenderEditableFormItem(this, 'region');
}

class AssociationField implements FieldMetadata<Idol> {
  readonly name = 'association';
  readonly label = '소속';
  readonly customizable = true;

  render_: FieldMetadata<Idol>['render'] | undefined;
  get render(): FieldMetadata<Idol>['render'] {
    if (this.render_ == null) {
      this.render_ = buildRefFieldRender(this, 'association');
    }

    return this.render_;
  }

  // @ts-ignore
  // readonly render = buildRefFieldRender(this, 'association');
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildRefFieldRenderReadOnlyFormItem(this, 'association');
  // @ts-ignore
  readonly renderEditableFormItem = buildRefFieldRenderEditableFormItem(this, 'association');
}

class ThumbnailField implements FieldMetadata<Idol> {
  readonly name = 'thumbnail';
  readonly label = '썸네일';
  readonly customizable = true;

  // @ts-ignore
  readonly render = buildImageFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildImageFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildImageFieldRenderEditableFormItem(this);
}

class CreatedAtField implements FieldMetadata<Idol> {
  readonly name = 'createdAt';
  readonly label = '생성일';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildDateFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildDateFieldRenderReadOnlyFormItem(this);
}

const idolCollectionMetadata: CollectionMetadata<Idol> = {
  useRead: useReadIdol,
  useToOverallPage: useToIdolsPage,
  useUpdate: useUpdateIdol,
  useDefaultDetailsAction: useToUpdateIdolPage,
  useToCreatePage: useToCreateIdolPage,
  useCreate: useCreateIdol,
  useReadByPagination: useReadIdols,
  fields: [
    new IdField(),
    new NameField(),
    new RegionField(),
    new AssociationField(),
    new ThumbnailField(),
    new CreatedAtField(),
  ],
  fieldsForBrief: ['id', 'name'],
};

export default idolCollectionMetadata;
