import React, {useEffect, useState} from 'react';
import Layout from "../../components/layout";
import Vote from "../../data-types/vote";
import useReadVote from "../../services/read-vote";
import useHandleAppError from "../../services/handle-app-error";
import {useParams} from "react-router-dom";
import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";
import UpdateVoteIdol from "./update-vote-idol";
import UpdateVoteTournament from "./update-vote-tournament";
import UpdateVoteEvent from "./update-vote-event";

type WithStatus<T> =
  {
    status: 'LOADING' | 'FAILED';
  } |
  {
    status: 'LOADED';
    value: T;
  };

const emptyState: WithStatus<Vote> = {status: 'LOADING'};

const createLoaded = <T,>(value: T): WithStatus<T> & {status: 'LOADED'} => {
  return {status: 'LOADED', value};
};

const useCurrentVote = (): WithStatus<Vote> => {
  const [state, setState] = useState<WithStatus<Vote>>(emptyState);
  const urlParams = useParams();
  const readVote = useReadVote();
  const handleAppError = useHandleAppError();

  const tryInit = async () => {
    const vote = await readVote(urlParams.id!);
    setState(createLoaded(vote));
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, []);

  return state;
};

const UpdateVotePage: React.FC = () => {
  const vote = useCurrentVote();

  return (
    <Layout title={'투표 수정하기'}>
      {renderWhenLoadingOrFailed(vote, loadedVoteWithStatus => {
        const loadedVote = loadedVoteWithStatus.value;

        const components = {
          IDOL: UpdateVoteIdol,
          TOURNAMENT: UpdateVoteTournament,
          EVENT: UpdateVoteEvent,
        };

        const Component = components[loadedVote.voteKind];

        return <Component vote={loadedVote}/>;
      })}
    </Layout>
  );
};

export default UpdateVotePage;
