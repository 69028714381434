import Country from '../data-types/country';
import useAuth from "./auth";
import {apiAddr} from "../config";
import serializeCountry from '../from-server-serializers/country';

const useCreateCountry = () => {
  const auth = useAuth();

  return async (payload: Partial<Country>): Promise<Country> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/countries`,
      data: payload,
    });

    return serializeCountry(axiosResult.data);
  };
};

export default useCreateCountry;
