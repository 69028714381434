import React from 'react';
import Layout from "../../components/layout";
import {Button} from "antd";
import renderWhenLoadingOrFailed from "../../utils/render-when-loading-or-failed";
import useLogic from './use-logic';
import BannerInput from "./banner-input";

const UpdateAppMainBannersPage: React.FC = () => {
  const logic = useLogic();

  return (
    <Layout title={'메인 배너 관리'}>
      {renderWhenLoadingOrFailed(logic, loadedLogic => {
        const onAddBanner = loadedLogic.createAppendBanner;

        return <>
          <BannerInput
            value={loadedLogic.banners}
            onChange={loadedLogic.setBanners}
          />
          <div>
            <Button onClick={onAddBanner}>
              배너 추가
            </Button>
          </div>
          <div>
            <Button onClick={loadedLogic.submit}>
              저장
            </Button>
          </div>
        </>;
      })}
    </Layout>
  );
};

export default UpdateAppMainBannersPage;
