import Association from "../data-types/association";

const serialize = (raw: Record<string, any>): Association => {
  return {
    id: raw.id,
    name: raw.name,
  };
};

export default serialize;
