import { Typography } from "antd";
import styled from "styled-components";
import { CountryType } from "../../../data-types/country";

interface IProps {
  title: string;
  value: any;
  onChange: (e: string, country: CountryType, title: string) => void;
}
const Container = styled.div`
  border: 1px solid;
  border-radius: 25px;
  margin-bottom: 1rem;
  h2 {
    text-align: center;
  }
`;
const TextContainer = styled.div`
  display: flex;

  div {
    width: 100%;
    margin-left: 60px;
    textarea {
      width: 80%;
    }
  }
`;
const ResultVoteTextForm: React.FC<IProps> = ({ title, onChange, value }) => {
  return (
    <Container>
      <h2>{title}</h2>
      <TextContainer>
        <div>
          <Typography.Paragraph strong>미국</Typography.Paragraph>
          <textarea
            onChange={(e) => {
              onChange(e.target.value, "en", title);
            }}
            value={value?.en}
          ></textarea>
        </div>
        <div>
          <Typography.Paragraph strong>한국</Typography.Paragraph>
          <textarea
            onChange={(e) => {
              onChange(e.target.value, "kr", title);
            }}
            value={value?.kr}
          ></textarea>
        </div>
        <div>
          <Typography.Paragraph strong>중국</Typography.Paragraph>
          <textarea
            onChange={(e) => {
              onChange(e.target.value, "zh", title);
            }}
            value={value?.zh}
          ></textarea>
        </div>
      </TextContainer>
    </Container>
  );
};

export default ResultVoteTextForm;
