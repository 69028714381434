import buildCreatePage from "../../utils/build-create-page";
import Country from '../../data-types/country';
import countryCollectionMetadata from "../../data-metadata/country";

const CreateCountryPage = buildCreatePage<Country>({
  title: '국가 생성',
  collectionMetadata: countryCollectionMetadata,
});

export default CreateCountryPage;
