import React from 'react';
import styled from 'styled-components';

const Head = styled.span`
  display: block;
  width: 100vw;
  height: 100vh;
  line-height: 100vh;
  font-size: 3em;
  color: pink;
  font-weight: bold;
  font-family: serif;
  text-align: center;
`;

const NotFoundPage: React.FC = () => {
  return <Head>Not found.</Head>;
};

export default NotFoundPage;
