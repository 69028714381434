import Page from "../../data-types/page";
import {CollectionRefSelectProps} from "./index";
import {useEffect, useState} from "react";
import useHandleAppError from "../../services/handle-app-error";
import {strict as assert} from 'assert';

interface BaseLogic {
  limit: number;
  pageIndex: number;
  setPageIndex: (value: number) => void;
}

interface LoadingLogic extends BaseLogic {
  status: 'LOADING';
}

interface LoadedLogic extends BaseLogic {
  status: 'LOADED';
  page: Page<any>;
}

interface FailedLogic extends BaseLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface BaseState {
  pageIndex: number;
}

interface LoadingState extends BaseState {
  status: 'LOADING';
}

interface LoadedState extends BaseState {
  status: 'LOADED';
  page: Page<any>;
}

interface FailedState extends BaseState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const defaultState: State = {
  status: 'LOADING',
  pageIndex: 0,
};

const useLogic = (props: CollectionRefSelectProps): Logic => {
  const [state, setState] = useState<State>(defaultState);

  const limit = 10;
  const skip = limit * state.pageIndex;
  const readDocuments = props.collectionMetadata.useReadByPagination();
  const handleAppError = useHandleAppError();

  const toLoading = () => setState(oldState => ({
    status: 'LOADING',
    pageIndex: oldState.pageIndex,
  }));

  const toFailed = () => setState(oldState => ({
    status: 'FAILED',
    pageIndex: oldState.pageIndex,
  }));

  const tryInit = async () => {
    toLoading();

    const page = await readDocuments({
      limit,
      skip,
    });

    setState(oldState => ({
      status: 'LOADED',
      page,
      pageIndex: oldState.pageIndex,
    }));
  };

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      toFailed();
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, [state.pageIndex]);

  const setPageIndex = (value: number) => {
    setState(oldState => ({
      ...oldState,
      pageIndex: value,
    }));
  };

  const baseLogic: BaseLogic = {
    limit,
    setPageIndex,
    pageIndex: state.pageIndex,
  };

  if (state.status === 'LOADING') {
    return {
      ...baseLogic,
      status: 'LOADING',
    };
  }

  if (state.status === 'FAILED') {
    return {
      ...baseLogic,
      status: 'FAILED',
    };
  }

  if (state.status === 'LOADED') {
    return {
      ...baseLogic,
      status: 'LOADED',
      page: state.page,
    };
  }

  assert.fail();
};

export default useLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};
