import useAuth from "./auth";
import Idol from "../data-types/idol";
import {apiAddr} from "../config";
import serializeIdol from '../from-server-serializers/idol';
import InternalFile from "../data-types/internal-file";
import runIfNotNull from "../utils/run-if-not-null";

const useCreateIdol = () => {
  const auth = useAuth();

  const createInternalToExternal = (payload: Partial<Idol>): Record<string, any> => {
    const takeKey = (a: InternalFile) => a.storeKey;

    return {
      ...payload,
      thumbnail: runIfNotNull(takeKey, payload.thumbnail),
    };
  };

  return async (payload: Partial<Idol>): Promise<Idol> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/idols`,
      data: createInternalToExternal(payload),
    });

    return serializeIdol(axiosResult.data);
  };
};

export default useCreateIdol;
