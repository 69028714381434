import CollectionMetadata from "../data-types/collection-metadata";
import Region from "../data-types/region";
import useReadRegion from "../services/read-region";
import useToRegionsPage from "../services/to-regions-page";
import useUpdateRegion from "../services/update-region";
import useToUpdateRegionPage from "../services/to-update-region-page";
import useToCreateRegionPage from "../services/to-create-region-page";
import useCreateRegion from "../services/create-region";
import useReadRegions from "../services/read-regions";
import FieldMetadata from "../data-types/field-metadata";
import Country from "../data-types/country";
import buildTextFieldRender from "../utils/build-text-field-render";
import buildTextFieldRenderReadOnlyFormItem from "../utils/build-text-field-render-read-only-form-item";
import buildMultilingualStringFieldRender from "../utils/build-multilingual-string-field-render";
import buildMultilingualStringFieldRenderReadOnlyFormItem
  from "../utils/build-multilingual-string-field-render-read-only-form-item";
import buildMultilingualStringFieldRenderEditableFormItem
  from "../utils/build-multilingual-string-field-render-editable-form-item";
import buildDateFieldRender from "../utils/build-date-field-render";
import buildDateFieldRenderReadOnlyFormItem from "../utils/build-date-field-render-read-only-form-item";
import buildRefFieldRender from "../utils/build-ref-field-render";
import buildRefFieldRenderReadOnlyFormItem from "../utils/build-ref-field-render-read-only-form-item";
import buildRefFieldRenderEditableFormItem from "../utils/build-ref-field-render-editable-form-item";

const regionCollectionMetadata: CollectionMetadata<Region> = {
  useRead: useReadRegion,
  useToOverallPage: useToRegionsPage,
  useUpdate: useUpdateRegion,
  useDefaultDetailsAction: useToUpdateRegionPage,
  useToCreatePage: useToCreateRegionPage,
  useCreate: useCreateRegion,
  useReadByPagination: useReadRegions,
  fields: [
    new class implements FieldMetadata<Country> {
      readonly name = 'id';
      readonly label = 'UID';
      readonly customizable = false;

      // @ts-ignore
      readonly render = buildTextFieldRender<Country>(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem<Country>(this);
    },
    new class implements FieldMetadata<Country> {
      readonly name = 'name';
      readonly label = '이름';
      readonly customizable = true;

      // @ts-ignore
      readonly render = buildMultilingualStringFieldRender<Country>(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildMultilingualStringFieldRenderReadOnlyFormItem<Country>(this);
      // @ts-ignore
      readonly renderEditableFormItem = buildMultilingualStringFieldRenderEditableFormItem<Country>(this);
    },
    new class implements FieldMetadata<Region> {
      readonly name = 'createdAt';
      readonly label = '생성일';
      readonly customizable = false;

      // @ts-ignore
      readonly render = buildDateFieldRender(this);
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildDateFieldRenderReadOnlyFormItem(this);
    },
    new class implements FieldMetadata<Region> {
      readonly name = 'country';
      readonly label = '국가';
      readonly customizable = true;

      // @ts-ignore
      readonly render = buildRefFieldRender<Region, Country>(this, 'country');
      // @ts-ignore
      readonly renderReadOnlyFormItem = buildRefFieldRenderReadOnlyFormItem<Region, Country>(this, 'country');
      // @ts-ignore
      readonly renderEditableFormItem = buildRefFieldRenderEditableFormItem<Region, Country>(this, 'country');
    }
  ],
  fieldsForBrief: ['id', 'name'],
};

export default regionCollectionMetadata;
