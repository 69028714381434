import { Badge, Button, Table } from "antd";
import Column from "antd/lib/table/Column";
import { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { PushAlarmT } from "../../../data-types/push-alarm";
import useAuth from "../../../services/auth";
import PushAlarmApi from "../../../services/pushAlarmAPI";

const PushAlarmList: React.FC = () => {
  const [data, setData] = useState<PushAlarmT[]>();

  const auth = useAuth();
  const api = useMemo(() => {
    return new PushAlarmApi(auth);
  }, [auth]);
  const navigator = useNavigate();
  useEffect(() => {
    async function getData() {
      try {
        const list = await api.read();
        setData(list);
      } catch {
        window.alert("데이터를 불러오는 도중 오류가 발생 했습니다.");
        navigator("/");
      }
    }

    getData();
  }, [api, navigator]);
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          navigator("new");
        }}
      >
        생성
      </Button>
      <br />

      <Table
        dataSource={data}
        onRow={(record) => {
          return {
            onClick: () => {
              navigator(`${record.id}`);
            },
          };
        }}
        rowKey={(record) => record.id!}
        style={{ cursor: "pointer" }}
      >
        <Column title="UID" dataIndex="id" />
        <Column
          title="발송 여부"
          dataIndex="sended"
          render={(sended: boolean) =>
            sended ? <Badge status="success" /> : <Badge status="warning" />
          }
        />
        <Column title="타이틀" dataIndex="scheduleTitle" />
        <Column title="디바이스" dataIndex="device" key="device" />
        <Column
          title="발송일"
          dataIndex="scheduleDate"
          key="scheduleDate"
          render={(_, record: any) =>
            `${new Date(record.scheduleDate).toLocaleDateString()} ${new Date(
              record.scheduleDate
            ).toLocaleTimeString()}`
          }
        />
      </Table>
    </>
  );
};

export default PushAlarmList;
