import Banner from "../../data-types/banner";
import {useEffect, useState} from "react";
import useHandleAppError from "../../services/handle-app-error";
import {strict as assert} from 'assert';
import emptyMultilingualString from "../../utils/empty-multilingual-string";
import {v4 as uuid} from 'uuid';
import useReadAppMainBanners from "../../services/read-app-main-banners";
import useUpdateAppMainBanners from "../../services/update-app-main-banners";

interface LoadingLogic {
  status: 'LOADING';
}

interface LoadedLogic {
  status: 'LOADED';
  banners: Banner[];
  setBanners: (value: Banner[]) => void;
  submit: () => void;
  createAppendBanner: () => void;
}

interface FailedLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  banners: Banner[];
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const initialState: State = {status: 'LOADING'};
  const [state, setState] = useState<State>(initialState);
  const handleAppError = useHandleAppError();
  const readMainBanners = useReadAppMainBanners();
  const updateMainBanners = useUpdateAppMainBanners();

  const tryInit = async () => {
    const banners = await readMainBanners();

    setState({status: 'LOADED', banners});
  };

  const toFailed = () => setState({status: 'FAILED'});

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      toFailed();
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, []);

  if (state.status === 'LOADING') {
    return {status: 'LOADING'};
  }

  if (state.status === 'FAILED') {
    return {status: 'FAILED'};
  }

  const setBanners = (newValue: Banner[]) => {
    setState({status: 'LOADED', banners: newValue});
  };

  const trySubmit = async () => {
    await updateMainBanners(state.banners);
    alert('저장되었습니다');
    window.location.reload();
  };

  const submit = async () => {
    try {
      await trySubmit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  const createAppendBanner = () => {
    const newBanner = {
      id: uuid(),
      title: emptyMultilingualString,
      image: undefined,
      actionButtonText: emptyMultilingualString,
      link: undefined,
    };

    setState(oldState => {
      if (oldState.status !== 'LOADED') {
        return oldState;
      }

      return {
        ...oldState,
        banners: [...oldState.banners, newBanner],
      };
    });
  };

  if (state.status === 'LOADED') {
    return {
      status: 'LOADED',
      banners: state.banners,
      setBanners,
      submit,
      createAppendBanner,
    };
  }

  assert.fail();
};

export default useLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};
