import buildPaginatedPage from "../../utils/build-paginated-page";
import Vote from "../../data-types/vote";
import CollectionMetadata from "../../data-types/collection-metadata";
import useReadVote from "../../services/read-vote";
import useReadVotes from "../../services/read-votes";
import useToUpdateVotePage from "../../services/to-update-vote-page";
import FieldMetadata from "../../data-types/field-metadata";
import buildTextFieldRender from "../../utils/build-text-field-render";
import buildMultilingualStringFieldRender from "../../utils/build-multilingual-string-field-render";
import buildVoteKindFieldRender from "../../utils/build-vote-kind-field-render";
import buildPeriodFieldRender from "../../utils/build-period-field-render";
import useToCreateVotePage from "../../services/to-create-vote-page";

class IdField implements Partial<FieldMetadata<Vote>> {
  readonly name = 'id';
  readonly label = 'UID';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildTextFieldRender(this);
}

class VoteKindField implements Partial<FieldMetadata<Vote>> {
  readonly name = 'voteKind';
  readonly label = '종류';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildVoteKindFieldRender(this);
}

class TitleField implements Partial<FieldMetadata<Vote>> {
  readonly name = 'voteTitle';
  readonly label = '제목';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildMultilingualStringFieldRender(this);
}

class PeriodField implements Partial<FieldMetadata<Vote>> {
  readonly name = 'votePeriod';
  readonly label = '기간';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildPeriodFieldRender(this);
}

const collectionMetadata: Partial<CollectionMetadata<Vote>> = {
  useRead: useReadVote,
  useReadByPagination: useReadVotes,
  useDefaultDetailsAction: useToUpdateVotePage,
  useToCreatePage: useToCreateVotePage,
  fields: [
    new IdField() as FieldMetadata<Vote>,
    new VoteKindField() as FieldMetadata<Vote>,
    new TitleField() as FieldMetadata<Vote>,
    new PeriodField() as FieldMetadata<Vote>,
  ],
};

const VotesPage = buildPaginatedPage<Vote>({
  title: '투표 관리',
  collectionMetadata: collectionMetadata as CollectionMetadata<Vote>,
});

export default VotesPage;
