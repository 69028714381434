import useAuth from "./auth";
import Banner from "../data-types/banner";
import {apiAddr} from "../config";
import serializeBanner from '../from-server-serializers/banner';

const useReadAppMainBanners = () => {
  const auth = useAuth();

  return async (): Promise<Banner[]> => {
    const axiosResult = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/admin/v1/app/main-banners`,
    });

    return axiosResult.data.map(serializeBanner);
  };
};

export default useReadAppMainBanners;
