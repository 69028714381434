import AuthImpl from "./auth-impl";
import AuthData from "./auth-data";
import AuthEventHandler from "./auth-event-handler";
import AuthEventResolver from "./auth-event-resolver";
import AuthEvent from "./auth-event";

interface Auth {
  login: AuthImpl["login"];
  logout: AuthImpl["logout"];
  subscribe: AuthImpl["subscribe"];
  callAxios: AuthImpl["callAxios"];
  checkIsLoggedIn: () => void;
  isLoggedIn: boolean;
  userId: string | undefined;
  refreshToken: string | undefined;
  email: string | undefined;
}

const createAuth = (): Auth => {
  const auth = new AuthImpl();
  auth.login = auth.login.bind(auth);
  auth.logout = auth.logout.bind(auth);
  auth.subscribe = auth.subscribe.bind(auth);
  auth.callAxios = auth.callAxios.bind(auth);
  auth.checkIsLoggedIn = auth.checkIsLoggedIn.bind(auth);
  return auth;
};

export { createAuth };
export type { AuthData, Auth, AuthEventHandler, AuthEventResolver, AuthEvent };
