import useAuth from "./auth";
import {apiAddr} from "../config";
import Country from "../data-types/country";

const useUpdateCountry = () => {
  const auth = useAuth();

  return async (id: string, payload: Partial<Country>) => {
    await auth.callAxios({
      method: 'patch',
      url: `${apiAddr}/admin/v1/countries/${id}`,
      data: payload,
    });
  };
};

export default useUpdateCountry;
