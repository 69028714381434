import Idol from "../data-types/idol";
import internalFileFromUrl from "../utils/internal-file-from-url";

const serialize = (raw: Record<string, any>): Idol => {
  return {
    id: raw.id,
    region: raw.region,
    association: raw.association,
    name: raw.name,
    thumbnail: internalFileFromUrl(raw.thumbnail),
    createdAt: new Date(raw.createdAt),
  };
};

export default serialize;
