import React from "react";
import Period from "../data-types/period";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import dayjs from "dayjs";

interface PeriodInputProps {
  className?: string | undefined;
  value?: Period | undefined;
  onChange?: ((value: Period) => void) | undefined;
}

const PeriodInput: React.FC<PeriodInputProps> = (props) => {
  const onChange = (period: any) => {
    if (period == null) {
      return;
    }

    const [from, to] = period;

    if (from == null || to == null) {
      return;
    }

    props.onChange != null &&
      props.onChange({ from: from.toDate(), to: to.toDate() });
  };

  const getValue = (): any => {
    if (props.value == null) {
      return null;
    }

    return [dayjs(props.value.from), dayjs(props.value.to)];
  };

  return (
    <DatePicker.RangePicker
      showTime
      className={props.className}
      onChange={onChange}
      value={getValue()}
    />
  );
};

export default PeriodInput;
