import React from 'react';
import WithId from "../utils/with-id";
import {Button} from "antd";
import styled from 'styled-components';

const ItemCont = styled.div`
  margin-top: 24px;
  border: 1px solid gray;
  padding: 16px;
  &:first-of-type {
    margin-top: 0px;
  }
`;

interface OrderedInputProps {
  className?: string | undefined;
  itemComponent: any;
  value?: WithId[] | undefined;
  onChange?: ((value: WithId[]) => void) | undefined;
}

const OrderedInput: React.FC<OrderedInputProps> = (props) => {
  const Item = props.itemComponent;

  const value = props.value ?? [];
  const onChange = <T extends any[]>(value: T) => {
    props.onChange != null && props.onChange(value);
  };

  return (
    <div className={props.className}>
      {value.map((item, itemIndex) => {
        const onUp = () => {
          if (itemIndex === 0) { return; }

          const copy = [...value];
          copy[itemIndex] = copy[itemIndex - 1];
          copy[itemIndex - 1] = item;
          onChange(copy);
        };

        const onDown = () => {
          if (value.length === 0 || itemIndex === (value.length - 1)) { return; }

          const copy = [...value];
          copy[itemIndex] = copy[itemIndex + 1];
          copy[itemIndex + 1] = item;
          onChange(copy);
        };

        const onDeleteCurrent = () => {
          const copy = [...value];
          copy.splice(itemIndex, 1);
          onChange(copy);
        };

        const onCurrentChange = (newValue: WithId) => {
          const copy = [...value];
          copy[itemIndex] = newValue;
          onChange(copy);
        };

        return (
          <ItemCont key={item.id}>
            <div>
              <Button onClick={onUp}>
                위로
              </Button>
              <Button onClick={onDown}>
                아래로
              </Button>
              <Button danger  onClick={onDeleteCurrent}>
                삭제
              </Button>
            </div>
            <div>
              <Item
                value={item}
                onChange={onCurrentChange}
                index={itemIndex}
              />
            </div>
          </ItemCont>
        );
      })}
    </div>
  );
};

export default OrderedInput;
export type {OrderedInputProps};
