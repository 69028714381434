import useAuth from "./auth";
import Association from '../data-types/association';
import {apiAddr} from "../config";
import serializeAssociation from '../from-server-serializers/association';

const useCreateAssociation = () => {
  const auth = useAuth();

  return async (payload: Partial<Association>): Promise<Association> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/associations`,
      data: payload,
    });

    return serializeAssociation(axiosResult.data);
  };
};

export default useCreateAssociation;
