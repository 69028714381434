import Vote from "../../data-types/vote";
import VoteKind from "../../data-types/vote-kind";

interface VoteKindMetadata {
  name: VoteKind;
  label: string;
}

const voteKindMetadata = [
  {
    name: 'IDOL',
    label: '아이돌 만들기',
  },
  {
    name: 'TOURNAMENT',
    label: '월드컵(일반) 만들기',
  },
  {
    name: 'EVENT',
    label: '월드컵(이벤트) 만들기',
  },
];

export default VoteKindMetadata;
export {voteKindMetadata};
