import React from 'react';
import styled from 'styled-components';

const Cont = styled.img`
  width: 260px;
  max-height: 800px;
`;

interface MediumImageProps {
  className?: string | undefined;
  value?: string | undefined;
}

const MediumImage: React.FC<MediumImageProps> = (props) => {
  return <Cont className={props.className} src={props.value}/>;
};

export default MediumImage;
export type {MediumImageProps};
