import { Typography } from "antd";
import Layout from "../../components/layout";
import ResultVoteDetail from "../../components/result-vote/result-vote-detail";

const ResultVoteDetailPage: React.FC = () => {
	return (
		<Layout>
			<Typography.Title>펜업투어 관리</Typography.Title>
			<ResultVoteDetail />
		</Layout>
	);
};

export default ResultVoteDetailPage;
