import Page from "../data-types/page";
import Serializer from "../data-types/from-server-serializer";

const serializePage = async <T>(
  raw: Page<Record<string, any>>,
  serializer: Serializer<T>
): Promise<Page<T>> => {
  return {
    totalCount: raw.totalCount,
    data: await Promise.all(raw.data.map(serializer as any)),
  } as Page<T>;
};

export default serializePage;
