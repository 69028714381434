import { Divider } from "antd";
import QnaAnswerForm from "./qna-answer-form";
import QnaDetailForm from "./qna-detail-form";
import { QnaDetail as QnaDetailData } from "../../../data-types/qna";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import QnaApi from "../../../services/qnaAPI";
import useAuth from "../../../services/auth";

const QnaDetail: React.FC = () => {
	const { id } = useParams();
	const [qna, setQna] = useState<QnaDetailData>();

	const auth = useAuth();
	const api = useMemo(() => { return new QnaApi(auth) }, [auth])
	useEffect(() => {
		async function getData(id: string | undefined) {
			if (id) {
				const data = await api.getQna(id);

				setQna(data);
			}
		}

		getData(id);
	}, [id, api]);
	return (
		<>
			{qna && (
				<>
					<Divider />
					<QnaDetailForm QnaData={qna} />
					<Divider />
					<QnaAnswerForm QnaData={qna} api={api} />
				</>
			)}
		</>
	);
};

export default QnaDetail;
