import IdolVoteCandidate from "../data-types/idol-vote-candidate";
import useAuth from "./auth";
import {apiAddr} from "../config";

const useReadIdolVoteCandidates = () => {
  const auth = useAuth();

  return async (id: string): Promise<IdolVoteCandidate[]> => {
    const axiosResult = await auth.callAxios({
      method: 'get',
      url: `${apiAddr}/admin/v1/votes/${id}/idol-vote-candidates`,
    });

    return axiosResult.data.map((r: Record<string, any>): IdolVoteCandidate => {
      return {
        id: r.id,
        idol: r.idol,
        voteCount: r.voteCount,
      };
    });
  };
};

export default useReadIdolVoteCandidates;
