import React from 'react';
import FieldMetadata from "../data-types/field-metadata";
import {Form} from "antd";
import CollectionDocumentView from "../components/collection-document-view";
import CollectionMetadata from "../data-types/collection-metadata";
import getCollectionMetadataById from "./get-collection-metadata-by-id";

const buildRefFieldRenderReadOnlyFormItem = <OwnerT, RefT>(
  that: FieldMetadata<OwnerT>,
  collectionMetadataId: string
): FieldMetadata<OwnerT>['renderReadOnlyFormItem'] => {
  return () => {
    const collectionMetadata: CollectionMetadata<RefT> = getCollectionMetadataById(collectionMetadataId);

    return (
      <Form.Item
        key={that.name}
        label={that.label}
        name={that.name}
      >
        <CollectionDocumentView
          collectionMetadata={collectionMetadata}
        />
      </Form.Item>
    );
  };
};

export default buildRefFieldRenderReadOnlyFormItem;
