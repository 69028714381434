import React from 'react';
import InternalFile from "../data-types/internal-file";
import MediumImageInput from "../utils/build-image-field-render-editable-form-item/medium-image-input";

interface ImageInputProps {
  className?: string | undefined;
  value?: InternalFile | undefined;
  multipleValue?: InternalFile[] | undefined;
  onChange?: ((value: InternalFile | undefined) => void) | undefined;
  onChangeMultiple?:((value: InternalFile[] | undefined) => void) | undefined;
  requestUploadInfoPath: string;
  disabled?:boolean|undefined;
  isMultipleUpload? : boolean | undefined;
}

const ImageInput: React.FC<ImageInputProps> = (props) => {
  return <MediumImageInput
    className={props.className}
    value={props.value}
    multipleValue={props.multipleValue}
    onChange={props.onChange}
    disabled={props.disabled}
    onChangeMultiple={props.onChangeMultiple}
    requestUploadInfoPath={props.requestUploadInfoPath}
    isMultipleUpload={(props.isMultipleUpload||false)}
  />;
};

export default ImageInput;
export type {ImageInputProps};
