import {useEffect, useState} from "react";
import useHandleAppError from "../../services/handle-app-error";
import useReadAppMaxIdolVoteCountPerDay from "../../services/read-app-max-idol-vote-count-per-day";
import useUpdateAppMaxIdolVoteCountPerDay from "../../services/update-app-max-idol-vote-count-per-day";
import {strict as assert} from 'assert';

interface LoadingLogic {
  status: 'LOADING';
}

interface LoadedLogic {
  status: 'LOADED';
  maxIdolVoteCountPerDay: number;
  setMaxIdolVoteCountPerDay: (value:any) => void;
  submit: () => void;
}

interface FailedLogic {
  status: 'FAILED';
}

type Logic = LoadingLogic | LoadedLogic | FailedLogic;

interface LoadingState {
  status: 'LOADING';
}

interface LoadedState {
  status: 'LOADED';
  maxIdolVoteCountPerDay: number;
}

interface FailedState {
  status: 'FAILED';
}

type State = LoadingState | LoadedState | FailedState;

const useLogic = (): Logic => {
  const initialState: State = {status: 'LOADING'};
  const [state, setState] = useState<State>(initialState);
  const handleAppError = useHandleAppError();
  const readMaxIdolVoteCountPerDay = useReadAppMaxIdolVoteCountPerDay();
  const updateMaxIdolVoteCountPerDay = useUpdateAppMaxIdolVoteCountPerDay();

  const tryInit = async () => {
    const maxIdolVoteCountPerDay = await readMaxIdolVoteCountPerDay();

    setState({status: 'LOADED', maxIdolVoteCountPerDay});
  };

  const toFailed = () => setState({status: 'FAILED'});

  const init = async () => {
    try {
      await tryInit();
    } catch (error) {
      toFailed();
      await handleAppError(error);
    }
  };

  useEffect(() => { init(); }, []);

  if (state.status === 'LOADING') {
    return {status: 'LOADING'};
  }

  if (state.status === 'FAILED') {
    return {status: 'FAILED'};
  }

  const trySubmit = async () => {
    await updateMaxIdolVoteCountPerDay(state.maxIdolVoteCountPerDay);
    alert('저장되었습니다');
    window.location.reload();
  };

  const submit = async () => {
    try {
      await trySubmit();
    } catch (error) {
      await handleAppError(error);
    }
  };

  const setMaxIdolVoteCountPerDay = (newValue: number) => {
    setState(oldState => ({...oldState, maxIdolVoteCountPerDay: newValue}));
  };

  if (state.status === 'LOADED') {
    return {
      status: 'LOADED',
      maxIdolVoteCountPerDay: state.maxIdolVoteCountPerDay,
      setMaxIdolVoteCountPerDay,
      submit,
    };
  }

  assert.fail();
};

export default useLogic;
export type {LoadingLogic, LoadedLogic, FailedLogic, Logic};
