import React, {useState} from "react";
import Layout from "../../components/layout";
import {Attraction} from "../../data-types/attraction";
import AttractionInput from "../../components/attraction/attraction-input";

const CreateAttractionPage : React.FC = () => {
    const [attraction, setAttraction] = useState<Partial<Attraction> | undefined>(undefined);
    return (
        <Layout title={'관광지 등록'}>
            <AttractionInput
                value={attraction}
                isCreate={true}
                onChange={setAttraction}
            />
        </Layout>
    );
};

export default CreateAttractionPage;
