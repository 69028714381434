import useAuth from "./auth";
import {apiAddr} from "../config";

const useUpdateRewardingTicketCount = () => {
  const auth = useAuth();

  return async (newValue: number) => {
    await auth.callAxios({
      method: 'put',
      url: `${apiAddr}/admin/v1/app/rewarding-ticket-count`,
      data: newValue,
      headers: {'Content-Type': 'application/json'},
    });
  };
};

export default useUpdateRewardingTicketCount;
