import useAuth from "./auth";
import {apiAddr} from "../config";

const useUpdateAppMaxIdolVoteCountPerDay = () => {
  const auth = useAuth();

  return async (count: number) => {
    await auth.callAxios({
      method: 'put',
      url: `${apiAddr}/admin/v1/app/max-idol-vote-count-per-day`,
      data: count.toString(),
      headers: {'Content-Type': 'application/json'},
    });
  };
};

export default useUpdateAppMaxIdolVoteCountPerDay;
