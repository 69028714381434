import useAuth from "./auth";
import EventVote from "../data-types/event-vote";
import {apiAddr} from "../config";
import serializeVote from '../from-server-serializers/vote';

const useCreateEventVote = () => {
  const auth = useAuth();

  return async (payload: Partial<EventVote>): Promise<EventVote> => {
    const axiosResult = await auth.callAxios({
      method: 'post',
      url: `${apiAddr}/admin/v1/votes`,
      data: {
        voteKind: 'EVENT',
        title: payload.title,
        description: payload.description,
        period: payload.period,
        thumbnail: payload.thumbnail?.storeKey,
        category: payload.category,
        choices: payload.choices!.map((choice) => {
          return {
            id: choice.id,
            content: {
              image: choice.content.image?.storeKey,
              title: choice.content.title,
              description: choice.content.description,
              link: choice.content.link,
            },
            voteCount: choice.voteCount,
          };
        }),
      },
    });

    const result = (await serializeVote(auth, axiosResult.data)) as EventVote;
    return result;
  };
};

export default useCreateEventVote;
