import buildReadCollectionDocument from "../utils/build-read-collection-document";
import serializeVote from '../from-server-serializers/vote';
import Vote from "../data-types/vote";

const useReadVote = buildReadCollectionDocument<Vote>({
  collection: 'votes',
  serializer: serializeVote,
});

export default useReadVote;
