import React, {useState} from 'react';
import Vote from "../../data-types/vote";
import Layout from "../../components/layout";
import SelectVoteKind from "./select-vote-kind";
import VoteKind from "../../data-types/vote-kind";
import VoteInput from "./vote-input";

const CreateVotePage: React.FC = () => {
  const [voteKind, setVoteKind] = useState<VoteKind | undefined>(undefined);
  const [vote, setVote] = useState<Partial<Vote> | undefined>(undefined);

  const onVoteKindChange = (newKind: VoteKind | undefined) => {
    if (voteKind != null) {
      const confirmResult = window.confirm('현재 내용이 삭제될 수 있습니다. 정말로 생성하는 투표 종류를 변경하시겠습니까?');

      if (!confirmResult) {
        return;
      }
    }

    setVoteKind(newKind);
  };

  return (
    <Layout title={'투표 만들기'}>
      <SelectVoteKind value={voteKind} onChange={onVoteKindChange}/>
      <VoteInput
        voteKind={voteKind}
        value={vote}
        onChange={setVote}
      />
    </Layout>
  );
};

export default CreateVotePage;
