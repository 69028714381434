import React from 'react';
import CollectionMetadata from "../../data-types/collection-metadata";
import requireLogin from "../../hocs/require-login";
import {Button, Form} from "antd";
import renderWhenLoadingOrFailed from "../render-when-loading-or-failed";
import Layout from "../../components/layout";
import buildLogic from "./build-logic";

interface Params<C> {
  title: string;
  collectionMetadata: CollectionMetadata<C>;
}

const buildCreatePage = <C,>(params: Params<C>) => {
  const useLogic = buildLogic<C>(params.collectionMetadata);

  const Page: React.FC = requireLogin(() => {
    const logic = useLogic();

    return (
      <Layout title={params.title}>
        <Form onFinish={logic.onSubmit}>
          {params.collectionMetadata.fields.map((field) => {
            if (field.customizable) {
              return field.renderEditableFormItem!();
            } else {
              return null;
            }
          })}
          <Form.Item>
            <Button type={'primary'} htmlType={'submit'}>
              생성
            </Button>
          </Form.Item>
        </Form>
      </Layout>
    );
  });

  return Page;
};

export default buildCreatePage;
