import buildReadCollectionDocuments from "../utils/build-read-collection-documents";
import Idol from "../data-types/idol";
import serializeIdol from '../from-server-serializers/idol';

const useReadIdols = buildReadCollectionDocuments<Idol>({
  collection: 'idols',
  serializer: serializeIdol,
});

export default useReadIdols;
