import Announcement from "../data-types/announcement";
import CollectionMetadata from "../data-types/collection-metadata";
import useReadAnnouncement from "../services/read-announcement";
import useReadAnnouncements from "../services/read-announcements";
import useToAnnouncementsPage from "../services/to-announcements-page";
import useUpdateAnnouncement from "../services/update-announcement";
import useToUpdateAnnouncementPage from "../services/to-update-announcement-page";
import useToCreateAnnouncementPage from "../services/to-create-announcement-page";
import useCreateAnnouncement from "../services/create-announcement";
import FieldMetadata from "../data-types/field-metadata";
import buildTextFieldRender from "../utils/build-text-field-render";
import buildTextFieldRenderReadOnlyFormItem from "../utils/build-text-field-render-read-only-form-item";
import buildMultilingualStringFieldRender from "../utils/build-multilingual-string-field-render";
import buildMultilingualStringFieldRenderReadOnlyFormItem
  from "../utils/build-multilingual-string-field-render-read-only-form-item";
import buildMultilingualStringFieldRenderEditableFormItem
  from "../utils/build-multilingual-string-field-render-editable-form-item";
import buildImageFieldRender from "../utils/build-image-field-render";
import buildImageFieldRenderReadOnlyFormItem from "../utils/build-image-field-render-read-only-form-item";
import buildImageFieldRenderEditableFormItem from "../utils/build-image-field-render-editable-form-item";
import buildDateFieldRender from "../utils/build-date-field-render";
import buildDateFieldRenderReadOnlyFormItem from "../utils/build-date-field-render-read-only-form-item";
import buildAnnouncementKindFieldRender from "../utils/build-announcement-kind-field-render";
import buildAnnouncementKindFieldRenderReadOnlyFormItem
  from "../utils/build-announcement-kind-field-render-read-only-form-item";
import buildAnnouncementKindFieldRenderEditableFormItem
  from "../utils/build-announcement-kind-field-render-editable-form-item";

class IdField implements FieldMetadata<Announcement> {
  readonly name = 'id';
  readonly label = 'UID';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildTextFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildTextFieldRenderReadOnlyFormItem(this);
}

class TitleField implements FieldMetadata<Announcement> {
  readonly name = 'title';
  readonly label = '제목';
  readonly customizable = true;

  // @ts-ignore
  readonly render = buildMultilingualStringFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildMultilingualStringFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildMultilingualStringFieldRenderEditableFormItem(this);
}

class KindField implements FieldMetadata<Announcement> {
  readonly name = 'kind';
  readonly label = '종류';
  readonly customizable = true;

  // @ts-ignore
  readonly render = buildAnnouncementKindFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildAnnouncementKindFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildAnnouncementKindFieldRenderEditableFormItem(this);
}

class ImageField implements FieldMetadata<Announcement> {
  readonly name = 'image';
  readonly label = '이미지';
  readonly customizable = true;

  // @ts-ignore
  readonly render = buildImageFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildImageFieldRenderReadOnlyFormItem(this);
  // @ts-ignore
  readonly renderEditableFormItem = buildImageFieldRenderEditableFormItem(this, 'upload-announcement-image');
}

class CreatedAtField implements FieldMetadata<Announcement> {
  readonly name = 'createdAt';
  readonly label = '생성일';
  readonly customizable = false;

  // @ts-ignore
  readonly render = buildDateFieldRender(this);
  // @ts-ignore
  readonly renderReadOnlyFormItem = buildDateFieldRenderReadOnlyFormItem(this);
}

const announcementCollectionMetadata: CollectionMetadata<Announcement> = {
  useRead: useReadAnnouncement,
  useToOverallPage: useToAnnouncementsPage,
  useUpdate: useUpdateAnnouncement,
  useDefaultDetailsAction: useToUpdateAnnouncementPage,
  useToCreatePage: useToCreateAnnouncementPage,
  useCreate: useCreateAnnouncement,
  useReadByPagination: useReadAnnouncements,
  fields: [
    new IdField(),
    new TitleField(),
    new KindField(),
    new ImageField(),
    new CreatedAtField(),
  ],
  fieldsForBrief: ['id', 'title'],
};

export default announcementCollectionMetadata;
