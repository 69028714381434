import React from 'react';
import FieldMetadata from "../data-types/field-metadata";
import {Form, Input, Typography} from "antd";
import MultilingualStringInput from "../components/multilingual-string-input";

const buildMultilingualStringFieldRenderEditableFormItem = <T,>(that: FieldMetadata<T>) => () => {
  return (
    <Form.Item
      key={that.name}
      label={that.label}
      name={that.name}
    >
      <MultilingualStringInput/>
    </Form.Item>
  );
};

export default buildMultilingualStringFieldRenderEditableFormItem;
