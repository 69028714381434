import Vote from "../data-types/vote";
import serializeIdolVote from './idol-vote';
import serializeTournamentVote from './tournament-vote';
import serializeEventVote from './event-vote';
import {strict as assert} from 'assert';
import {Auth} from "../utils/auth";

const serialize = async (auth: Auth, r: Record<string, any>): Promise<Vote> => {
  switch (r.voteKind) {
    case 'IDOL': return await serializeIdolVote(auth, r);
    case 'TOURNAMENT': return await serializeTournamentVote(auth, r);
    case 'EVENT': return await serializeEventVote(auth, r);
  }

  assert.fail();
};

export default serialize;
