import InternalFile from "../data-types/internal-file";

const internalFileFromUrl = (url: string): InternalFile => {
  return {
    isNew: false,
    url,
  };
};

export default internalFileFromUrl;
